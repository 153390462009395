import Opportunity from 'static/models/Opportunity';
import Product from 'static/models/Product';
import { ProductQuestionAnswer } from 'static/models/ProductQuestionAnswer';
import { ProductQuestion } from 'static/models/ProductQuestions';

export const filterProductQuestions = (questions: ProductQuestion[], opportunity: Opportunity): ProductQuestion[] => {
    return questions.filter(
        x =>
            (x.timingTypes.length === 0 || x.timingTypes.includes(opportunity.timing)) &&
            (x.dispositionTypes.length === 0 || x.dispositionTypes.includes(opportunity.disposition)),
    );
};

export const getProductsFromAnswer = (questionId: string, answer: ProductQuestionAnswer): Product[] => {
    const selectedProducts: Product[] = [];

    answer.products.forEach(product => {
        const existingProduct = selectedProducts.find(x => x.id === product.id);

        if (existingProduct) {
            existingProduct.quantity++;
        } else {
            selectedProducts.push({
                ...product,
                questionId,
            });
        }
    });

    return selectedProducts;
};
