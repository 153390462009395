import { ignoredRoutes } from 'hooks/useRouteCalculator/ignored-routes';
import { stopPages } from 'hooks/useRouteCalculator/routing-graph';
import { RootState } from 'redux/root';
import urlPaths from 'static/constants/enums/urlPaths';

import { selectOpportunityCustomerPaths, selectOpportunityId } from '../../redux/opportunity/opportunity-selectors';
import { CustomerPathEntry, CustomerPathObjectEntry } from '../../static/models/Opportunity';

export function getStopPage(parsedCustomerPaths: CustomerPathEntry[]): urlPaths | null {
    // Do not direct to non-content pages(ie loading pages, returning).
    const contentOnlyPaths: CustomerPathEntry[] = parsedCustomerPaths.filter(
        path => !ignoredRoutes.includes(path.path),
    );

    // if the customer was ever on a stop page, send them back to that page
    for (let i = 0; i < contentOnlyPaths.length; i++) {
        if (stopPages.has(contentOnlyPaths[i].path)) {
            return contentOnlyPaths[i].path;
        }
    }

    if (contentOnlyPaths.length > 0) {
        return contentOnlyPaths[contentOnlyPaths.length - 1].path;
    }

    return null;
}

export function getStopPageForObject(parsedCustomerPaths: CustomerPathObjectEntry[]): urlPaths | null {
    if (!!!parsedCustomerPaths) {
        return null;
    }
    // Do not direct to non-content pages(ie loading pages, returning).
    const contentOnlyPaths: CustomerPathObjectEntry[] = parsedCustomerPaths.filter(
        path => !ignoredRoutes.includes(path.pathName),
    );

    // if the customer was ever on a stop page, send them back to that page
    for (let i = 0; i < contentOnlyPaths.length; i++) {
        if (stopPages.has(contentOnlyPaths[i].pathName)) {
            return contentOnlyPaths[i].pathName;
        }
    }

    if (contentOnlyPaths.length > 0) {
        return contentOnlyPaths[0].pathName;
    }

    return null;
}

export function jumpToPageCalculator(state: RootState): urlPaths {
    const opportunityId = selectOpportunityId(state);
    // by this point, an opportunity will have been created. if not, :badtime:
    if (!opportunityId) {
        return urlPaths.error;
    }

    let previousStopPage: urlPaths;
    if (state.featureFlags.useCustomerPathAssociationObject) {
        previousStopPage = getStopPageForObject(state.opportunity.customerPathObject);
    } else {
        previousStopPage = getStopPage(selectOpportunityCustomerPaths(state));
    }

    if (previousStopPage) {
        return previousStopPage;
    }

    if (opportunityId) {
        return urlPaths.preferences;
    }

    // something seriously bad happened
    return urlPaths.error;
}
