import React, { useState } from 'react';

import { TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const TextBoxComponent: React.FunctionComponent<PropShape> = props => {
    const [currentValue, setCurrentValue] = useState(props.value);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setCurrentValue(e.target.value);
        props.onChange(e);
    };

    const shouldShowError = (): boolean => {
        return props.showError && !!props.errorMessage;
    };

    return (
        <div className={props.className}>
            <TextField
                id={`input-${props.formName}-${props.fieldName}`}
                label={props.labelString}
                name={props.fieldName}
                placeholder={props.placeholder}
                value={currentValue ?? ''}
                onChange={handleOnChange}
                fullWidth
                error={shouldShowError()}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => props.onBlur(e)}
                InputLabelProps={{ shrink: true }}
            />
            {shouldShowError() && <Alert color="error">{props.errorMessage}</Alert>}
        </div>
    );
};

interface PropShape {
    // Shape of passed in props
    formName: string;
    className?: string;
    fieldName: string;
    value?: string;
    labelString?: string;
    placeholder?: string;
    showError?: boolean;
    errorMessage?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

TextBoxComponent.defaultProps = {
    // Set default values for props
    value: '',
    onChange: () => { },
    onBlur: () => { },
};

export default TextBoxComponent;
