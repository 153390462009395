import React, { useState, useEffect } from 'react';

import { Grid, Typography } from '@material-ui/core';
import { useAvailablePackages } from 'hooks/product-package-questions/useAvailablePackages';
import { useSelectedPackage } from 'hooks/product-package-questions/useSelectedPackage';
import { useSelectedProducts } from 'hooks/product-package-questions/useSelectedProducts';
import { useOpportunity } from 'hooks/useOpportunity';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import Package from 'static/models/Package';
import {
    filterPackages,
    filterPackagesProductsByTiming,
    sortDisplayPackages,
    updatePackagePrices,
} from 'toolboxes/reuseable-logic/package-functions';
import { getPackageText } from 'toolboxes/reuseable-logic/state-specific-language/package-language-change';

import PackageCardComponent from '../package-card-component/PackageCardComponent';

const PackageSelectionComponent: React.FunctionComponent<PropShape> = () => {
    const [displayPackages, setDisplayPackages] = useState<Package[]>([]);
    const { opportunity } = useOpportunity();
    const availablePackages = useAvailablePackages();
    const { selectedPackage, setSelectedPackage } = useSelectedPackage();
    const { setSelectedProducts } = useSelectedProducts();
    const { selectedAccount } = useSelectedAccount();

    const packageText = getPackageText(selectedAccount);

    useEffect(() => {
        const filteredPackages = filterPackages(availablePackages, opportunity.disposition);
        const filteredPackagesProducts = filterPackagesProductsByTiming(filteredPackages, opportunity.timing);
        updatePackagePrices(filteredPackagesProducts);
        const sortedPackages = sortDisplayPackages(filteredPackagesProducts);

        setDisplayPackages(sortedPackages);
    }, [availablePackages, opportunity.disposition, opportunity.timing]);

    const handleOnPackageSelect = (p: Package) => {
        setSelectedPackage(p);
        setSelectedProducts(p.products);
    };

    return (
        <>
            {displayPackages.length === 0 ? (
                <Typography>No {packageText}s Available</Typography>
            ) : (
                <Grid container direction="row" justifyContent="center" spacing={2}>
                    {displayPackages.map((val, i) => (
                        <Grid item xs={12} key={i}>
                            <PackageCardComponent
                                package={val}
                                selected={val.id === selectedPackage?.id}
                                onSelect={handleOnPackageSelect}
                                singlePackageCard={displayPackages.length === 1}
                            />
                        </Grid>
                    ))}
                </Grid>
            )}
        </>
    );
};

interface PropShape {
    // Shape of passed in props
}

PackageSelectionComponent.defaultProps = {
    // Set default values for props
};

export default PackageSelectionComponent;
