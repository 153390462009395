import { Action } from 'history';
import { RoutingParams } from 'static/models/RoutingParams';
import pageRequiresAccountSelected from 'toolboxes/reuseable-logic/page-requires-account-selected';
import pageRequiresOpportunity from 'toolboxes/reuseable-logic/page-requires-opportunity';

import { RootState } from '../../redux/root';
import urlPaths from '../../static/constants/enums/urlPaths';
import loadOpportunityReroutes from './pages/loadOpportunity';
import returnReroutes from './pages/returning';
import startReroutes from './pages/start';

// This function receives the state, and can find out where the user should be directed to
// If the user should be redirected to a different url path, return it here
export default function generatePageReroute(
    state: RootState,
    urlPath: string,
    _previousUrl: string,
    params: RoutingParams,
    _navigationMethod: Action,
): string {
    // Never redirect from an error
    if (urlPath === urlPaths.error) {
        return '';
    }

    // Redirect if an opportunity does not exist and the requests page requires an opportunity
    if (!state.opportunity.opportunityId && pageRequiresOpportunity(urlPath)) {
        return urlPaths.start;
    }

    if (!state.account.id && pageRequiresAccountSelected(urlPath)) {
        return urlPaths.start;
    }

    // Normal redirections
    switch (urlPath) {
        case urlPaths.returningOpportunity:
            return returnReroutes(state);
        case urlPaths.start:
            return startReroutes(state, _previousUrl);
        case urlPaths.loadOpportunity:
            return loadOpportunityReroutes(state, params);
        default:
            return '';
    }
}
