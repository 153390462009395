import membershipPageRouting from 'components/membership-page-components/membership-page-component/membership-routing';
import membershipPurchaseCompleteRouting from 'components/membership-page-components/membership-purchase-complete-page-component/membership-purchase-complete-routing';
import membershipSignUpPageRouting from 'components/membership-page-components/membership-sign-up-page-component/membership-sign-up-routing';
import membershipVerificationCompletePageRouting from 'components/membership-page-components/membership-verification-complete-page-component/membership-verification-complete-routing';
import membershipVerificationPageRouting from 'components/membership-page-components/membership-verification-page-component/membership-verification-routing';
import casketPageRouting from 'components/page-components/casket-page-component/casket-routing';
import limitedEnhancementsPageRouting from 'components/page-components/limited-enhancements-page-component/limited-enhancements-routing';
import orderSummaryRouting from 'components/page-components/order-summary-component/order-summary-routing';
import outerBurialContainerPageRouting from 'components/page-components/outer-burial-container-page-component/outer-burial-container-routing';
import packagePageRouting from 'components/page-components/package-page-component/package-routing';
import pickupLocationPageRouting from 'components/page-components/pickup-location-page-component/pickup-location-routing';
import preferencesPageRouting from 'components/page-components/preferences-page-component/preferences-routing';
import productQuestionsPageRouting from 'components/page-components/product-questions-page-component/product-questions-routing';
import timingGatePageRouting from 'components/page-components/timing-gate-page-component/timing-gate-routing';
import travelProtectionPageRouting from 'components/page-components/travel-protection-page-component/travel-protection-routing';
import urnPageRouting from 'components/page-components/urn-page-component/urn-routing';
import { RootState } from 'redux/root';
import urlPaths from 'static/constants/enums/urlPaths';
import { Disposition } from 'static/models/enums/disposition';
import { PackageAddonSelectionType } from 'static/models/enums/PackageAddonSelectionType';
import { PackageSelectionType } from 'static/models/enums/PackageSelectionType';
import { Timing } from 'static/models/enums/timing';
import { filterProductQuestions } from 'toolboxes/reuseable-logic/product-question-functions';
import {
    filterProductsForPackage,
    packageHasProductsWithSubcategory,
} from 'toolboxes/reuseable-logic/products/productFunctions';

export type PageGraph = { [key: string]: RoutingNode };

export interface TransitionTestFunction {
    (state: RootState): boolean;
}

interface RoutingEdge {
    destination: urlPaths;
    condition: TransitionTestFunction;
}

// A RoutingNode should be defined for each page component that's part of the flow.
// It defines where you can go in both the forward and reverse directions, and also what the conditions
// are to go to each destination. The transitions are tested in the order the edges are defined, so make
// sure you take that into an account. If going to a stop page needs to take priority over moving on, put
// the stop page edge first.
export interface RoutingNode {
    forwardEdges: RoutingEdge[];
    backwardEdges: RoutingEdge[];
    forAllForwardEdges?: TransitionTestFunction;
    forAllBackwardEdges?: TransitionTestFunction;
}

// Default edges are provided as possible edges to get to every page. You can add an additional condition that gets
// checked for all edges or add specific additional edges (i.e. to go to a stop page) in a *-routing.ts file
// which should be placed in the page component's folder. There are some good examples to look at if you need
// inspiration.
export const defaultEdges: RoutingEdge[] = [
    {
        destination: urlPaths.preferences,
        condition: () => true,
    },
    {
        destination: urlPaths.timingGate,
        condition: (state: RootState): boolean => {
            if (state.featureFlags.enableTimingGatePage) {
                const { feature_TimingGateLocation } = state.account;
                if (feature_TimingGateLocation === 'Before Quote') {
                    if (
                        (state.pickedPreferences.timing === Timing.IMMINENT && !state.account.feature_ImminentSales) ||
                        (state.pickedPreferences.timing === Timing.FUTURE && !state.account.feature_FutureSales)
                    ) {
                        return true;
                    }
                }
            }
            return false;
        },
    },
    {
        destination: urlPaths.timingNotAvailable,
        condition: (state: RootState): boolean => {
            const { feature_TimingGateLocation } = state.account;
            if (
                (state.featureFlags.enableTimingGatePage && !feature_TimingGateLocation) ||
                !state.featureFlags.enableTimingGatePage
            ) {
                if (
                    (state.pickedPreferences.timing === Timing.IMMINENT && !state.account.feature_ImminentSales) ||
                    (state.pickedPreferences.timing === Timing.FUTURE && !state.account.feature_FutureSales)
                ) {
                    return true;
                }
            }
            return false;
        },
    },
    {
        destination: urlPaths.packages,
        condition: () => true,
    },
    {
        destination: urlPaths.casket,
        condition: state => packageHasProductsWithSubcategory(state, 'Casket'),
    },
    {
        destination: urlPaths.urn,
        condition: state =>
            state.selectedPackage.disposition === Disposition.Cremation &&
            packageHasProductsWithSubcategory(state, 'Urn'),
    },
    {
        destination: urlPaths.outerBurialContainer,
        condition: state => packageHasProductsWithSubcategory(state, 'Outer Burial Container'),
    },
    {
        destination: urlPaths.limitedEnhancements,
        condition: (state: RootState): boolean => {
            const {
                selectedPackage,
                opportunity: { timing },
            } = state;

            const items = filterProductsForPackage(
                selectedPackage,
                selectedPackage.packageProducts,
                'Additional Items',
                null,
                timing,
            );

            if (state.account.feature_EnhancementSelection && items.length > 0) {
                return true;
            }

            return false;
        },
    },
    {
        destination: urlPaths.enhancements,
        condition: (state: RootState): boolean => {
            const {
                selectedPackage,
                availableProducts,
                opportunity: { timing },
            } = state;

            if (!state.account.feature_EnhancementSelection) {
                return false;
            }

            const packageTypeNotAllowedToSeeEnhancements =
                state.selectedPackage.packageSelectionType === PackageSelectionType.Limited &&
                (state.selectedPackage.addonSelectionType === PackageAddonSelectionType.Limited ||
                    state.selectedPackage.addonSelectionType === PackageAddonSelectionType.RequiredLimited);

            if (packageTypeNotAllowedToSeeEnhancements) {
                return false;
            }

            const allItems = filterProductsForPackage(
                selectedPackage,
                availableProducts,
                'Additional Items',
                null,
                timing,
                true,
            );

            if (allItems.length === 0) {
                return false;
            }

            return true;
        },
    },
    {
        destination: urlPaths.productQuestions,
        condition: (state: RootState): boolean => {
            // if no product questions, reroute to Transfer page
            const relevantQuestions = filterProductQuestions(state.availableProductQuestions, state.opportunity);
            if (relevantQuestions.length === 0) {
                return false;
            }
            return true;
        },
    },
    {
        destination: urlPaths.pickupLocation,
        condition: state => state.opportunity.timing === Timing.IMMEDIATE,
    },
    {
        destination: urlPaths.travelProtection,
        condition: state => state.availableTravelPackages?.length > 0 && state.opportunity.timing === Timing.FUTURE,
    },
    {
        destination: urlPaths.orderSummary,
        condition: () => true,
    },
    {
        destination: urlPaths.demoAppEndPageComponent,
        condition: state => state.isDemoApp,
    },
];

// mergeEdges merges an array of edges into a node, then returns the node with the merged edges. If node is null, it
// creates a new node with the passed in edges.
function buildGraphNode(nodePath: urlPaths, node: RoutingNode): RoutingNode {
    const forwardEdges = mergeDefaultEdges(nodePath, 'forward');
    const backwardEdges = mergeDefaultEdges(nodePath, 'backward');
    if (node) {
        // give any explicitly defined edges priority over the defaults by putting them first
        return {
            ...node,
            forwardEdges: [...node.forwardEdges, ...forwardEdges],
            backwardEdges: [...node.backwardEdges, ...backwardEdges],
        };
    }
    return {
        forwardEdges,
        backwardEdges,
    };
}

export const defaultMembershipEdges: RoutingEdge[] = [
    {
        destination: urlPaths.membershipQuestion,
        condition: () => true,
    },
    {
        destination: urlPaths.membershipVerification,
        condition: () => true,
    },
    {
        destination: urlPaths.membershipVerificationComplete,
        condition: state => state.membership.membershipVerificationValid,
    },
    {
        destination: urlPaths.membershipSignUp,
        condition: () => true,
    },
    {
        destination: urlPaths.membershipPurchaseComplete,
        condition: state => state.membership.membershipSignUpValid,
    },
];

function mergeDefaultEdges(nodePath: urlPaths, direction: 'forward' | 'backward'): RoutingEdge[] {
    const defaultEdgesToMerge = [...defaultMembershipEdges, ...defaultEdges];
    if (direction === 'backward') {
        defaultEdgesToMerge.reverse();
    }
    // we want to start looking at edges to pages _after_ the current one only. This also happens to handle the
    // case where the current page doesn't have any edges leading to it (i.e. the starting page) because findIndex will
    // return -1, then we add 1 to start at 0. Additionally, if we're at the end, startingIndex will end up being
    // equal to the length of the array, and the for loop will be skipped.
    const startingIndex = defaultEdgesToMerge.findIndex(e => e.destination === nodePath) + 1;
    return defaultEdgesToMerge.slice(startingIndex);
}

export const pageGraph: PageGraph = {
    [urlPaths.membershipQuestion]: buildGraphNode(urlPaths.membershipQuestion, membershipPageRouting),
    [urlPaths.membershipVerification]: buildGraphNode(
        urlPaths.membershipVerification,
        membershipVerificationPageRouting,
    ),
    [urlPaths.membershipVerificationComplete]: buildGraphNode(
        urlPaths.membershipVerificationComplete,
        membershipVerificationCompletePageRouting,
    ),
    [urlPaths.membershipSignUp]: buildGraphNode(urlPaths.membershipSignUp, membershipSignUpPageRouting),
    [urlPaths.membershipPurchaseComplete]: buildGraphNode(
        urlPaths.membershipPurchaseComplete,
        membershipPurchaseCompleteRouting,
    ),
    [urlPaths.preferences]: buildGraphNode(urlPaths.preferences, preferencesPageRouting),
    [urlPaths.timingGate]: buildGraphNode(urlPaths.timingGate, timingGatePageRouting),
    [urlPaths.packages]: buildGraphNode(urlPaths.packages, packagePageRouting),
    [urlPaths.casket]: buildGraphNode(urlPaths.casket, casketPageRouting),
    [urlPaths.urn]: buildGraphNode(urlPaths.urn, urnPageRouting),
    [urlPaths.outerBurialContainer]: buildGraphNode(urlPaths.outerBurialContainer, outerBurialContainerPageRouting),
    [urlPaths.limitedEnhancements]: buildGraphNode(urlPaths.limitedEnhancements, limitedEnhancementsPageRouting),
    [urlPaths.enhancements]: buildGraphNode(urlPaths.enhancements, null), // no additional conditions to move on
    [urlPaths.productQuestions]: buildGraphNode(urlPaths.productQuestions, productQuestionsPageRouting),
    [urlPaths.pickupLocation]: buildGraphNode(urlPaths.pickupLocation, pickupLocationPageRouting),
    [urlPaths.travelProtection]: buildGraphNode(urlPaths.travelProtection, travelProtectionPageRouting),
    [urlPaths.orderSummary]: buildGraphNode(urlPaths.orderSummary, orderSummaryRouting),
    [urlPaths.demoAppEndPageComponent]: buildGraphNode(urlPaths.demoAppEndPageComponent, null),
    [urlPaths.timingNotAvailable]: { forwardEdges: [], backwardEdges: [] }, // can't go anywhere from here
    [urlPaths.sendingFailingRequest]: { forwardEdges: [], backwardEdges: [] }, // can't go forward will be redirected to error page
    [urlPaths.errorSubmission]: { forwardEdges: [], backwardEdges: [] },
    [urlPaths.locationSelection]: { forwardEdges: [], backwardEdges: [] },
};

export const stopPages = [urlPaths.timingNotAvailable].reduce((pageSet, p) => {
    pageSet.add(p);
    return pageSet;
}, new Set<urlPaths>());
