import Package from 'static/models/Package';
import Product from 'static/models/Product';
import { TravelOpportunityResponse } from 'static/models/TravelOpportunityResponse';

import productServiceClient from '../apis/productServiceClient';
import { sendRequest } from '../web-request-tools';

export function fetchTravelProviderAndProducts(funeralHomeId: string): Promise<any> {
    return sendRequest(productServiceClient.get(`/travelProtection/${funeralHomeId}/provider-and-packages`));
}

export function runCreateTravelOpportunityFromOpportunityId(
    travelProviderId: string,
    salesOpportunityId: string,
    selectedProducts: Product[],
): Promise<string> {
    return sendRequest(
        productServiceClient.post(
            `travelProtection/create/${travelProviderId}/${salesOpportunityId}`,
            selectedProducts,
        ),
    );
}

export function fetchTravelOpportunityFromOpportunityId(opportunityId: string): Promise<TravelOpportunityResponse> {
    return sendRequest(productServiceClient.get<TravelOpportunityResponse>(`/opportunity/${opportunityId}/travel`));
}

export function runDeleteTravelOpportunityFromOpportunityId(
    travelOpportunityId: string,
    salesOpportunityId: string,
): Promise<TravelOpportunityResponse> {
    return sendRequest(
        productServiceClient.delete<TravelOpportunityResponse>(
            `/travelProtection/delete/${travelOpportunityId}/${salesOpportunityId}`,
        ),
    );
}

export async function runSaveTravelPackageToTravelOpportunity(
    opportunityId: string,
    packageToSave: Package,
): Promise<string> {
    const productList = {
        opportunityId,
        packageId: packageToSave.id,
        packageName: packageToSave.name,
        products: packageToSave.products,
    };
    return sendRequest(productServiceClient.post<string>('/packages/selectProducts', productList));
}
