import { FormData } from 'hooks/forms/FormTypes';
import DemoFormData from 'static/models/demo/demoFormData';
import { LeadContact } from 'static/models/LeadContact';
import { runCreateLead, runCreateLeadFromDemo } from 'toolboxes/web-requests/specific-requests/lead-requests';

export async function createLead(lead: LeadContact): Promise<any> {
    const response = await runCreateLead(lead);
    return response;
}

export async function createLeadFromDemo(lead: FormData<DemoFormData>): Promise<any> {
    const response = await runCreateLeadFromDemo({
        title: lead.title.value,
        firstName: lead.firstName.value,
        lastName: lead.lastName.value,
        phone: lead.phone.value,
        email: lead.email.value,
        website: lead.website.value,
        company: lead.company.value,
        callsPerYear: lead.callsPerYear.value,
    });
    return response;
}
