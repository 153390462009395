import React, { HTMLProps } from 'react';

const ExternalLinkComponent: React.FunctionComponent<PropShape> = ({ children, href, id, newTab, ...rest }) => {
    let otherProps: HTMLProps<HTMLAnchorElement> = { ...rest };
    if (newTab) {
        otherProps = { ...otherProps, rel: 'noreferrer', target: '_blank' };
    }
    return (
        <a href={href} id={`external-link-${id}`} {...otherProps}>
            {children}
        </a>
    );
};
interface PropShape extends HTMLProps<HTMLAnchorElement> {
    href: string;
    id: string;
    newTab?: boolean;
}

ExternalLinkComponent.defaultProps = {
    newTab: true,
};

export default ExternalLinkComponent;
