import { leadContactValidator } from 'hooks/forms/validators';
import { RoutingNode } from 'hooks/useRouteCalculator/routing-graph';

const timingGatePageRouting: RoutingNode = {
    forwardEdges: [],
    backwardEdges: [],
    forAllForwardEdges: state => {
        return leadContactValidator(state.leadContact) === null;
    },
    forAllBackwardEdges: () => true,
};

export default timingGatePageRouting;
