import React from 'react';

import { Dialog, DialogActions, DialogContent, Typography } from '@material-ui/core';
import IdleTimer from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { resetAction } from 'redux/root';
import { clearOpportunityIdLocalStorage } from 'toolboxes/reuseable-logic/local-storage-getters-setters';

import ButtonComponent from '../layout-components/button-components/button-component/ButtonComponent';

const DemoAppIdleComponent: React.FunctionComponent<PropShape> = (props: PropShape) => {
    const dispatch = useDispatch();

    const DefaultTimeOut = props.timeout ?? 60 * 1000; // 1 minute

    const resetSession = () => {
        dispatch(resetAction());
        clearOpportunityIdLocalStorage();
        window.location.pathname = '/';
    };

    const continueWorking = _event => {
        props.callback();
    };

    return (
        <Dialog open={props.show}>
            <IdleTimer timeout={DefaultTimeOut} onIdle={resetSession} />
            <DialogContent>
                <Typography style={{ textAlign: 'center' }} variant="h2">
                    Are you still working?
                </Typography>
            </DialogContent>
            <DialogActions>
                <ButtonComponent id="continue" onClick={continueWorking}>
                    Continue working
                </ButtonComponent>

                <ButtonComponent id="reset" onClick={resetSession}>
                    Reset Session
                </ButtonComponent>
            </DialogActions>
        </Dialog>
    );
};

interface PropShape {
    // Shape of passed in props
    callback: Function;
    show?: boolean;
    timeout?: number;
}

export default DemoAppIdleComponent;
