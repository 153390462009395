import React from 'react';

import { Button } from '@material-ui/core';
import { Link } from 'react-router-dom';

const ButtonLinkComponent: React.FunctionComponent<PropShape & React.HTMLAttributes<HTMLElement>> = ({
    children,
    ...rest
}) => {
    return (
        <Button {...rest} component={Link}>
            {children}
        </Button>
    );
};

interface PropShape {
    // Shape of passed in props
    to: string;
    id: string;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    disabled?: boolean;
    color?: 'primary' | 'secondary';
    variant?: 'contained' | 'outlined';
    fullWidth?: boolean;
    disableRipple?: boolean;
}

ButtonLinkComponent.defaultProps = {
    // Set default values for props
    onClick: () => {},
    disabled: false,
    color: 'primary',
    variant: 'contained',
    fullWidth: false,
    disableRipple: false,
};

export default ButtonLinkComponent;
