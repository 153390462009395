import React, { ReactNode } from 'react';

import { makeStyles, Card } from '@material-ui/core';
import CardButtonOptionsComponent from 'components/form-components/card-button-options-component/CardButtonOptionsComponent';
import ButtonOption from 'static/models/ButtonOption';

import ProductQuestionCardHeaderComponent from './ProductQuestionCardHeaderComponent';

const useStyles = makeStyles(theme => ({
    card: {
        margin: theme.spacing(1),
        position: 'relative',
        overflow: 'visible',
        borderRadius: 0,
    },
    cardHeader: {
        paddingBottom: 0,
    },
    cardSubtitle: {
        paddingTop: '5px',
        paddingBottom: '10px',
    },
    cardBodyBold: {
        fontWeight: 'bold',
    },
    editButton: {
        float: 'right',
        paddingLeft: 10,
        paddingRight: 10,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        },
    },
    multilineRadioLabel: {
        display: 'inline-block',
        cursor: 'pointer',
        justifyContent: 'flex-start',
        textAlign: 'left',
        marginBottom: '1rem',
        backgroundColor: '#F0F0F0',
        '&:hover': {
            backgroundColor: '#a8a8a8',
            color: '#fff',
        },
    },
}));

function ProductQuestionCardComponent<T>(props: PropShape<T>): React.ReactElement<PropShape<T>> {
    const classes = useStyles();
    const {
        answerSelected,
        buttonIdBase,
        cardHeader,
        cardSubheader,
        editButtonId,
        onEditClick,
        onOptionClick,
        options,
        selectedText,
    } = props;
    return (
        <Card className={classes.card} elevation={0}>
            <ProductQuestionCardHeaderComponent
                editButtonId={editButtonId}
                editButtonTitle={props.editButtonTitle}
                selectedText={selectedText}
                answerSelected={answerSelected}
                onEditClick={onEditClick}
                cardHeader={cardHeader}
                cardSubheader={cardSubheader}
            />
            {!answerSelected && (
                <CardButtonOptionsComponent buttonIdBase={buttonIdBase} onClick={onOptionClick} options={options} />
            )}
        </Card>
    );
}

interface PropShape<T> {
    // Shape of passed in props
    editButtonId: string;
    editButtonTitle: string;
    selectedText: string;
    answerSelected: boolean;
    onEditClick: () => void;
    cardHeader: ReactNode;
    cardSubheader?: ReactNode;
    buttonIdBase: string;
    onOptionClick: (opt: T) => void;
    options: ButtonOption<T>[];
}

ProductQuestionCardComponent.defaultProps = {
    // Set default values for props
    answerSelected: false,
    editButtonId: '',
    textFetcher: () => '',
    onEditClick: () => {},
    cardHeader: '',
    buttonIdBase: '',
    onOptionClick: () => {},
};

export default ProductQuestionCardComponent;
