import { setAccount } from 'redux/account/root';
import { RootState } from 'redux/root';
import { setTravelOpportunity } from 'redux/travel-protection/travelOpportunity/root';
import urlPaths from 'static/constants/enums/urlPaths';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import { getAccountByOpportunityId } from 'toolboxes/server-application-buffer/account-data';
import { getTravelOpportunityFromSalesOpportunity } from 'toolboxes/server-application-buffer/travel-data';

export default function loadOpportunityExitFunctions(state: RootState, dispatch: Function): BlockingFunction[] {
    const functionsToRun: BlockingFunction[] = [];

    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>(async (resolve, reject) => {
                try {
                    const response = await getAccountByOpportunityId(state.opportunity.opportunityId);
                    dispatch(setAccount(response));
                    return resolve();
                } catch {
                    return reject();
                }
            }),
        blocksPageStarts: [urlPaths.initialLoadAndCreate],
    });

    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>(async (resolve, reject) => {
                try {
                    const response = await getTravelOpportunityFromSalesOpportunity(state.opportunity.opportunityId);
                    dispatch(setTravelOpportunity(response));
                    return resolve();
                } catch {
                    return reject();
                }
            }),
        blocksPageStarts: [urlPaths.initialLoadAndCreate],
    });

    return functionsToRun;
}
