import Account from 'static/models/Account';

export function formatDate(date: string): string {
    const dateData = new Date(date);
    dateData.setMinutes(dateData.getMinutes() + dateData.getTimezoneOffset());
    return `${dateData.getUTCMonth() + 1}/${dateData.getUTCDate()}/${dateData.getUTCFullYear()}`;
}

export function formatPhoneNumber(phoneNumber: string): string {
    try {
        const cleaned = phoneNumber.replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        return '(' + match[1] + ') ' + match[2] + '-' + match[3];
    } catch {
        return phoneNumber;
    }
}

export function formatHiddenSSN(ssn: string): string {
    try {
        const cleaned = ssn.replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{2})(\d{4})$/);
        return `XXX-XX-${match[3]}`;
    } catch {
        return 'XXX-XX-XXXX';
    }
}

export function formatMonetaryAmount(monetaryAmount: string | number): string {
    try {
        const stringifiedNumber = monetaryAmount.toString();
        const cleaned = stringifiedNumber.replace(/[^\d.-]/g, '');
        const numericAmount = parseFloat(cleaned);
        const displayValue = `$${numericAmount.toFixed(2)}`;
        return displayValue;
    } catch {
        return `$${monetaryAmount}`;
    }
}

export function formatRemoveNonNumeric(value: string): string {
    return value.replace(/\D/g, '');
}

export function formatCurrency(amount: number, includeCents = false): string {
    const absAmount = Math.abs(amount);
    const dollars = (includeCents ? Math.floor(absAmount) : Math.round(absAmount)).toString();
    let result = '';
    let digitCounter = 0;
    const isNegative = amount < 0;
    for (let i = dollars.length - 1; i >= 0; i--) {
        digitCounter++;
        result = dollars.charAt(i) + result;
        if (digitCounter % 3 === 0 && i !== 0) {
            result = ',' + result;
        }
    }
    if (includeCents) {
        const cents = Math.abs(Math.round(absAmount * 100) % 100);
        result = `${result}.${cents.toString().padStart(2, '0')}`;
    }
    if (isNegative) {
        return `($${result})`;
    }
    return `$${result}`;
}

export function formatAccountAddress(account: Account): string {
    return `${account.address}, ${account.city}, ${account.state} ${account.zip}`;
}
