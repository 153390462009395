import { pickupLocationValidator } from 'hooks/forms/validators';
import { RoutingNode } from 'hooks/useRouteCalculator/routing-graph';

const pickupLocationPageRouting: RoutingNode = {
    forwardEdges: [],
    backwardEdges: [],
    forAllForwardEdges: state =>
        state.pickupLocation.isWithinServiceRadius &&
        state.pickupLocation.pickupLocationType?.length > 0 &&
        pickupLocationValidator(state.pickupLocation) === null,
};

export default pickupLocationPageRouting;
