import React, { ReactElement } from 'react';

import { alpha, makeStyles, Radio, RadioProps, Theme } from '@material-ui/core';
import clsx from 'clsx';

// Inspired by https://material-ui.com/components/radio-buttons/#customized-radios

const commonIconProps = (theme: Theme, opacity: number) => ({
    borderRadius: '50%',
    borderWidth: 3,
    borderStyle: 'solid',
    width: theme.spacing(3),
    height: theme.spacing(3),
    borderColor: alpha(theme.palette.primary.main, opacity),
});

const useStyles = makeStyles(theme => ({
    icon: {
        ...commonIconProps(theme, 0.5),
        backgroundColor: '#FFF',
    },
    checkedIcon: {
        ...commonIconProps(theme, 1),
        backgroundColor: theme.palette.primary.main,
        boxShadow: 'inset 0 0 0 3px #FFF',
    },
}));

function StyledRadio(props: RadioProps): ReactElement {
    const classes = useStyles();

    return (
        <Radio
            disableRipple
            color="default"
            checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

export default StyledRadio;
