import { setDisposition, setOpportunity } from 'redux/opportunity/root';
import { setPickedPreferences } from 'redux/picked-preferences/root';
import { setAvailablePackages } from 'redux/product-package-questions/availablePackages/root';
import { setAvailableProducts } from 'redux/product-package-questions/availableProducts/root';
import { RootState } from 'redux/root';
import { setSelectedPackage } from 'redux/selectedPackage/root';
import smartlookClient from 'smartlook-client';
import urlPaths from 'static/constants/enums/urlPaths';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import Package from 'static/models/Package';
import Product from 'static/models/Product';
import { stripAttributes } from 'toolboxes/reuseable-logic/html';
import { setOpportunityIdLocalStroage } from 'toolboxes/reuseable-logic/local-storage-getters-setters';
import { getPackageDispositions, preparePackages } from 'toolboxes/reuseable-logic/package-functions';
import { localSetAccount } from 'toolboxes/server-application-buffer/account-data';
import { createOpportunity } from 'toolboxes/server-application-buffer/opportunity-data';
import {
    getAccountPackages,
    getAccountProducts,
} from 'toolboxes/server-application-buffer/products-packages-questions-data';

export default function InitialLoadAndCreateFunctions(state: RootState, dispatch: Function): BlockingFunction[] {
    const functionsToRun: BlockingFunction[] = [];

    functionsToRun.push({
        promiseGenerator: async () => {
            // We create the opportunity in this function to make sure it gets created before
            // we preload disposition below. Otherwise, this call to setOpportunity may come after
            // the one below and undo the preload
            const account = await localSetAccount(state, dispatch);

            if (!state.opportunity.opportunityId) {
                const opportunity = await createOpportunity(account.id);
                setOpportunityIdLocalStroage(opportunity.opportunityId, opportunity.closeDate);
                dispatch(setOpportunity(opportunity));

                // after the opportunity is created, set the smarlook User Id to the opportunity Id
                if (smartlookClient.initialized()) {
                    smartlookClient.identify(opportunity.opportunityId, {});
                }
            } else {
                // if we already have an opportunity Id, set the smartlook User Id
                if (smartlookClient.initialized()) {
                    smartlookClient.identify(state.opportunity.opportunityId, {});
                }
            }

            // hybrid packages require products to initialize
            const promises: [Promise<Product[]>, Promise<Package[]>] = [
                getAccountProducts(account.id),
                getAccountPackages(account.id),
            ];

            const [products, packages] = await Promise.all(promises);
            const productsWithCleanedDescriptions: Product[] = products.map(p => ({
                ...p,
                salesDescription: stripAttributes(p.salesDescription),
            }));
            dispatch(setAvailableProducts(productsWithCleanedDescriptions));

            const dispositions = getPackageDispositions(packages);
            if (dispositions?.length === 1) {
                // if there's only one disposition, preload it into state
                dispatch(setDisposition(dispositions[0]));
                dispatch(setPickedPreferences({ ...state.pickedPreferences, disposition: dispositions[0] }));
            }

            const preparedPackages = preparePackages(packages, products);
            dispatch(setAvailablePackages(preparedPackages));

            const selectedPackage = preparedPackages.find(x => x.id === state.opportunity.packageId);

            if (selectedPackage) {
                dispatch(setSelectedPackage(selectedPackage));
            }
        },
        blocksPageStarts: [urlPaths.initialLoadAndCreate],
    });

    return functionsToRun;
}
