import { useDispatch, useSelector } from 'react-redux';
import { setErrorCaseCreationFailed, setErrorCaseNumber, setErrorData } from 'redux/errorLogging/root';
import ErrorData from 'static/models/errorLogging/errorData';
import ErrorLogging from 'static/models/errorLogging/errorLogging';

import { RootState } from '../redux/root';

interface ReturnType {
    errorLogging: ErrorLogging;
    setErrorData: (errorData: ErrorData) => void;
    setErrorCaseCreationFailed: (value: boolean) => void;
    setErrorCaseNumber: (caseNumber: string) => void;
}

export const useErrorData = (): ReturnType => {
    const dispatch = useDispatch();

    return {
        errorLogging: useSelector((state: RootState) => state.errorLogging),
        setErrorData: (value: ErrorData) => dispatch(setErrorData(value)),
        setErrorCaseCreationFailed: (value: boolean) => dispatch(setErrorCaseCreationFailed(value)),
        setErrorCaseNumber: (value: string) => dispatch(setErrorCaseNumber(value)),
    };
};
