import { Disposition } from 'static/models/enums/disposition';
import { PackageSelectionType } from 'static/models/enums/PackageSelectionType';
import { Timing } from 'static/models/enums/timing';
import Package from 'static/models/Package';
import Product from 'static/models/Product';

import { filterProductsByCategory, getFuneralSubTotalPrice } from './products/productFunctions';

export function matchPackageAttributes<T>(attributes: T[], attribute: T): boolean {
    return attributes.length === 0 || attributes.includes(attribute);
}

export const filterPackages = (packages: Package[], disposition: Disposition): Package[] => {
    return packages.filter(x => x.disposition === disposition);
};

export const filterPackagesProductsByTiming = (packages: Package[], timing: Timing): Package[] => {
    const filteredPackages: Package[] = [];

    packages.forEach(funeralPackage => {
        const filteredPackage = filterPackageProductsByTiming(funeralPackage, timing);
        filteredPackages.push(filteredPackage);
    });

    return filteredPackages;
};

export const filterPackageProductsByTiming = (funeralPackage: Package, timing: Timing): Package => {
    const copiedPackage = { ...funeralPackage };

    if (copiedPackage) {
        const validProducts = funeralPackage.products.filter(
            x => !x.timingTypes || x.timingTypes.length === 0 || x.timingTypes.includes(timing),
        );

        copiedPackage.products = validProducts;
    }

    return copiedPackage;
};

export const updatePackagePrices = (packages: Package[]): void => {
    packages.forEach(funeralPackage => {
        funeralPackage.price = getFuneralSubTotalPrice(funeralPackage.products);
    });
};

export const sortDisplayPackages = (packages: Package[]): Package[] => {
    return packages.sort((a, b) => {
        if (!!!a.displayOrder && !!!b.displayOrder) {
            return b.price - a.price; // both a and b do not have display orders then sort by price high to low
        } else if (!!!a.displayOrder && !!b.displayOrder) {
            if (a.displayOrder === 0) {
                return -1;
            }
            return 1; // if a doesn't have a display order and b does then put b before a (nulls towards the back)
        } else if (!!a.displayOrder && !!!b.displayOrder) {
            if (b.displayOrder === 0) {
                return 1;
            }
            return -1; // if a has a display order and b does not then put a before b (nulls towards the back)
        } else {
            // both a and b have display order
            if (a.displayOrder === b.displayOrder) {
                return b.price - a.price; // if the display order of a and b are the same then sort highest priced first
            } else {
                return a.displayOrder - b.displayOrder; // place the lower display order number first (1 before 2)
            }
        }
    });
};

export function getPackageDispositions(packages: Package[]): Disposition[] {
    const dispositions = packages.map(p => p.disposition);
    const dispositionSet = new Set<Disposition>();
    dispositions.forEach(d => {
        if (!dispositionSet.has(d)) {
            dispositionSet.add(d);
        }
    });
    return Array.from(dispositionSet);
}

export const preparePackages = (packages: Package[], products: Product[]): Package[] => {
    return packages.map(funeralPackage => {
        // hybrid packages need to be converted into Limited packages
        // hybrid packages return their associated products by id instead of the full product
        // because of this we need all products in order to convert them to Limited Packages
        // if not hybrid, can just return the package
        if (funeralPackage.packageSelectionType !== PackageSelectionType.Hybrid) {
            return funeralPackage;
        }

        // find all included products from the products array
        // each one need to be marked as included
        // this allows hybrid packages to have one product that is part of the package (can not remove)
        // while also allowing the same product (same id) to be selected again without effecting the included one
        const includedProducts = products
            .filter(x => funeralPackage.includedProducts.includes(x.id))
            .map(product => {
                // copy the original product and mark as includedInPackage
                // this makes the product 'unique', this duplicate product
                // does not show up in product pages but will become a default
                // product of the package
                return { ...product, includedInPackage: true };
            });

        // default products are 'pre-selected' products
        const defaultProducts = funeralPackage.defaultProducts.map(productId => {
            // first check if the product exists inside 'limitedProducts'
            // this allows for the benefit of limited packages, such as price overrides
            const defaultLimitedProduct = funeralPackage.limitedProducts.find(x => x.id === productId);
            if (defaultLimitedProduct) {
                return defaultLimitedProduct;
            }

            // if it does not exist, then we find the product in the full list of products
            const defaultProduct = products.find(x => x.id === productId);
            if (defaultProduct) {
                return defaultProduct;
            }
        });

        // each category of a hybrid package can be either limited or full (this is what makes it 'hybrid')
        // but the frontend doesn't understand the difference, so we need to load up the products it needs
        // example: if the package contains any caskets, then we use only those included for the caskets (limited caskets)
        // but if no caskets are found in the package, then then we use all caskets from the account products (full caskets)
        const packageProducts = [
            ...getAvailableProductsForCategory(funeralPackage.limitedProducts, products, 'Merchandise', 'Casket'),
            ...getAvailableProductsForCategory(funeralPackage.limitedProducts, products, 'Merchandise', 'Urn'),
            ...getAvailableProductsForCategory(
                funeralPackage.limitedProducts,
                products,
                'Merchandise',
                'Outer Burial Container',
            ),
            ...getAvailableProductsForCategory(funeralPackage.limitedProducts, products, 'Additional Items', null),
        ];

        // return a new package
        // clear out hybrid specific fields
        return {
            ...funeralPackage,
            products: [...includedProducts, ...defaultProducts],
            packageProducts: packageProducts,
            includedProducts: null,
            defaultProducts: null,
            limitedProducts: null,
            packageSelectionType: PackageSelectionType.Limited,
        };
    });
};

function getAvailableProductsForCategory(
    limitedProducts: Product[],
    allProducts: Product[],
    category: string,
    subcategory: string,
): Product[] {
    const filteredLimitedProducts = filterProductsByCategory(limitedProducts, category, subcategory);

    // if products are found in limited, return
    if (filteredLimitedProducts.length > 0) {
        return filteredLimitedProducts;
    }

    // otherwise return whatever is found within all products
    const filteredAllProducts = filterProductsByCategory(allProducts, category, subcategory);
    return filteredAllProducts;
}

export function getCountOfRequiredProducts(selectedProducts: Product[]): number {
    return selectedProducts.reduce((total, product) => {
        if (
            product.category === 'Additional Items' &&
            product.packageProduct &&
            !product.questionId &&
            !product.isHiddenFromConsumer &&
            !product.includedInPackage
        ) {
            return total + product.quantity;
        }
        return total;
    }, 0);
}
