import React from 'react';
import { useCallback } from 'react';

import {
    getMembershipVerificationSubtitle,
    getMembershipVerificationTitle,
    MembershipVerification,
    MembershipVerificationFormModel,
} from '@efuneral/memberships';
import CardComponent from 'components/global-components/layout-components/ui-components/card-component/CardComponent';
import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import { useMembership } from 'hooks/useMembership';

const MembershipVerificationPageComponent: React.FunctionComponent = () => {
    const { isMembershipVerificationValid, setMembershipVerificationValid, setMembershipVerificationContact } =
        useMembership();

    const handleOnFormChange = useCallback(
        (isValid: boolean, contact: MembershipVerificationFormModel) => {
            if (isMembershipVerificationValid !== isValid) {
                setMembershipVerificationValid(isValid);
                setMembershipVerificationContact(contact);
            }
        },
        [isMembershipVerificationValid, setMembershipVerificationValid, setMembershipVerificationContact],
    );

    return (
        <PageContentWrapperComponent
            title={'Membership'}
            subtitle={
                'We require a membership to purchase our services online. Please answer the following questions prior to starting an online funeral arrangement.'
            }
        >
            <CardComponent title={getMembershipVerificationTitle()} subtitle={getMembershipVerificationSubtitle()}>
                <MembershipVerification showTitle={false} showSubTitle={false} onFormChange={handleOnFormChange} />
            </CardComponent>
        </PageContentWrapperComponent>
    );
};

export default MembershipVerificationPageComponent;
