import Package from 'static/models/Package';
import Product from 'static/models/Product';
import { ProductQuestion } from 'static/models/ProductQuestions';
import {
    fetchAllProducts,
    fetchAllPackages,
    fetchAllProductQuestions,
} from 'toolboxes/web-requests/specific-requests/products-packages-questions-requests';

import { setAvailableProductQuestions } from '../../redux/product-package-questions/availableProductQuestions/root';
import { RootState } from '../../redux/root';
import { localSetAccount } from './account-data';

// Product Data Below
export async function getAccountProducts(accountId: string): Promise<Product[]> {
    return await fetchAllProducts(accountId);
}

// Package Data Below
export async function getAccountPackages(accountId: string): Promise<Package[]> {
    return await fetchAllPackages(accountId);
}

// Product Question Data Below
export async function getAccountProductQuestions(accountId: string): Promise<ProductQuestion[]> {
    return await fetchAllProductQuestions(accountId);
}

export async function getAndDispatchAccountProductQuestions(state: RootState, dispatch: Function): Promise<void> {
    const account = await localSetAccount(state, dispatch);

    const response = await getAccountProductQuestions(account.id);
    dispatch(setAvailableProductQuestions(response));
    return;
}
