import { RootState } from 'redux/root';
import urlPaths from 'static/constants/enums/urlPaths';
import { getOpportunityIdLocalStorage } from 'toolboxes/reuseable-logic/local-storage-getters-setters';

export default function returnReroutes(state: RootState): string {
    const savedOpportunityId = getOpportunityIdLocalStorage(state);
    if (!savedOpportunityId) {
        return urlPaths.start;
    }
}
