import getEnvironment from 'env-config/get-environment';
import { RootState } from 'redux/root';

export function transitionToPurchaserFlow(state: RootState): void {
    const environment = getEnvironment();

    const subdomain = state.account.subdomain;
    const domain = environment.purchaserFlowUrl;
    const opportunityId = state.opportunity.opportunityId;

    window.location.href = `http://${subdomain}.${domain}loadOpportunity?id=${opportunityId}`;
}

export function transitionToPurchaserFlowPassedInfo(accountSubdomain: string, oppId: string, isDemoApp = false): void {
    const environment = getEnvironment();

    const subdomain = accountSubdomain;
    const domain = environment.purchaserFlowUrl;
    const opportunityId = oppId;

    if (isDemoApp) {
        return;
    }
    window.location.href = `http://${subdomain}.${domain}loadOpportunity?id=${opportunityId}`;
}
