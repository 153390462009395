import React from 'react';

import { CardContent, Typography } from '@material-ui/core';
import CardComponent from 'components/global-components/layout-components/ui-components/card-component/CardComponent';
import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { formatMonetaryAmount } from 'toolboxes/reuseable-logic/string-formatters';

const MembershipPurchaseCompletePageComponent: React.FunctionComponent = () => {
    const { selectedAccount } = useSelectedAccount();

    return (
        <PageContentWrapperComponent
            title={'Membership'}
            subtitle={
                'We require a membership to purchase our services online. Please answer the following questions prior to starting an online funeral arrangement.'
            }
        >
            <CardComponent>
                <CardContent>
                    <Typography variant="h3">Membership Purchase Confirmation</Typography>
                    <Typography variant="subtitle2">
                        Thank you for your membership purchase from {selectedAccount.name}. Your card was successfully
                        charged {formatMonetaryAmount(selectedAccount.membershipCost)}. We've sent you an email
                        confirming your purchase.
                    </Typography>
                </CardContent>
                <CardContent>
                    <Typography variant="subtitle2">Click continue below to plan now.</Typography>
                </CardContent>
            </CardComponent>
        </PageContentWrapperComponent>
    );
};

export default MembershipPurchaseCompletePageComponent;
