import { setErrorCaseCreationFailed, setErrorCaseId, setErrorCaseNumber } from 'redux/errorLogging/root';
import { RootState } from 'redux/root';
import urlPaths from 'static/constants/enums/urlPaths';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import {
    sendErrorDataToCreateErrorCase,
    sendUpdateErrorCase,
} from 'toolboxes/server-application-buffer/opportunity-data';

export default function ErrorExitFunctions(state: RootState, dispatch: Function): BlockingFunction[] {
    const functionsToRun: BlockingFunction[] = [];

    functionsToRun.push({
        promiseGenerator: async () => {
            if (state.errorLogging.errorCaseCreationFailed) {
                const result = await sendErrorDataToCreateErrorCase(state);
                if (result.caseId !== `couldn't make the error case`) {
                    dispatch(setErrorCaseCreationFailed(false));
                    dispatch(setErrorCaseNumber(result.caseNumber));
                    dispatch(setErrorCaseId(result.caseId));
                    sendUpdateErrorCase(state, result.caseId);
                    return;
                }
            } else if (state.errorLogging.errorCaseNumber) {
                sendUpdateErrorCase(state, state.errorLogging.errorCaseId);
                return;
            }
            return;
        },
        blocksPageStarts: [urlPaths.errorSubmission],
    });

    return functionsToRun;
}
