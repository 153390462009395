import React from 'react';

import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { getPricelistUrl } from 'toolboxes/reuseable-logic/pricelist';

import ExternalLinkComponent from '../external-link-component/ExternalLinkComponent';

const PriceListLinkComponent: React.FunctionComponent<PropShape> = ({ children, className }) => {
    const { selectedAccount } = useSelectedAccount();
    return (
        <ExternalLinkComponent id="pricelist" href={getPricelistUrl(selectedAccount)} className={className}>
            {children}
        </ExternalLinkComponent>
    );
};

interface PropShape {
    className?: string;
}

PriceListLinkComponent.defaultProps = {
    className: '',
};

export default PriceListLinkComponent;
