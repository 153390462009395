import React from 'react';

import { Button, ButtonProps, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

type StyleProps = {
    color: string;
};

const makeSelectedStyle = (theme: Theme) => ({
    backgroundColor: ({ color }) => theme.palette[color].main,
    color: '#fff',
    borderColor: ({ color }) => theme.palette[color].main,
    borderWidth: 3,
});

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
    // this is a bit ridiculous, is there a better way to override material's styles?
    selected: {
        ...makeSelectedStyle(theme),
        '&:hover': {
            ...makeSelectedStyle(theme),
            '&:disabled': {
                ...makeSelectedStyle(theme),
            },
        },
        '&:disabled': {
            ...makeSelectedStyle(theme),
        },
    },
}));

const ButtonComponent: React.FunctionComponent<PropShape> = ({ children, color, id, selected, ...rest }) => {
    const classes = useStyles({ color });

    return (
        <Button
            {...rest}
            color={color}
            id={`button-${id}`}
            className={clsx(rest.className, { [classes.selected]: selected })}
        >
            {children}
        </Button>
    );
};

interface PropShape extends ButtonProps {
    // Shape of passed in props
    id: string;
    selected?: boolean;
}

ButtonComponent.defaultProps = {
    // Set default values for props
    onClick: () => {},
    color: 'primary',
    variant: 'contained',
    selected: false,
};

export default ButtonComponent;
