import { setAccount } from 'redux/account/root';
import { setAvailableAccounts } from 'redux/availableAccounts/root';
import { RootState } from 'redux/root';
import urlPaths from 'static/constants/enums/urlPaths';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import { getSubdomain } from 'toolboxes/reuseable-logic/get-subdomain';
import { getParentAndChildrenAccountsBySubdomain } from 'toolboxes/server-application-buffer/account-data';

export const loadAccounts = (state: RootState, dispatch: Function): BlockingFunction[] => {
    const functionsToRun: BlockingFunction[] = [];
    let subdomain = getSubdomain();

    if (state.isDemoApp) {
        subdomain = 'acmebasic';
    }

    functionsToRun.push({
        promiseGenerator: async () => {
            const response = await getParentAndChildrenAccountsBySubdomain(subdomain);

            if (response.length === 0) {
                window.location.href = 'https://efuneral.com/';
            }

            if (response.length === 1) {
                dispatch(setAccount(response[0]));
                return;
            }
            dispatch(setAvailableAccounts(response));
        },
        blocksPageStarts: [urlPaths.start, urlPaths.membershipQuestion, urlPaths.locationSelection],
    });

    return functionsToRun;
};
