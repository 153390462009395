import React from 'react';

import { makeStyles } from '@material-ui/core';
import ButtonComponent from 'components/global-components/layout-components/button-components/button-component/ButtonComponent';

const useStyles = makeStyles(theme => ({
    button: {
        fontSize: '1em',
        height: theme.spacing(4.5),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        },
    },
}));

const CloseActions: React.FunctionComponent<PropShape> = ({ onClick, productId }) => {
    const classes = useStyles();
    return (
        <ButtonComponent
            id={`close-details-${productId}`}
            onClick={onClick}
            variant="outlined"
            color="primary"
            className={classes.button}
        >
            Close
        </ButtonComponent>
    );
};

interface PropShape {
    onClick: () => void;
    productId: string;
}

export default CloseActions;
