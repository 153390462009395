import { createTheme, Theme } from '@material-ui/core';

import { createThemeOptions } from './create-theme-options';
import { getDefaultTheme } from './default-theme';

const createCustomTheme = (css: string, isBasic: boolean): Theme => {
    if (isBasic) {
        const muiTheme = createTheme({
            ...getDefaultTheme(),
        });

        return muiTheme;
    }
    // create account theme override options
    const accountOptions = createThemeOptions(css);

    // merge the default theme with account override options
    const muiTheme = createTheme({
        ...getDefaultTheme(),
        ...accountOptions,
    });

    return muiTheme;
};

export default createCustomTheme;
