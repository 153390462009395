import DemoFormData from 'static/models/demo/demoFormData';
import ErrorFormData from 'static/models/errorLogging/errorFormData';
import { LeadContact } from 'static/models/LeadContact';
import { PickupLocation } from 'static/models/PickupLocation';
import validator from 'validator';

import { FormErrors } from './FormTypes';

export const pickupLocationValidator = (pickupLocation: PickupLocation): FormErrors<PickupLocation> => {
    const formErrors: FormErrors<PickupLocation> = {};

    if (pickupLocation.isEnteredManually) {
        if (isStringNullOrEmpty(pickupLocation.pickupLocationStreet)) {
            formErrors.pickupLocationStreet = 'Street address is required';
        }

        if (isStringNullOrEmpty(pickupLocation.pickupLocationCity)) {
            formErrors.pickupLocationCity = 'City is required';
        }

        if (isStringNullOrEmpty(pickupLocation.pickupLocationState)) {
            formErrors.pickupLocationState = 'State is required';
        }

        if (!stringHasLength(pickupLocation.pickupLocationZip, 5, 5)) {
            formErrors.pickupLocationZip = 'Zip is required';
        }
    }

    // no keys == no errors
    if (Object.keys(formErrors).length === 0) {
        return null;
    }

    return formErrors;
};

export const errorFormDataValidator = (errorFormData: ErrorFormData): FormErrors<ErrorFormData> => {
    const formErrors: FormErrors<ErrorFormData> = {};

    if (isStringNullOrEmpty(errorFormData.name)) {
        formErrors.name = 'Name is required';
    }

    if (!stringHasLength(errorFormData.phone, 10, 10)) {
        formErrors.phone = 'Phone number is required';
    }

    if (isStringNullOrEmpty(errorFormData.email)) {
        formErrors.email = 'Email is required';
    }

    if (isStringNullOrEmpty(errorFormData.subject)) {
        formErrors.subject = 'Subject is required';
    }

    if (isStringNullOrEmpty(errorFormData.details)) {
        formErrors.details = 'Details is required';
    }

    // no keys == no errors
    if (Object.keys(formErrors).length === 0) {
        return null;
    }

    return formErrors;
};

export const demoAppFormDataValidator = (demoFormData: DemoFormData): FormErrors<DemoFormData> => {
    const formErrors: FormErrors<DemoFormData> = {};

    if (isStringNullOrEmpty(demoFormData.firstName)) {
        formErrors.firstName = 'First name is required';
    }

    if (isStringNullOrEmpty(demoFormData.lastName)) {
        formErrors.lastName = 'Last name is required';
    }

    if (isStringNullOrEmpty(demoFormData.phone) || !validator.isMobilePhone(demoFormData.phone, 'any')) {
        formErrors.phone = 'Phone number is required';
    }

    if (isStringNullOrEmpty(demoFormData.email)) {
        formErrors.email = 'Email is required';
    }

    // no keys == no errors
    if (Object.keys(formErrors).length === 0) {
        return null;
    }

    return formErrors;
};

export const isStringNullOrEmpty = (value: string): boolean => {
    if (value) {
        return false;
    }

    return true;
};

export const stringHasLength = (value: string, minimumLength: number, maximumLength: number): boolean => {
    if (!value) {
        return false;
    }

    if (value.length < minimumLength || value.length > maximumLength) {
        return false;
    }

    return true;
};

export const leadContactValidator = (leadContact: LeadContact): FormErrors<LeadContact> => {
    const formErrors: FormErrors<LeadContact> = {};

    if (isStringNullOrEmpty(leadContact.firstName)) {
        formErrors.firstName = 'First name is required';
    }

    if (isStringNullOrEmpty(leadContact.lastName)) {
        formErrors.lastName = 'Last name is required';
    }

    if (isStringNullOrEmpty(leadContact.phone) || !validator.isMobilePhone(leadContact.phone, 'en-US')) {
        formErrors.phone = 'Phone number is required';
    }

    if (isStringNullOrEmpty(leadContact.email)) {
        formErrors.email = 'Email is required';
    }

    // no keys == no errors
    if (Object.keys(formErrors).length === 0) {
        return null;
    }

    return formErrors;
};
