import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Account, { defaultAccount } from '../../static/models/Account';

const initialState: Account = { ...defaultAccount };

export { initialState as initialAccount };

const accountSlice = createSlice({
    name: 'account',
    initialState,
    reducers: {
        setAccount(_, action: PayloadAction<Account>) {
            return action.payload;
        },
    },
});

export const { setAccount } = accountSlice.actions;
export default accountSlice.reducer;
