import React from 'react';

import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { defaultDialogOnCloseEventHandler } from 'toolboxes/helpers/DialogHelpers';

import ButtonComponent from '../button-components/button-component/ButtonComponent';

const ConfirmationModalComponent: React.FunctionComponent<PropShape> = props => {
    return (
        <Dialog
            onClose={defaultDialogOnCloseEventHandler}
            maxWidth="xs"
            aria-labelledby={`confirmation-dialog-${props.id}`}
            open={props.open}
        >
            <DialogTitle id="confirmation-dialog-title">{props.title}</DialogTitle>
            <DialogContent dividers>{props.children}</DialogContent>
            <DialogActions>
                <ButtonComponent
                    id={`${props.id}-cancel`}
                    title={`${props.title} Cancel`}
                    onClick={props.onCancel}
                    size="small"
                >
                    {props.cancelText}
                </ButtonComponent>
                <ButtonComponent
                    id={`${props.id}-accept`}
                    title={`${props.title} Accept`}
                    onClick={props.onAccept}
                    size="small"
                >
                    {props.acceptText}
                </ButtonComponent>
            </DialogActions>
        </Dialog>
    );
};

interface PropShape {
    // Shape of passed in props
    id: string;
    title: string;
    open: boolean;
    cancelText: string;
    acceptText: string;
    onCancel?: React.MouseEventHandler<HTMLButtonElement>;
    onAccept?: React.MouseEventHandler<HTMLButtonElement>;
}

ConfirmationModalComponent.defaultProps = {
    // Set default values for props
    onCancel: () => {},
    onAccept: () => {},
};

export default ConfirmationModalComponent;
