import React, { useState } from 'react';

import { makeStyles, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useLocationAutocomplete } from 'hooks/useLocationAutocomplete';
import Account from 'static/models/Account';
import { AutocompletedLocation } from 'static/models/LocationAutocomplete';

const useStyles = makeStyles({
    option: {
        fontWeight: 'normal',
    },
});

const LocationSearchComponent: React.FunctionComponent<PropShape> = ({
    onLocationChange,
    searchOrigin,
    onClear,
    value,
}) => {
    const classes = useStyles();
    const [searchQuery, setSearchQuery] = useState(value);
    const { locations, isLoading } = useLocationAutocomplete(searchQuery, searchOrigin);

    const notListedOption: AutocompletedLocation = {
        description: 'The address is not listed',
        place_id: null,
    };

    const getDefaultValue = (): AutocompletedLocation => {
        if (value) {
            return {
                description: value,
                place_id: null,
            };
        }

        return null;
    };

    const renderInput = params => (
        <TextField
            {...params}
            label="Search a location"
            margin="normal"
            variant="outlined"
            onChange={e => {
                setSearchQuery(e.target.value);
            }}
            value={searchQuery}
            InputProps={{
                ...params.InputProps,
                type: 'search',
            }}
        />
    );

    return (
        <Autocomplete
            freeSolo
            disableClearable
            loading={isLoading}
            loadingText={<Typography variant="body2">Loading...</Typography>}
            options={[...locations, notListedOption]}
            getOptionLabel={loc => loc.description}
            classes={{ option: classes.option }}
            onChange={(_, newValue: null | AutocompletedLocation) => {
                onLocationChange(newValue);
            }}
            filterOptions={values => values}
            onInputChange={(_, val) => onClear(!val)}
            renderInput={renderInput}
            value={getDefaultValue()}
            aria-label={'location search'}
        />
    );
};

interface PropShape {
    searchOrigin?: Account;
    onLocationChange: (newLocation: AutocompletedLocation | null) => void;
    onClear?: (cleared: boolean) => void;
    value?: string;
}

LocationSearchComponent.defaultProps = {
    // Set default values for props
    onClear: () => {},
    value: '',
};

export default LocationSearchComponent;
