import { setOpportunity } from 'redux/opportunity/root';
import { setPickupLocation } from 'redux/pickupLocation/root';
import { setProductQuestionResponses } from 'redux/product-package-questions/productQuestionResponses/root';
import { RootState } from 'redux/root';
import { setSelectedProducts } from 'redux/selectedProducts/root';
import urlPaths from 'static/constants/enums/urlPaths';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import { PickupLocation } from 'static/models/PickupLocation';
import { RoutingParams } from 'static/models/RoutingParams';
import {
    getOpportunityIdLocalStorage,
    setOpportunityIdLocalStroage,
    checkIfExistsInLocalStorage,
    clearOpportunityIdLocalStorage,
} from 'toolboxes/reuseable-logic/local-storage-getters-setters';
import { mapLoadedOpportunityToOpportunity } from 'toolboxes/reuseable-logic/map-opportunity';
import { loadOpportunity } from 'toolboxes/server-application-buffer/opportunity-data';

import { setAvailableProductQuestions } from '../../../redux/product-package-questions/availableProductQuestions/root';

export default function LoadOpportunityFunctions(
    state: RootState,
    dispatch: Function,
    params: RoutingParams,
): BlockingFunction[] {
    const functionsToRun: BlockingFunction[] = [];

    let opportunityId = null;
    if (params && 'id' in params) {
        opportunityId = params.id;
    } else {
        opportunityId = getOpportunityIdLocalStorage(state);
    }

    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>(async (resolve, reject) => {
                try {
                    const loadedOpportunity = await loadOpportunity(opportunityId);
                    const opportunity = mapLoadedOpportunityToOpportunity(loadedOpportunity);
                    // If opportunity was passed in via param there wouldn't be a local storage of it yet.
                    // Did it here so we would have the opportunity's close date
                    if (!checkIfExistsInLocalStorage('opportunityKey')) {
                        setOpportunityIdLocalStroage(opportunityId, opportunity.closeDate);
                    } else if (params?.id === opportunityId && opportunityId !== getOpportunityIdLocalStorage(state)) {
                        // If there was already a different local storage opportunity,
                        // but an opportunity was passed in via params, override the local storage opportunity
                        await clearOpportunityIdLocalStorage();
                        setOpportunityIdLocalStroage(opportunityId, opportunity.closeDate);
                    }

                    const pickupLocation: PickupLocation = {
                        pickupLocationStreet: loadedOpportunity.pickupLocationStreet,
                        pickupLocationCity: loadedOpportunity.pickupLocationCity,
                        pickupLocationCounty: loadedOpportunity.pickupLocationCounty,
                        pickupLocationState: loadedOpportunity.pickupLocationState,
                        pickupLocationZip: loadedOpportunity.pickupLocationZip,
                        pickupLocationDescription: loadedOpportunity.pickupLocationDescription,
                        mileageDistance: Number(loadedOpportunity.mileageDistance),
                        mileageCost: Number(loadedOpportunity.mileageCost),
                        isWithinServiceRadius: false,
                        distanceBeyondServiceArea:
                            Math.round(loadedOpportunity.mileageDistance) - state.account.serviceRadius,
                    };

                    dispatch(setOpportunity(opportunity));
                    dispatch(setPickupLocation(pickupLocation));
                    dispatch(setSelectedProducts(loadedOpportunity.selectedProducts));
                    dispatch(setProductQuestionResponses(loadedOpportunity.productQuestionResponses));
                    dispatch(setAvailableProductQuestions(loadedOpportunity.productQuestions));

                    return resolve();
                } catch {
                    return reject();
                }
            }),
        blocksPageStarts: [urlPaths.loadOpportunity],
    });

    return functionsToRun;
}
