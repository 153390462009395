import React from 'react';

import { CardContent, Typography } from '@material-ui/core';
import CardComponent from 'components/global-components/layout-components/ui-components/card-component/CardComponent';
import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import { useMembership } from 'hooks/useMembership';

const MembershipVerificationCompletePageComponent: React.FunctionComponent = () => {
    const { membershipVerificationContact } = useMembership();

    return (
        <PageContentWrapperComponent
            title={'Membership'}
            subtitle={
                'We require a membership to purchase our services online. Please answer the following questions prior to starting an online funeral arrangement.'
            }
        >
            <CardComponent>
                <CardContent>
                    <Typography variant="h3">Membership Sign-in Complete</Typography>
                    <Typography variant="subtitle2">
                        You're ready to start planning {membershipVerificationContact?.firstName}{' '}
                        {membershipVerificationContact?.lastName}'s arrangement.
                    </Typography>
                </CardContent>
                <CardContent>
                    <Typography variant="subtitle2">Click continue below to begin.</Typography>
                </CardContent>
            </CardComponent>
        </PageContentWrapperComponent>
    );
};

export default MembershipVerificationCompletePageComponent;
