import React, { useState } from 'react';

import { Card, CardContent, Grid, IconButton, makeStyles, Theme, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import PopupComponent from 'components/form-components/popup-component/PopupComponent';
import { useSelectedProducts } from 'hooks/product-package-questions/useSelectedProducts';
import { useAvailableTravelPackages } from 'hooks/travel/useAvailableTravelPackages';
import { useSelectedTravelPackage } from 'hooks/travel/useSelectedTravelPackage';
// import { useOpportunity } from 'hooks/useOpportunity';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { useLocation } from 'react-router';
import urlPaths from 'static/constants/enums/urlPaths';
import {
    getFormattedFuneralSubTotalPrice,
    getFormattedFuneralTaxTotalPrice,
    getFormattedFuneralTotalWithTaxIncluded,
    getFuneralTaxTotalPrice,
} from 'toolboxes/reuseable-logic/products/productFunctions';
import { formatCurrency } from 'toolboxes/reuseable-logic/string-formatters';
// import { transitionToPurchaserFlowPassedInfo } from 'toolboxes/reuseable-logic/transition-to-purchaser-flow';

// import { useIsDemoApp } from '../../../hooks/useIsDemoApp';
import { usePickupLocation } from '../../../hooks/usePickupLocation';
// import ButtonLinkComponent from '../layout-components/button-components/button-link-component/ButtonLinkComponent';
import MockLinkComponent from '../layout-components/mock-link-component/MockLinkComponent';
import ReceiptComponent from '../receipt-component/ReceiptComponent';
import TaxedItemsPopupContentComponent from './TaxedItemsPopupContentComponent';

type StyleProps = {
    widthSmallAndUp: number | string;
    orderSummaryComponent: boolean;
};

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
    width: {
        [theme.breakpoints.up('sm')]: {
            width: ({ widthSmallAndUp }) => widthSmallAndUp,
        },
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            minWidth: 250,
        },
    },
    cartInnerLine: {
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.primary.main,
        borderBottomStyle: 'solid',
    },
    cartInnerSection: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    cartCard: {
        borderTopWidth: 22,
        borderTopColor: ({ orderSummaryComponent }) =>
            orderSummaryComponent ? theme.palette.primary.main : theme.custom.footerFontColor,
        borderTopStyle: 'solid',
        alignItems: 'center',
    },
    cartCardLeft: {
        textAlign: 'left',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
    },
    cartCardRight: {
        textAlign: 'end',
        justifyContent: 'flex-end',
    },
    textUppercase: {
        textTransform: 'uppercase',
    },
    textBold: {
        fontWeight: 'bold',
    },
    textAlignCenter: {
        textAlign: 'center',
        fontWeight: 'normal',
        paddingTop: 0,
    },
    paddingBottom: {
        paddingBottom: 10,
    },
    paddingTop: {
        paddingTop: 20,
    },
    paddingLeft: {
        paddingLeft: 5,
    },
    bold: {
        fontWeight: 'bold',
    },
    alignRight: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    showMoreLink: {
        paddingTop: 10,
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        justifyContent: 'center',
    },
    subtext: {
        fontSize: '0.875rem',
        fontStyle: 'italic',
    },
    taxedItemsFontSize: {
        fontSize: '0.75rem',
    },
    button: {
        backgroundColor: theme.palette.primary.main,
        width: '100%',
        fontSize: '1rem',
    },
}));

const CartComponent: React.FunctionComponent<PropShape> = ({
    shouldShowCloseIcon,
    shouldShowMore,
    orderSummaryComponent,
    onCloseIconFunction,
}) => {
    const widthSmallAndUp = orderSummaryComponent ? '80%' : 320;
    const classes = useStyles({ widthSmallAndUp, orderSummaryComponent });

    const { selectedProducts } = useSelectedProducts();
    const { selectedAccount } = useSelectedAccount();
    const { pickupLocation } = usePickupLocation();
    const availableTravelPackages = useAvailableTravelPackages();
    const { selectedTravelPackage } = useSelectedTravelPackage();
    const [isCollapsed, setIsCollapsed] = useState<Boolean>(true);
    // const { opportunity } = useOpportunity();
    // const { isDemoApp } = useIsDemoApp();

    const taxTotal = getFuneralTaxTotalPrice(selectedProducts, selectedAccount.salesTax);

    const location = useLocation();
    const isOrderSummaryPage = location.pathname === urlPaths.orderSummary;

    return (
        <div className={classes.width}>
            <Grid container direction="column">
                <Card className={classes.cartCard}>
                    {shouldShowCloseIcon && (
                        <span className={classes.alignRight}>
                            <IconButton
                                id="close-icon-cart"
                                disableRipple
                                component="span"
                                onClick={onCloseIconFunction}
                            >
                                <CloseIcon />
                            </IconButton>
                        </span>
                    )}
                    <CardContent className={classes.textAlignCenter}>
                        <Typography id="cart-order-summary-header" variant="body2" className={classes.textUppercase}>
                            Order Summary
                        </Typography>
                        <Typography
                            id="cart-order-summary-subtext"
                            variant="body2"
                            className={`${classes.subtext} ${classes.paddingBottom}`}
                        >
                            Prices are estimated
                        </Typography>
                        <div className={classes.cartInnerLine}>
                            <div className={classes.cartInnerSection}>
                                <div className={classes.cartCardLeft}>
                                    <Typography id="cart-subtotal-text" variant="body2">
                                        Subtotal
                                    </Typography>
                                </div>
                                <div className={classes.cartCardRight}>
                                    <Typography id="cart-subtotal" variant="body2">
                                        {getFormattedFuneralSubTotalPrice(
                                            selectedProducts.filter(x => x.category !== 'Travel Protection'),
                                            pickupLocation,
                                            selectedAccount.mileageCharge,
                                        )}
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.cartInnerSection}>
                                <div className={classes.cartCardLeft}>
                                    <Typography
                                        id="cart-sales-tax-text"
                                        variant="body2"
                                        className={classes.cartCardLeft}
                                    >
                                        Sales Tax{' '}
                                        {taxTotal > 0 && (
                                            <span>
                                                <PopupComponent
                                                    button={
                                                        <IconButton
                                                            id="taxed-items-icon"
                                                            color="primary"
                                                            disableRipple
                                                            component="span"
                                                        >
                                                            <InfoIcon />
                                                        </IconButton>
                                                    }
                                                    popupId="taxed-items-popup"
                                                    anchorOriginVertical="center"
                                                    anchorOriginHorizontal="right"
                                                    transformOriginVertical="top"
                                                    transformOriginHorizontal="left"
                                                >
                                                    <div id="taxed-items-popup-text" className={classes.paddingLeft}>
                                                        <TaxedItemsPopupContentComponent />
                                                    </div>
                                                </PopupComponent>
                                            </span>
                                        )}
                                    </Typography>
                                </div>
                                <div className={classes.cartCardRight}>
                                    <Typography id="cart-tax-total" variant="body2">
                                        {getFormattedFuneralTaxTotalPrice(selectedProducts, selectedAccount.salesTax)}
                                    </Typography>
                                </div>
                            </div>
                        </div>
                        {(orderSummaryComponent || selectedTravelPackage.id) &&
                            availableTravelPackages?.length > 0 &&
                            availableTravelPackages[0]?.id && (
                                <div className={classes.cartInnerLine}>
                                    <div className={classes.cartInnerSection}>
                                        <div className={classes.cartCardLeft}>
                                            <Typography id="cart-travel-protection-name" variant="body2">
                                                {selectedTravelPackage.id
                                                    ? selectedTravelPackage.name
                                                    : availableTravelPackages[0].name}
                                            </Typography>
                                        </div>
                                        <div className={classes.cartCardRight}>
                                            <Typography id="cart-travel-protection-price" variant="body2">
                                                {selectedTravelPackage.id
                                                    ? formatCurrency(selectedTravelPackage.price)
                                                    : 'NONE'}
                                            </Typography>
                                        </div>
                                    </div>
                                </div>
                            )}
                        <Typography
                            id="cart-total-header"
                            variant="body2"
                            className={`${classes.textUppercase} ${classes.textBold} ${classes.paddingTop} ${classes.paddingBottom}`}
                        >
                            Total Estimate
                        </Typography>
                        <Typography id="cart-total-price" variant="body2" className={classes.bold}>
                            {getFormattedFuneralTotalWithTaxIncluded(
                                selectedProducts,
                                selectedAccount.salesTax,
                                selectedTravelPackage.price,
                                pickupLocation,
                                selectedAccount.mileageCharge,
                            )}
                        </Typography>
                        {!isOrderSummaryPage && shouldShowMore && (
                            <MockLinkComponent
                                id={`cart-show-more-less`}
                                onClick={() => setIsCollapsed(!isCollapsed)}
                                className={classes.showMoreLink}
                            >
                                {isCollapsed ? (
                                    <>
                                        show more details <ExpandMoreIcon />
                                    </>
                                ) : (
                                    <>
                                        show less details <ExpandLessIcon />
                                    </>
                                )}
                            </MockLinkComponent>
                        )}
                        {!isCollapsed && <ReceiptComponent />}
                        {/* {isOrderSummaryPage && !orderSummaryComponent && (
                            <div className={classes.paddingTop}>
                                <ButtonLinkComponent
                                    id="continue"
                                    to={''}
                                    disabled={!isOrderSummaryPage}
                                    className={classes.button}
                                    onClick={() =>
                                        transitionToPurchaserFlowPassedInfo(
                                            selectedAccount.subdomain,
                                            opportunity.opportunityId,
                                            isDemoApp,
                                        )
                                    }
                                >
                                    Complete Payment
                                </ButtonLinkComponent>
                            </div>
                        )} */}
                    </CardContent>
                </Card>
            </Grid>
        </div>
    );
};

interface PropShape {
    shouldShowMore?: boolean;
    orderSummaryComponent?: boolean;
    shouldShowCloseIcon?: boolean;
    onCloseIconFunction?: () => void;
}

CartComponent.defaultProps = {
    shouldShowMore: false,
    orderSummaryComponent: false,
    shouldShowCloseIcon: false,
    onCloseIconFunction: () => {},
};

export default CartComponent;
