import React from 'react';

import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import PickupLocationSelectionComponent from 'components/global-components/pickup-location-selection-component/PickupLocationSelectionComponent';
import { useSelectedAccount } from 'hooks/useSelectedAccount';

/*
On page exit
    Save pickup location information to opportunity
On page reroute
    If timing !== Immediate, go to Travel Protection
*/
const PickupLocationPageComponent: React.FunctionComponent<PropShape> = () => {
    const { selectedAccount } = useSelectedAccount();
    return (
        <PageContentWrapperComponent
            title="Where is your loved one now?"
            subtitle={
                <>
                    We need to know where your loved one is located so the staff at {selectedAccount.name} can transfer
                    them into their care.
                </>
            }
        >
            <PickupLocationSelectionComponent />
        </PageContentWrapperComponent>
    );
};
interface PropShape {
    // Shape of passed in props
}
PickupLocationPageComponent.defaultProps = {
    // Set default values for props
};
export default PickupLocationPageComponent;
