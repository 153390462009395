import { disclaimerText } from 'components/page-components/package-page-component/PriceListDisclaimerText';
import { SubmitDisclaimerRequest } from 'static/models/SubmitDisclaimerRequest';
import { submitDisclaimerRequest } from 'toolboxes/web-requests/specific-requests/disclaimer-requests';

export async function acceptPriceListDisclaimer(opportunityId: string): Promise<string> {
    const data: SubmitDisclaimerRequest = {
        accepted: 'Yes',
        contactId: '',
        opportunityId,
        text: disclaimerText,
        topic: 'Price List',
        userDecision: 'I understand',
    };
    return await submitDisclaimerRequest(data);
}

export async function acceptCPRADisclosure(opportunityId: string): Promise<string> {
    const data: SubmitDisclaimerRequest = {
        accepted: 'Yes',
        contactId: '',
        opportunityId,
        text: disclaimerText,
        topic: 'CPRA Disclosure',
        userDecision: 'I understand',
    };
    return await submitDisclaimerRequest(data);
}
