import { MutableRefObject, useEffect, useRef, useState } from 'react';

interface ReturnType {
    ref: MutableRefObject<HTMLElement>;
    hasOverflow: boolean;
}

export function useContentOverflow(): ReturnType {
    const [hasOverflow, setHasOverflow] = useState(false);
    const ref = useRef<HTMLElement>(null);
    useEffect(() => {
        setHasOverflow(ref.current.scrollHeight > ref.current.clientHeight);
    }, [ref]);
    return {
        ref,
        hasOverflow,
    };
}
