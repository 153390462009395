import { setErrorCaseCreationFailed, setErrorCaseId, setErrorCaseNumber } from 'redux/errorLogging/root';
import { RootState } from 'redux/root';
import urlPaths from 'static/constants/enums/urlPaths';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import { sendErrorDataToCreateErrorCase } from 'toolboxes/server-application-buffer/opportunity-data';

export default function ErrorEnterFunctions(state: RootState, dispatch: Function): BlockingFunction[] {
    const functionsToRun: BlockingFunction[] = [];

    functionsToRun.push({
        promiseGenerator: async () => {
            const result = await sendErrorDataToCreateErrorCase(state);
            if (result.caseId === `couldn't make the error case`) {
                dispatch(setErrorCaseCreationFailed(true));
                return;
            } else if (result.caseId) {
                dispatch(setErrorCaseId(result.caseId));
                dispatch(setErrorCaseNumber(result.caseNumber));
                return;
            } else {
                dispatch(setErrorCaseCreationFailed(true));
                return;
            }
        },
        blocksPageStarts: [urlPaths.error],
    });

    return functionsToRun;
}
