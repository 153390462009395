import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductQuestion } from 'static/models/ProductQuestions';

const initialState: ProductQuestion[] = [];

export { initialState as initialAvailableProductQuestions };

const availableProductQuestionsSlice = createSlice({
    name: 'availableProductQuestions',
    initialState,
    reducers: {
        setAvailableProductQuestions(state, action: PayloadAction<ProductQuestion[]>) {
            return action.payload;
        },
    },
});

export const { setAvailableProductQuestions } = availableProductQuestionsSlice.actions;
export default availableProductQuestionsSlice.reducer;
