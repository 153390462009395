import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Product from 'static/models/Product';

const initialState: Product = {
    id: '',
    name: '',
    price: 0,
    lineItemTotal: 0,
    category: '',
    subCategory: '',
    tag: '',
    imageURL: '',
    quantity: 0,
    casketMaterial: '',
    manufacturer: '',
    modelNumber: '',
    salesTaxApplicable: false,
    salesDescription: '',
    productCategory: '',
    selected: false,
    packageProduct: false,
    status: '',
    isHiddenFromConsumer: false,
    isDispositionApplicable: false,
    isCeremonyApplicable: false,
    isVisitationApplicable: false,
    isImmediateBurialPackage: false,
    isDirectCremationPackage: false,
    dispositionTypes: [],
    timingTypes: [],
    ceremonyTypes: '',
    visitationTypes: '',
    questionId: '',
    included: false,
    includedInPackage: false,
};

export { initialState as initialProduct };

const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        setProduct(state, action: PayloadAction<Product>) {
            return action.payload;
        },
    },
});

export const { setProduct } = productSlice.actions;
export default productSlice.reducer;
