import React, { Fragment } from 'react';

import { Popover } from '@material-ui/core';

const PopupComponent: React.FunctionComponent<PropShape> = props => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    if (props.closed && anchorEl) {
        setAnchorEl(null);
    }

    return (
        <Fragment>
            <span onClick={handleClick}>{props.button}</span>
            <Popover
                id={props.popupId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: props.anchorOriginVertical,
                    horizontal: props.anchorOriginHorizontal,
                }}
                transformOrigin={{
                    vertical: props.transformOriginVertical,
                    horizontal: props.transformOriginHorizontal,
                }}
                elevation={6}
            >
                {props.children}
            </Popover>
        </Fragment>
    );
};

interface PropShape {
    button: JSX.Element;
    popupId: string;
    anchorOriginVertical?: number | 'top' | 'center' | 'bottom';
    anchorOriginHorizontal?: number | 'center' | 'left' | 'right';
    transformOriginVertical?: number | 'top' | 'center' | 'bottom';
    transformOriginHorizontal?: number | 'center' | 'left' | 'right';
    closed?: boolean;
}

PopupComponent.defaultProps = {
    button: undefined,
    popupId: 'popup',
    anchorOriginVertical: 'bottom',
    anchorOriginHorizontal: 'center',
    transformOriginVertical: 'top',
    transformOriginHorizontal: 'center',
    closed: false,
};

export default PopupComponent;
