import React from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import AddOutlinedIcon from '@material-ui/icons/AddOutlined';
import RemoveOutlinedIcon from '@material-ui/icons/RemoveOutlined';
import ButtonComponent from 'components/global-components/layout-components/button-components/button-component/ButtonComponent';

const useStyles = makeStyles(theme => ({
    quantityButton: {
        minWidth: 0,
        width: theme.spacing(4.5),
        height: theme.spacing(4.5),
    },
    quantityContainer: {
        textAlign: 'center',
    },
    icon: {
        fontSize: '1em',
        lineHeight: 1.5,
    },
    quantityControlsContainer: {
        alignItems: 'center',
        justifyContent: 'space-between',
    },
}));

const QuantityActions: React.FunctionComponent<PropShape> = ({ onDecrement, onIncrement, productId, quantity }) => {
    const classes = useStyles();
    return (
        <Grid container direction="row" spacing={1} className={classes.quantityControlsContainer}>
            <Grid item>
                <ButtonComponent
                    id={`decrease-quantity-${productId}`}
                    title="decrease"
                    className={classes.quantityButton}
                    color="primary"
                    variant="outlined"
                    onClick={onDecrement}
                >
                    <RemoveOutlinedIcon className={classes.icon} />
                </ButtonComponent>
            </Grid>
            <Grid item>
                <div className={classes.quantityContainer}>{quantity}</div>
            </Grid>
            <Grid item>
                <ButtonComponent
                    id={`increase-quantity-${productId}`}
                    title="increase"
                    className={classes.quantityButton}
                    color="primary"
                    variant="outlined"
                    onClick={onIncrement}
                >
                    <AddOutlinedIcon className={classes.icon} />
                </ButtonComponent>
            </Grid>
        </Grid>
    );
};

interface PropShape {
    onDecrement: () => void;
    onIncrement: () => void;
    productId: string;
    quantity: number;
}

export default QuantityActions;
