import React, { Fragment } from 'react';

import { Typography } from '@material-ui/core';
import ExternalLinkComponent from 'components/global-components/external-link-component/ExternalLinkComponent';
import Account from 'static/models/Account';
import { GeographicState } from 'static/models/enums/states';

import PriceListLinkComponent from '../price-list-link-component/PriceListLinkComponent';

const FooterLinks: React.FunctionComponent<PropShape> = ({ account, textStyle }) => {
    return (
        <Fragment>
            <Typography variant="body2" className={textStyle}>
                <PriceListLinkComponent className={textStyle}>General Price List (GPL)</PriceListLinkComponent>
                <span> | </span>
                <ExternalLinkComponent
                    id="terms-of-use"
                    href="https://www.efuneral.com/terms-of-use"
                    className={textStyle}
                >
                    Terms of Use
                </ExternalLinkComponent>
                <span> | </span>
                <ExternalLinkComponent
                    id="privacy-policy"
                    href="https://www.efuneral.com/privacy"
                    className={textStyle}
                >
                    Privacy Policy
                </ExternalLinkComponent>
            </Typography>
            {account.state === GeographicState.TX && (
                <Typography variant="body2" className={textStyle}>
                    <span>Texas consumers: Visit </span>
                    <ExternalLinkComponent
                        id="texas-prepaid-funeral"
                        href="https://www.prepaidfunerals.texas.gov"
                        className={textStyle}
                    >
                        www.prepaidfunerals.texas.gov
                    </ExternalLinkComponent>
                    <span> for more information.</span>
                </Typography>
            )}
        </Fragment>
    );
};
interface PropShape {
    account: Account;
    textStyle: string;
}

export default FooterLinks;
