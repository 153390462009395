import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Product from 'static/models/Product';

const initialState: Product[] = [];

export { initialState as initialAvailableProducts };

const availableProductsSlice = createSlice({
    name: 'availableProducts',
    initialState,
    reducers: {
        setAvailableProducts(state, action: PayloadAction<Product[]>) {
            return action.payload;
        },
    },
});

export const { setAvailableProducts } = availableProductsSlice.actions;
export default availableProductsSlice.reducer;
