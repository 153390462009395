import LoadedOpportunity from 'static/models/LoadedOpportunity';
import Opportunity from 'static/models/Opportunity';

import { parseCustomerPath } from './customer-path';

export function mapLoadedOpportunityToOpportunity(loadedOpp: LoadedOpportunity): Opportunity {
    return {
        opportunityId: loadedOpp.opportunityId,
        stageName: loadedOpp.stageName,
        closeDate: loadedOpp.closeDate,
        closedToUser: loadedOpp.closedToUser,
        returnToReact: loadedOpp.returnToReact,
        timing: loadedOpp.timing,
        disposition: loadedOpp.disposition,
        ceremony: loadedOpp.ceremony,
        visitation: loadedOpp.visitation,
        preneedContractType: loadedOpp.preneedContractType,
        stopPage: loadedOpp.stopPage,
        stopPageDetail: loadedOpp.stopPageDetail,
        productSelectionLocked: loadedOpp.productSelectionLocked,
        packageId: loadedOpp.packageId,
        parsedCustomerPath: parseCustomerPath(loadedOpp.customerPath),
        hidePriceListDisclaimer: loadedOpp.hidePriceListDisclaimer,
        customerPathObject: loadedOpp.customerPathObject,
    };
}
