import React, { useState, useEffect } from 'react';

import { Theme, ThemeProvider } from '@material-ui/core';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { EfuneralServiceTier } from 'static/constants/enums/efuneralServiceTier';
import createCustomTheme from 'theme/create-custom-theme';
import { getAccountCssBySubdomain } from 'toolboxes/server-application-buffer/account-data';

const AccountThemeProvider: React.FunctionComponent = ({ children }) => {
    const {
        selectedAccount: { subdomain, efuneralServiceTier },
    } = useSelectedAccount();
    const isBasic = efuneralServiceTier === EfuneralServiceTier.BASIC;
    const [theme, setTheme] = useState<Theme>(createCustomTheme(null, isBasic));

    useEffect(() => {
        if (subdomain) {
            const getAccountCss = async () => {
                try {
                    const response = await getAccountCssBySubdomain(subdomain);
                    const customTheme = createCustomTheme(response, isBasic);
                    setTheme(customTheme);
                } catch {
                    const customTheme = createCustomTheme(null, isBasic);
                    setTheme(customTheme);
                }
            };
            getAccountCss();
        } else {
            const customTheme = createCustomTheme(null, isBasic);
            setTheme(customTheme);
        }
    }, [isBasic, subdomain]);

    return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default AccountThemeProvider;
