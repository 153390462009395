import React from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import { useOpportunity } from 'hooks/useOpportunity';
import { useRouteCalculator } from 'hooks/useRouteCalculator/useRouteCalculator';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { useLocation } from 'react-router';
import urlPaths from 'static/constants/enums/urlPaths';
import { Timing } from 'static/models/enums/timing';
import { transitionToPurchaserFlowPassedInfo } from 'toolboxes/reuseable-logic/transition-to-purchaser-flow';

import getEnvironment from '../../../env-config/get-environment';
import { useIsDemoApp } from '../../../hooks/useIsDemoApp';
import FailingRequestButtonComponent from '../failing-request-button-component/FailingRequestButtonComponent';
import ButtonLinkComponent from '../layout-components/button-components/button-link-component/ButtonLinkComponent';
import ResetSessionButtonComponent from '../reset-session-button/ResetSessionButton';

const useStyles = makeStyles(theme => ({
    stickyFooter: {
        position: 'sticky',
        bottom: 0,
        backgroundColor: 'white',
        boxShadow: '0px -3px 3px #EEE',
        padding: theme.spacing(1),
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
        },
    },
    continueButtonContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
    },
    continueButton: {
        width: 267,
        color: '#fff',
        marginRight: theme.spacing(9),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2),
        },
    },
    resetSessionButtonContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    resetSessionButton: {
        textAlign: 'center',
        marginLeft: theme.spacing(9),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: theme.spacing(2),
            marginLeft: theme.spacing(2),
        },
    },
}));

const ContinueBarComponent: React.FunctionComponent = () => {
    const classes = useStyles();
    const { nextPage } = useRouteCalculator();
    const { selectedAccount } = useSelectedAccount();
    const { opportunity } = useOpportunity();
    const { isDemoApp } = useIsDemoApp();
    const environment = getEnvironment();

    const location = useLocation();
    const isOrderSummaryPage = location.pathname === urlPaths.orderSummary;
    const isReturningOpportunityPage = location.pathname === urlPaths.returningOpportunity;
    const isMembershipQuestionPage = location.pathname === urlPaths.membershipQuestion;
    const isTimingUnavailable =
        (opportunity.timing === Timing.IMMINENT && !selectedAccount.feature_ImminentSales) ||
        (opportunity.timing === Timing.FUTURE && !selectedAccount.feature_FutureSales);

    const handleClick = () => {
        isOrderSummaryPage
            ? transitionToPurchaserFlowPassedInfo(selectedAccount.subdomain, opportunity.opportunityId, isDemoApp)
            : null;
    };

    if (isReturningOpportunityPage || isMembershipQuestionPage) {
        return null;
    }

    const showResetSessionButton = !environment.productionEnvironment;
    const showFailingRequestButton = !(environment.productionEnvironment || environment.demoEnvironment);
    const showContinueButton = !(isTimingUnavailable && isOrderSummaryPage);

    return (
        <Grid container className={classes.stickyFooter}>
            <Grid item sm={12} md={6} className={classes.resetSessionButtonContainer}>
                {showResetSessionButton && (
                    <ResetSessionButtonComponent id="reset-session" className={classes.resetSessionButton} />
                )}
                {showFailingRequestButton && <FailingRequestButtonComponent />}
            </Grid>
            <Grid item sm={12} md={6} className={classes.continueButtonContainer}>
                {showContinueButton && (
                    <ButtonLinkComponent
                        id="continue"
                        to={nextPage ?? ''}
                        disabled={!nextPage && !isOrderSummaryPage}
                        className={classes.continueButton}
                        onClick={handleClick}
                    >
                        Continue
                    </ButtonLinkComponent>
                )}
            </Grid>
        </Grid>
    );
};

export default ContinueBarComponent;
