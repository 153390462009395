import css from 'css';
import memoizeOne from 'memoize-one';

const memoizedCssParser = memoizeOne(css.parse);
// This toolbox can find a property on a given css classname within a given css string
export default function getCssClassProperty(className: string, propertyName: string, fullCssString: string): string {
    const cssAst = memoizedCssParser(fullCssString);
    const rule = cssAst.stylesheet.rules.find(rule => rule.selectors.includes(className));
    if (!rule) {
        return undefined;
    }

    const declaration = rule.declarations.find(declaration => declaration.property === propertyName);
    if (!declaration) {
        return undefined;
    }

    return declaration.value;
}
