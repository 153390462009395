import { GooglePlaceDetailsResponse } from 'static/models/location/GooglePlaceDetailsResponse';
import { PickupLocation } from 'static/models/PickupLocation';

export function convertPlaceDetailsToPickupLocation(placeDetails: GooglePlaceDetailsResponse): PickupLocation {
    const street = placeDetails.result.address_components.find(x => x.types.includes('street_number'));
    const route = placeDetails.result.address_components.find(x => x.types.includes('route'));
    const city = placeDetails.result.address_components.find(x => x.types.includes('locality'));
    const state = placeDetails.result.address_components.find(x => x.types.includes('administrative_area_level_1'));
    const zip = placeDetails.result.address_components.find(x => x.types.includes('postal_code'));
    const county = placeDetails.result.address_components.find(x => x.types.includes('administrative_area_level_2'));

    const pickupLocation: PickupLocation = {
        pickupLocationStreet: `${street?.long_name || ''} ${route?.long_name || ''}`.trim(),
        pickupLocationCity: city?.long_name || '',
        pickupLocationCounty: county?.long_name || '',
        pickupLocationState: state?.short_name || '',
        pickupLocationZip: zip?.long_name || '',
        isWithinServiceRadius: false,
    };

    return pickupLocation;
}

export function getFullAddressFromPickupLocation(pickupLocation: PickupLocation): string {
    return `${pickupLocation.pickupLocationStreet}, ${pickupLocation.pickupLocationCity}, ${pickupLocation.pickupLocationState} ${pickupLocation.pickupLocationZip}`;
}
