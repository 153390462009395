import Environment from '../../static/models/Environment';

export const testEnvironment: Environment = {
    stripeKey: 'pk_test_RWUyawfBgmlSWaChbmAIZlSx',
    efuneralGatewayBaseUrl: 'https://plan.stagingefuneral.com',
    // efuneralGatewayBaseUrl: 'https://api.stagingfuneral.dev/v0',
    // efuneralGatewayBaseUrl: 'http://localhost:5001',
    customerContractOrReceiptUrl: 'https://plan.stagingefuneral.com/api/document',
    smartlookKey: '46abf62b125fe4b37dd703e4c2c9f7b5976b70a4',
    efuneralGatewayKey: '5XXFJZsbOraYqbdQfng0g8DjaZgmDHgPRpPoWQu5',
    sentryUrl: '',
    efuneralMediaBucket: 'https://media.stagingefuneral.com',
    featuresUrl: 'https://features.stagingefuneral.com/api/v1',
    tulipBaseUrl: 'planahead.tulipstaging.com',
    clearRepresentativeOnPurchaserEmailLinkLoad: true,
    agentSignupUrl: 'https://google.com',
    googleTagManagerId: '',
    createDemoRecords: false,
    googleMapsUri: 'https://api.stagingefuneral.com/google/maps',
    generalHellosignKey: '35fa953488e1559062d3f022fe2c205b',
    tulipHelloSignKey: '15ed7c489612f8f511592c136c5e164b',
    verifyHelloSignDomain: false,
    purchaserFlowUrl: 'stagingefuneral.com/checkout/',
    productionEnvironment: false,
    demoEnvironment: false,
    defaultSubdomain: 'acmefuneralhome',
};
