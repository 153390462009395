import { createSelector, OutputSelector } from '@reduxjs/toolkit';

import Account from '../../static/models/Account';
import { RootState } from '../root';

export const selectAvailableAccounts = (state: RootState): Account[] => state.availableAccounts;

export const selectAvailableAccountBySubdomain = (
    subdomain: string,
): OutputSelector<RootState, Account | undefined, (s: Account[]) => Account | undefined> =>
    createSelector(selectAvailableAccounts, accounts => accounts.find(a => a.subdomain === subdomain));

export const selectIsAvailableAccountAParentAccount = (
    account: Account,
): OutputSelector<RootState, boolean, (s: Account[]) => boolean> =>
    createSelector(selectAvailableAccounts, accounts => accounts.some(a => a.parentId === account.id));
