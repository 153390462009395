import { createTheme, Theme } from '@material-ui/core';
import getCssClassProperty from 'toolboxes/reuseable-logic/css-ast-parser';

import { getDefaultTheme } from './default-theme';

export const createThemeOptions = (css: string): Theme => {
    if (!css) {
        return getDefaultTheme();
    }

    const primaryColor = getCssClassProperty('.custom-btn', 'background-color', css).substring(0, 7);
    const secondaryColor = getCssClassProperty('.custom-btn-info', 'background-color', css).substring(0, 7);
    const headerBackgroundColor = getCssClassProperty('.custom-hdr-ftr-bkgrnd', 'background-color', css).substring(
        0,
        7,
    );
    const headerBorderColor = getCssClassProperty('.custom-hdr-ftr-border', 'border-color', css).substring(0, 7);
    const headerFontColor = getCssClassProperty('.custom-name-text', 'color', css).substring(0, 7);
    const footerFontColor = getCssClassProperty('.custom-footer-text', 'color', css).substring(0, 7);

    const defaultTheme = getDefaultTheme(primaryColor, secondaryColor);
    return createTheme({
        ...defaultTheme,
        palette: {
            ...defaultTheme.palette,
            primary: {
                main: primaryColor,
            },
            secondary: {
                main: secondaryColor,
            },
        },
        custom: {
            headerBottomBorderColor: headerBorderColor,
            headerBackgroundColor: headerBackgroundColor,
            headerFontColor: headerFontColor,
            footerBackgroundColor: headerBackgroundColor,
            footerFontColor: footerFontColor,
        },
    });
};
