import React from 'react';

import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import TimingGateComponent from 'components/global-components/timing-gate-component/TimingGateComponent';

const TimingGatePageComponent: React.FunctionComponent<PropShape> = () => {
    return (
        <PageContentWrapperComponent title="We're Sorry">
            <TimingGateComponent />
        </PageContentWrapperComponent>
    );
};
interface PropShape {
    // Shape of passed in props
}

TimingGatePageComponent.defaultProps = {
    // Set default values for props
};

export default TimingGatePageComponent;
