import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PickupLocation } from 'static/models/PickupLocation';

const initialState: PickupLocation = { isWithinServiceRadius: false };

export { initialState as initialPickupLocation };

const pickupLocation = createSlice({
    name: 'pickupLocation',
    initialState,
    reducers: {
        setPickupLocation(state, action: PayloadAction<PickupLocation>) {
            return action.payload;
        },
        updatePickupLocation(state, action: PayloadAction<PickupLocation>) {
            return { ...state, ...action.payload };
        },
    },
});

export const { setPickupLocation, updatePickupLocation } = pickupLocation.actions;
export default pickupLocation.reducer;
