import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProductQuestionResponse } from 'static/models/ProductQuestionResponse';

const initialState: ProductQuestionResponse[] = [];

export { initialState as initialProductQuestionsResponses };

const productQuestionResponses = createSlice({
    name: 'productQuestionResponses',
    initialState,
    reducers: {
        setProductQuestionResponses(state, action: PayloadAction<ProductQuestionResponse[]>) {
            return action.payload;
        },
        setProductQuestionResponse(state, action: PayloadAction<ProductQuestionResponse>) {
            // filter out questionId incase an answer has already be picked
            const responses = state.filter(x => x.productQuestionId !== action.payload.productQuestionId);
            // add the new answer to the responses
            return [...responses, action.payload];
        },
    },
});

export const { setProductQuestionResponses, setProductQuestionResponse } = productQuestionResponses.actions;
export default productQuestionResponses.reducer;
