import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'redux/root';
import {
    setDeclinedTravelProtection,
    setSelectedTravelPackage,
} from 'redux/travel-protection/selectedTravelPackage/root';
import Package from 'static/models/Package';

interface ReturnType {
    selectedTravelPackage: Package;
    declinedTravelProtection: boolean;
    setSelectedTravelPackage: (selectedTravelPackage: Package) => void;
    setDeclinedTravelProtection: (val: boolean) => void;
}

export const useSelectedTravelPackage = (): ReturnType => {
    const dispatch = useDispatch();
    return {
        selectedTravelPackage: useSelector((state: RootState) => state.selectedTravelPackage.package),
        declinedTravelProtection: useSelector(
            (state: RootState) => state.selectedTravelPackage.declinedTravelProtection,
        ),
        setSelectedTravelPackage: (val: Package) => dispatch(setSelectedTravelPackage(val)),
        setDeclinedTravelProtection: (val: boolean) => dispatch(setDeclinedTravelProtection(val)),
    };
};
