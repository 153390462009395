import React from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import { useAvailableTravelPackages } from 'hooks/travel/useAvailableTravelPackages';
import { useSelectedTravelPackage } from 'hooks/travel/useSelectedTravelPackage';
import { initialSelectedTravelPackage } from 'redux/travel-protection/selectedTravelPackage/root';
import Package from 'static/models/Package';

import ButtonComponent from '../layout-components/button-components/button-component/ButtonComponent';
import ProductQuestionCardComponent from '../layout-components/product-question-card-components/ProductQuestionCardComponent';
import TravelProtectionCardHeader from './TravelProtectionCardHeader';
import TravelProtectionCardSubheader from './TravelProtectionCardSubheader';

const useStyles = makeStyles(theme => ({
    declineButton: {
        margin: theme.spacing(1),
    },
}));

const TravelProtectionCards: React.FunctionComponent = () => {
    const classes = useStyles();
    const displayPackages = useAvailableTravelPackages();
    const {
        selectedTravelPackage,
        setSelectedTravelPackage,
        declinedTravelProtection,
        setDeclinedTravelProtection,
    } = useSelectedTravelPackage();
    const selectedId = selectedTravelPackage.id;

    const handleOptionClick = (p: Package) => {
        if (p === null) {
            // decline travel protection
            setDeclinedTravelProtection(true);
            if (selectedTravelPackage.id.length > 0) {
                setSelectedTravelPackage(initialSelectedTravelPackage);
            }
            return;
        }
        setSelectedTravelPackage(p);
    };

    const handleEditClick = () => {
        if (declinedTravelProtection) {
            setDeclinedTravelProtection(false);
        } else if (selectedId.length > 0) {
            setSelectedTravelPackage(initialSelectedTravelPackage);
        }
    };

    const editText = 'Click "Edit" to change your selection.';

    if (declinedTravelProtection) {
        return (
            <Grid item xs={12}>
                <ProductQuestionCardComponent
                    editButtonId="edit-declined-travel-package"
                    editButtonTitle="Edit Travel Package"
                    selectedText={editText}
                    answerSelected={true}
                    cardHeader="No travel protection"
                    onEditClick={handleEditClick}
                    options={[]}
                />
            </Grid>
        );
    }

    if (selectedId.length > 0) {
        return (
            <Grid item xs={12}>
                <ProductQuestionCardComponent
                    editButtonId="edit-travel-package"
                    editButtonTitle="Edit Travel Package"
                    selectedText={editText}
                    answerSelected={true}
                    cardHeader={<TravelProtectionCardHeader travelPackage={selectedTravelPackage} />}
                    onEditClick={handleEditClick}
                    options={[]}
                />
            </Grid>
        );
    }

    if (displayPackages.length === 1) {
        const p = displayPackages[0];
        const options = [
            { value: p, title: 'Select' },
            { value: null, title: 'Decline travel protection' },
        ];
        return (
            <Grid item xs={12}>
                <ProductQuestionCardComponent
                    editButtonTitle="Edit Travel Package"
                    selectedText=""
                    answerSelected={false}
                    cardHeader={<TravelProtectionCardHeader travelPackage={p} />}
                    cardSubheader={<TravelProtectionCardSubheader travelPackage={p} />}
                    buttonIdBase="travel-package"
                    onOptionClick={handleOptionClick}
                    options={options}
                />
            </Grid>
        );
    }

    return (
        <>
            {displayPackages
                .filter(p => !selectedId || p.id === selectedId)
                .map((p, i) => (
                    <Grid item xs={12} key={i}>
                        <ProductQuestionCardComponent
                            editButtonTitle="Edit Travel Package"
                            selectedText=""
                            answerSelected={p.id === selectedTravelPackage.id}
                            cardHeader={<TravelProtectionCardHeader travelPackage={p} />}
                            cardSubheader={<TravelProtectionCardSubheader travelPackage={p} />}
                            buttonIdBase={`select-travel-package-${p.id}`}
                            onOptionClick={handleOptionClick}
                            options={[{ value: p, title: 'Select' }]}
                        />
                    </Grid>
                ))}
            {selectedTravelPackage.id.length === 0 && (
                <Grid item xs={12} className={classes.declineButton}>
                    <ButtonComponent
                        id="decline-travel-protection"
                        variant="outlined"
                        fullWidth
                        onClick={() => handleOptionClick(null)}
                    >
                        No thank you, I would like to decline travel protection.
                    </ButtonComponent>
                </Grid>
            )}
        </>
    );
};

export default TravelProtectionCards;
