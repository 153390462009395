import React, { useState, useEffect } from 'react';

import { Grid } from '@material-ui/core';
import { sanitize } from 'dompurify';
import { useAvailableProductQuestions } from 'hooks/product-package-questions/useAvailableProductQuestions';
import { useProductQuestionResponses } from 'hooks/product-package-questions/useProductQuestionResponses';
import { useSelectedProducts } from 'hooks/product-package-questions/useSelectedProducts';
import { useOpportunity } from 'hooks/useOpportunity';
import ButtonOption from 'static/models/ButtonOption';
import { ProductQuestionResponse } from 'static/models/ProductQuestionResponse';
import { ProductQuestion } from 'static/models/ProductQuestions';
import { filterProductQuestions, getProductsFromAnswer } from 'toolboxes/reuseable-logic/product-question-functions';

import ProductQuestionCardComponent from '../layout-components/product-question-card-components/ProductQuestionCardComponent';
const ProductQuestionSelectionComponent: React.FunctionComponent = () => {
    const { opportunity } = useOpportunity();
    const availableProductQuestions = useAvailableProductQuestions();
    const { selectedProducts, updateQuestionProducts, removeSelectedProduct } = useSelectedProducts();
    const { productQuestionResponses, setProductQuestionResponses, setProductQuestionResponse } =
        useProductQuestionResponses();
    const [displayProductQuestions, setDisplayProductQuestions] = useState<ProductQuestion[]>([]);

    useEffect(() => {
        const relevantQuestions = filterProductQuestions(availableProductQuestions, opportunity);
        setDisplayProductQuestions(relevantQuestions);
    }, [opportunity, availableProductQuestions]);

    const handleOnAnswerSelected = (response: ProductQuestionResponse): void => {
        const answer = displayProductQuestions
            .find(x => x.id === response.productQuestionId)
            .productQuestionAnswers.find(x => x.id === response.productQuestionAnswerId);

        const answerProducts = getProductsFromAnswer(response.productQuestionId, answer);

        updateQuestionProducts({
            questionId: response.productQuestionId,
            products: answerProducts,
        });
        setProductQuestionResponse(response);
    };

    const handleOnEditQuestionResponse = (productQuestion: ProductQuestion): void => {
        // find all selectedProducts whose question id = the productQuestion.id
        const questionProducts = selectedProducts.filter(x => x.questionId === productQuestion.id);
        // remove the products from selectedProducts
        questionProducts.forEach(removeSelectedProduct);
        // remove from the productQuestionResponses redux store
        const filteredQuestionResponses = productQuestionResponses.filter(
            x => x.productQuestionId !== productQuestion.id,
        );
        setProductQuestionResponses(filteredQuestionResponses);
    };

    const handleOnAnswerSelect = (productQuestion: ProductQuestion, answerId: string): void => {
        handleOnAnswerSelected({
            productQuestionId: productQuestion.id,
            productQuestionAnswerId: answerId,
        });
    };

    const getAnswerOptions = (productQuestion: ProductQuestion): ButtonOption<string>[] => {
        return productQuestion.productQuestionAnswers.map(answer => {
            return {
                value: answer.id,
                title: answer.answerText,
                subtitle: answer.disclaimer,
            };
        });
    };

    const getSelectedAnswerId = (productQuestion: ProductQuestion): string => {
        const selectedAnswer = productQuestionResponses.find(x => x.productQuestionId === productQuestion.id);
        return selectedAnswer?.productQuestionAnswerId;
    };

    const getSelectedAnswerText = (productQuestion: ProductQuestion): string => {
        const selectedAnswer = productQuestionResponses.find(x => x.productQuestionId === productQuestion.id);
        if (!selectedAnswer) {
            return '';
        }
        const answer = productQuestion.productQuestionAnswers.find(
            x => x.id === selectedAnswer.productQuestionAnswerId,
        );
        return answer ? answer.answerText + (answer.disclaimer ? ' - ' + answer.disclaimer : '') : '';
    };

    const findFirstQuestionWithNoAnswer = (): string => {
        return displayProductQuestions.find(x => !productQuestionResponses.find(y => y.productQuestionId === x.id)).id;
    };

    return (
        <Grid container direction="row" justifyContent="center" spacing={2}>
            <Grid item xs={12}>
                {displayProductQuestions
                    .filter(
                        question =>
                            productQuestionResponses.find(x => x.productQuestionId === question.id) ||
                            question.id === findFirstQuestionWithNoAnswer(),
                    )
                    .map((q, i) => (
                        <ProductQuestionCardComponent
                            key={i}
                            editButtonId={q.id + '-edit'}
                            editButtonTitle={`Edit Question ${i}`}
                            selectedText={getSelectedAnswerText(q)}
                            answerSelected={!!getSelectedAnswerId(q)}
                            onEditClick={() => handleOnEditQuestionResponse(q)}
                            cardHeader={q.questionText}
                            buttonIdBase={'select-product-question-' + q.id}
                            onOptionClick={value => handleOnAnswerSelect(q, value)}
                            options={getAnswerOptions(q)}
                            cardSubheader={
                                <div dangerouslySetInnerHTML={{ __html: sanitize(q.questionTextSubtitle) }} />
                            }
                        />
                    ))}
            </Grid>
        </Grid>
    );
};

export default ProductQuestionSelectionComponent;
