import { createSelector } from '@reduxjs/toolkit';

import Account from '../../static/models/Account';
import { RootState } from '../root';

export const selectAccount = (state: RootState): Account => state.account;

export const selectHasAccountBeenSelected = createSelector(selectAccount, a => Boolean(a.id));
export const selectAccountName = createSelector(selectAccount, account => account.name);
export const selectAccountMembershipCost = createSelector(selectAccount, account => account.membershipCost);
export const selectAccountMembershipQuestionPageText = createSelector(
    selectAccount,
    account => account.membershipQuestionPageText,
);
export const selectAccountHasMembershipsEnabled = createSelector(selectAccount, account => account.feature_Membership);
