import React, { useEffect, useState } from 'react';

import { Grid } from '@material-ui/core';
import ProductQuestionCardComponent from 'components/global-components/layout-components/product-question-card-components/ProductQuestionCardComponent';
import { useAvailablePackages } from 'hooks/product-package-questions/useAvailablePackages';
import { useOpportunity } from 'hooks/useOpportunity';
import { usePickedPreferences } from 'hooks/usePickedPreferences';
import { getAvailableDispositionOptions } from 'static/models/DispositionOptions';
import { Disposition } from 'static/models/enums/disposition';
import { Timing } from 'static/models/enums/timing';
import timingOptions from 'static/models/TimingOptions';

import ConfirmationModalComponent from '../layout-components/confirmation-modal-component/ConfirmationModalComponent';

const PreferencesSelectionComponent: React.FunctionComponent = () => {
    const { opportunity } = useOpportunity();
    const availablePackages = useAvailablePackages();
    const { pickedPreferences, setPickedPreferences } = usePickedPreferences();
    const [showWarningModal, setShowWarningModal] = useState(false);
    const [acceptedChanges, setAcceptedChanges] = useState(false);
    const availableDispositionOptions = getAvailableDispositionOptions(availablePackages);

    useEffect(() => {
        const hasTiming = !!opportunity.timing;
        const hasDisposition = !!opportunity.disposition;
        const hasPickedTiming = !!pickedPreferences.timing;
        const hasPickedDisposition = !!pickedPreferences.disposition;
        const hasTimingChanged = opportunity.timing !== pickedPreferences.timing;
        const hasDispositionChanged = opportunity.disposition !== pickedPreferences.disposition;

        // if the opportunity has timing or disposition
        // and the 'picked' timing or disposition has a value
        // and the opportunity timing or disposition does not equal the picked value
        // then we can show the modal
        if (
            (hasTiming && hasPickedTiming && hasTimingChanged) ||
            (hasDisposition && hasPickedDisposition && hasDispositionChanged)
        ) {
            setShowWarningModal(true);
        } else {
            setShowWarningModal(false);
        }
    }, [opportunity, pickedPreferences, setShowWarningModal]);

    const handleOnTimingChange = (timing: Timing): void => {
        setPickedPreferences({ ...pickedPreferences, timing });
    };
    const handleDispositionChange = (disposition: Disposition) => {
        setPickedPreferences({ ...pickedPreferences, disposition });
    };

    const getSelectedTimingText = (): string => {
        const timing = timingOptions.find(x => x.value === pickedPreferences.timing);
        if (timing) {
            return timing.title + (timing.subtitle ? ' - ' + timing.subtitle : '');
        }
        return '';
    };
    const getSelectedDispositionText = (): string => {
        const disposition = availableDispositionOptions.find(x => x.value === pickedPreferences.disposition);
        if (disposition) {
            return disposition.title + ' - ' + disposition.subtitle;
        }
        return '';
    };

    const handleTimingEditClick = (): void => {
        setPickedPreferences({ ...pickedPreferences, timing: null });
    };
    const handleDispositionEditClick = (): void => {
        setPickedPreferences({ ...pickedPreferences, disposition: null });
    };

    const undoChanges = (): void => {
        setPickedPreferences({ timing: opportunity.timing, disposition: opportunity.disposition });
    };

    return (
        <>
            <Grid container direction="row" justifyContent="center" spacing={2} aria-label={'preferences selection'}>
                <Grid item xs={12}>
                    <ProductQuestionCardComponent
                        editButtonId="timing-edit"
                        editButtonTitle="Edit Timing"
                        selectedText={getSelectedTimingText()}
                        answerSelected={!!pickedPreferences.timing}
                        onEditClick={handleTimingEditClick}
                        cardHeader="When will you need our services?"
                        buttonIdBase="select-preferences-timing"
                        onOptionClick={handleOnTimingChange}
                        options={timingOptions}
                    />
                    {availableDispositionOptions.length > 1 &&
                        (pickedPreferences.timing || pickedPreferences.disposition) && (
                            <ProductQuestionCardComponent
                                editButtonId="disposition-edit"
                                editButtonTitle="Edit Disposition"
                                selectedText={getSelectedDispositionText()}
                                answerSelected={!!pickedPreferences.disposition}
                                onEditClick={handleDispositionEditClick}
                                cardHeader={`WHAT TYPE OF SERVICE WOULD YOU LIKE TO START PLANNING?`}
                                cardSubheader={
                                    <>
                                        <div>
                                            Deciding what will be done is an important decision that is deeply
                                            influenced by personal preferences and religious beliefs.
                                        </div>
                                        <div>Please note a cemetery plot is not included in this purchase.</div>
                                    </>
                                }
                                buttonIdBase="select-preferences-disposition"
                                onOptionClick={handleDispositionChange}
                                options={availableDispositionOptions}
                            />
                        )}
                </Grid>
            </Grid>
            <ConfirmationModalComponent
                id="preference-change"
                title="Preference Change"
                open={showWarningModal && !acceptedChanges}
                cancelText="Undo Changes"
                acceptText="Accept Changes"
                onCancel={undoChanges}
                onAccept={() => setAcceptedChanges(true)}
            >
                Changing your preferences greatly affects the care and services we need to provide. If you continue, we
                will have to restart the product selection process so we may best serve you.
            </ConfirmationModalComponent>
        </>
    );
};

export default PreferencesSelectionComponent;
