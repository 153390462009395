import { RoutingNode } from 'hooks/useRouteCalculator/routing-graph';
import urlPaths from 'static/constants/enums/urlPaths';

const membershipVerificationCompletePageRouting: RoutingNode = {
    forwardEdges: [
        {
            destination: urlPaths.start,
            condition: () => true,
        },
    ],
    backwardEdges: [],
    forAllBackwardEdges: () => {
        return false;
    },
};

export default membershipVerificationCompletePageRouting;
