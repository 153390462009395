import { RootState } from 'redux/root';
import urlPaths from 'static/constants/enums/urlPaths';
import {
    getMembershipQuestionSkipSessionStorage,
    getOpportunityIdLocalStorage,
    setMembershipQuestionSkipSessionStorage,
} from 'toolboxes/reuseable-logic/local-storage-getters-setters';

import {
    selectAccountHasMembershipsEnabled,
    selectHasAccountBeenSelected,
} from '../../../redux/account/account-selectors';
import {
    selectAvailableAccountBySubdomain,
    selectIsAvailableAccountAParentAccount,
} from '../../../redux/availableAccounts/available-accounts-selectors';
import { getSubdomain } from '../../reuseable-logic/get-subdomain';

function performMembershipReroutingForCurrentAccount(state: RootState): string | undefined {
    const isMembershipsEnabledForAccount = selectAccountHasMembershipsEnabled(state);
    if (isMembershipsEnabledForAccount) {
        return urlPaths.membershipQuestion;
    }
    return undefined;
}

function performMembershipReroutingForAvailableAccounts(state: RootState): string | undefined {
    const accountForSubdomain = selectAvailableAccountBySubdomain(getSubdomain())(state);
    if (!accountForSubdomain) {
        return undefined;
    }

    const isParentAccount = selectIsAvailableAccountAParentAccount(accountForSubdomain)(state);
    if (isParentAccount) {
        return urlPaths.locationSelection;
    }
    if (accountForSubdomain.feature_Membership) {
        return urlPaths.membershipQuestion;
    }
    return undefined;
}

function performMembershipRerouting(state: RootState): string | undefined {
    if (getMembershipQuestionSkipSessionStorage()) {
        return undefined;
    }

    if (state.membership.membershipSignUpValid || state.membership.membershipVerificationValid) {
        return undefined;
    }

    if (selectHasAccountBeenSelected(state)) {
        return performMembershipReroutingForCurrentAccount(state);
    }

    return performMembershipReroutingForAvailableAccounts(state);
}

export default function startReroutes(state: RootState, previousUrl: string): string {
    if (previousUrl === urlPaths.preferences) {
        setMembershipQuestionSkipSessionStorage();
    }
    const savedOpportunityId = getOpportunityIdLocalStorage(state);
    if (savedOpportunityId && !state.isDemoApp) {
        return urlPaths.returningOpportunity;
    }

    return performMembershipRerouting(state);
}
