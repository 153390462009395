import React, { useState } from 'react';

import { Dialog, Grid, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import GuaranteedLegendComponent from 'components/global-components/guaranteed-legend-component/GuaranteedLegendComponent';
import ProductCardComponent from 'components/global-components/product-card-component/ProductCardComponent';
import { useSelectedProducts } from 'hooks/product-package-questions/useSelectedProducts';
import { useOpportunity } from 'hooks/useOpportunity';
import { Timing } from 'static/models/enums/timing';
import Product, { ProductGroup } from 'static/models/Product';
import { groupProductsBySubcategory, productsAreTheSame } from 'toolboxes/reuseable-logic/products/productFunctions';

import { DescriptionOverflowType } from '../product-card-component/types';
import CloseActions from './CloseActions';
import NonQuantityActions from './NonQuantityActions';
import QuantityActions from './QuantityActions';

// sizing constants
const desktopCardWidth = 215;
const mobileCardWidth = 300;
const modalWidth = 308;
const modalContentHeight = 250;

const useStyles = makeStyles(theme => ({
    gridCell: {
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(1.5),
        [theme.breakpoints.up('md')]: {
            maxWidth: desktopCardWidth,
            marginLeft: 0,
            marginRight: theme.spacing(3),
        },
    },
    groupHeader: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
}));

const ProductGridListComponent: React.FunctionComponent<PropShape> = ({
    products,
    productsCategory,
    productSubcategory,
    multiselect,
    enableQuantity,
    groupBySubcategory,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const maxCardWidth = isMobile ? mobileCardWidth : desktopCardWidth;
    const descriptionOverflow: DescriptionOverflowType = isMobile ? 'expand' : 'open';

    const [modalProduct, setModalProduct] = useState<Product>(null);
    const { opportunity } = useOpportunity();
    const modalIsOpen = modalProduct !== null;
    const showGuaranteed = opportunity.timing !== Timing.IMMEDIATE;

    let groupedProducts: ProductGroup[] = [];
    if (groupBySubcategory) {
        groupedProducts = groupProductsBySubcategory(products, productsCategory);
    }

    const {
        selectedProducts,
        addSelectedProduct,
        setSelectedProducts,
        removeSelectedProduct: decrementProduct,
    } = useSelectedProducts();
    const anySelected = selectedProducts.some(
        p =>
            !p.isHiddenFromConsumer &&
            p.subCategory === productSubcategory &&
            !p.includedInPackage &&
            (p.questionId === null || p.questionId === undefined),
    );
    const handleSelect = (p: Product): void => {
        if (multiselect) {
            incrementProduct(p);
            return;
        }
        setSelectedProducts(
            selectedProducts.filter(p => p.includedInPackage || p.questionId || p.subCategory !== productSubcategory),
        );
        addSelectedProduct(p);
    };

    const handleOpenModal = (p: Product) => {
        if (!isMobile) {
            setModalProduct(p);
        }
    };

    const handleCloseModal = () => {
        if (!isMobile && modalProduct) {
            setModalProduct(null);
        }
    };

    const lookupProduct = (p: Product) => selectedProducts.find(product => productsAreTheSame(p, product));
    const productIsSelected = (product: Product) => !!lookupProduct(product);
    const getSelectedQuantity = (product: Product) => lookupProduct(product)?.quantity ?? 0;

    const incrementProduct = (product: Product): void => {
        const productCount = getSelectedQuantity(product);
        if (!product.maxQuantity || productCount < product.maxQuantity) {
            addSelectedProduct(product);
        }
    };

    const renderProductCard = (p: Product, i: number) => {
        return (
            <ProductCardComponent
                key={i}
                product={p}
                showGuaranteed={showGuaranteed}
                cardAction={
                    enableQuantity && productIsSelected(p) ? (
                        <QuantityActions
                            onDecrement={() => decrementProduct(p)}
                            onIncrement={() => incrementProduct(p)}
                            productId={p.id}
                            quantity={getSelectedQuantity(p)}
                        />
                    ) : (
                        <NonQuantityActions
                            anySelected={anySelected}
                            onSelect={() => handleSelect(p)}
                            productId={p.id}
                            selected={productIsSelected(p)}
                            title={p.name}
                        />
                    )
                }
                descriptionOverflow={descriptionOverflow}
                selected={productIsSelected(p)}
                onOpen={handleOpenModal}
                maxWidth={maxCardWidth}
            />
        );
    };

    return (
        <>
            <Grid container item>
                {showGuaranteed && <GuaranteedLegendComponent />}
            </Grid>
            {groupBySubcategory ? (
                groupedProducts.map(g => (
                    <Grid container key={g.groupName}>
                        <Grid container item>
                            <Typography variant="h3" className={classes.groupHeader}>
                                {g.groupName}
                            </Typography>
                        </Grid>
                        <Grid container item>
                            {g.products.map((p, i) => (
                                <Grid item xs={12} key={i} className={classes.gridCell}>
                                    {renderProductCard(p, i)}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                ))
            ) : (
                <Grid container>
                    {products.map((p, i) => (
                        <Grid item xs={12} key={i} className={classes.gridCell}>
                            {renderProductCard(p, i)}
                        </Grid>
                    ))}
                </Grid>
            )}
            <Dialog open={modalIsOpen} onClose={handleCloseModal}>
                {modalProduct && (
                    <ProductCardComponent
                        cardAction={<CloseActions onClick={handleCloseModal} productId={modalProduct.id} />}
                        product={modalProduct}
                        descriptionOverflow="scroll"
                        fixedWidth
                        width={modalWidth}
                        contentHeight={modalContentHeight}
                    />
                )}
            </Dialog>
        </>
    );
};

interface PropShape {
    products: Product[];
    productsCategory?: string;
    productSubcategory?: string;
    multiselect?: boolean;
    enableQuantity?: boolean;
    groupBySubcategory?: boolean;
}

ProductGridListComponent.defaultProps = {
    productsCategory: '',
    productSubcategory: '',
    multiselect: false,
    enableQuantity: false,
    groupBySubcategory: false,
};

export default ProductGridListComponent;
