import React, { ReactNode } from 'react';

import { Container, makeStyles, Typography } from '@material-ui/core';

import BackButtonComponent from '../back-button-component/BackButtonComponent';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    container: {
        flexGrow: 1,
        marginBottom: 20,
        width: '100%',
    },
    stickyTitle: {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.palette.background.default,
        zIndex: 1000,
        marginTop: '1rem',
        marginBottom: '1rem',
    },
    subtitle: {
        marginBottom: '2rem',
    },
}));

const PageContentWrapperComponent: React.FunctionComponent<PropShape> = ({ title, subtitle, children }) => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <BackButtonComponent />
            <Container className={classes.container}>
                {title && (
                    <Typography variant="h1" component="h1" className={classes.stickyTitle} aria-label="page title">
                        {title}
                    </Typography>
                )}
                {subtitle && (
                    <Typography variant="subtitle1" className={classes.subtitle} aria-label="page subtitle">
                        {subtitle}
                    </Typography>
                )}
                {children}
            </Container>
        </div>
    );
};
interface PropShape {
    title?: ReactNode;
    subtitle?: ReactNode;
}

PageContentWrapperComponent.defaultProps = {
    title: '',
    subtitle: '',
};
export default PageContentWrapperComponent;
