import React from 'react';

import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import TravelPackageSelectionComponent from 'components/global-components/travel-package-selection-component/TravelPackageSelectionComponent';
import { useTravelProvider } from 'hooks/travel/useTravelProvider';

const TravelProtectionPageComponent: React.FunctionComponent = () => {
    const travelProvider = useTravelProvider();
    return (
        <PageContentWrapperComponent
            title="Travel Protection Options"
            subtitle={
                <>
                    We have partnered with {travelProvider.name} to offer you the option of travel protection. You are
                    free to select a plan now or this service can be added on at a later date.
                </>
            }
        >
            <TravelPackageSelectionComponent />
        </PageContentWrapperComponent>
    );
};

export default TravelProtectionPageComponent;
