import { RootState } from 'redux/root';
import { updatePackageOnOpportunity } from 'toolboxes/server-application-buffer/opportunity-data';

import urlPaths from '../../../static/constants/enums/urlPaths';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';

export function packageSelection(state: RootState): BlockingFunction[] {
    return [
        {
            promiseGenerator: async () => {
                if (state.selectedPackage) {
                    await updatePackageOnOpportunity(state);
                }
            },
            blocksPageStarts: [urlPaths.casket, urlPaths.urn, urlPaths.outerBurialContainer],
        },
    ];
}
