import Account from 'static/models/Account';
import { GooglePlaceDetailsRequest } from 'static/models/location/GooglePlaceDetailsRequest';
import { GooglePlaceDetailsResponse } from 'static/models/location/GooglePlaceDetailsResponse';
import { ServiceAreaRequest } from 'static/models/location/ServiceAreaRequest';
import { ServiceAreaResponse } from 'static/models/location/ServiceAreaResponse';
import { AutocompletedLocation } from 'static/models/LocationAutocomplete';
import { LocationAutocompleteRequest } from 'static/models/LocationAutocompleteRequest';
import { PickupLocation } from 'static/models/PickupLocation';
import {
    fetchAutocompletedLocations,
    fetchPlaceDetails,
    fetchServiceArea,
} from 'toolboxes/web-requests/specific-requests/location-requests';

export async function getAutocompletedLocations(
    query: string,
    sessionToken: string,
    origin?: Account,
): Promise<AutocompletedLocation[]> {
    const autocompleteRequest: LocationAutocompleteRequest = {
        input: query,
        sessionToken,
        latitude: origin ? origin.latitude : '',
        longitude: origin ? origin.longitude : '',
        radius: origin ? origin.serviceRadius * 2 : 0,
    };
    const response = await fetchAutocompletedLocations(autocompleteRequest);
    if (!response.error_message) {
        return response.predictions;
    }
    console.error(response.error_message);
    return [];
}

export async function getPlaceDetails(
    placeId: string,
    address: string,
    opportunityId: string,
): Promise<GooglePlaceDetailsResponse> {
    const request: GooglePlaceDetailsRequest = {
        placeId,
        address,
        sessionToken: opportunityId,
    };
    return await fetchPlaceDetails(request);
}

export async function getDistanceToLocation(
    location: AutocompletedLocation,
    account: Account,
    units: 'imperial' | 'metric' = 'imperial',
): Promise<number> {
    const request: ServiceAreaRequest = {
        originPlaceId: account.googlePlaceId,
        destinationPlaceId: location.place_id,
        units,
    };
    const response = await fetchServiceArea(request);
    return response.distance;
}

export async function getServiceAreaData(
    destinationPlaceId: string,
    pickupLocation: PickupLocation,
    account: Account,
    units: 'imperial' | 'metric' = 'imperial',
): Promise<ServiceAreaResponse> {
    const request: ServiceAreaRequest = {
        originPlaceId: account.googlePlaceId,
        destinationPlaceId,
        destinationCounty: pickupLocation.pickupLocationCounty,
        destinationState: pickupLocation.pickupLocationState,
        serviceRadius: Number(account.serviceRadius),
        serviceRadiusAreas: account.serviceRadiusAreas,
        serviceRadiusMaximum: account.serviceRadiusMaximum,
        units,
    };
    return await fetchServiceArea(request);
}
