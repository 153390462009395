import { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import { setPickedPreferences } from 'redux/picked-preferences/root';
import { Preferences } from 'static/models/Preferences';

import { RootState } from '../redux/root';
import { useOpportunity } from './useOpportunity';

interface ReturnType {
    pickedPreferences: Preferences;
    setPickedPreferences: (pickedPreferences: Preferences) => void;
}

export const usePickedPreferences = (): ReturnType => {
    const { opportunity } = useOpportunity();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(
            setPickedPreferences({
                timing: opportunity.timing,
                disposition: opportunity.disposition,
            }),
        );
    }, [opportunity.timing, opportunity.disposition, dispatch]);

    return {
        pickedPreferences: useSelector((state: RootState) => state.pickedPreferences),
        setPickedPreferences: (pickedPreferences: Preferences) => dispatch(setPickedPreferences(pickedPreferences)),
    };
};
