import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = false;

export { initialState as initialIsDemoApp };

const isDemoApp = createSlice({
    name: 'isDemoApp',
    initialState,
    reducers: {
        setIsDemoApp(state, action: PayloadAction<any>) {
            return action.payload;
        },
    },
});

export const { setIsDemoApp } = isDemoApp.actions;
export default isDemoApp.reducer;
