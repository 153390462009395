import axios from 'axios';
import getEnvironment from 'env-config/get-environment';

export const runAPITest = (): void => {
    const baseUrl = getEnvironment().efuneralGatewayBaseUrl;
    axios
        .get(`${baseUrl}/api/endpointTests`, {
            headers: {
                'x-api-key': getEnvironment().efuneralGatewayKey,
                'Content-Type': 'application/json',
            },
        })
        .then(() => {
            console.info('API Test Completed Successfully.');
        })
        .catch((error: any) => {
            console.error(`API Test Completed with errors: ${error}`);
        });
};
