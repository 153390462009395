import { useSelector } from 'react-redux';

import { RootState } from '../redux/root';

interface ReturnType {
    isDemoApp: boolean;
}

export const useIsDemoApp = (): ReturnType => {
    return {
        isDemoApp: useSelector((state: RootState) => state.isDemoApp),
    };
};
