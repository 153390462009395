import { useDispatch, useSelector } from 'react-redux';
import { setErrorFormData, updateErrorFormData } from 'redux/errorLogging/errorFormData/root';
import ErrorFormData from 'static/models/errorLogging/errorFormData';

import { RootState } from '../redux/root';

interface ReturnType {
    errorFormData: ErrorFormData;
    setErrorFormData: (errorFOrmData: ErrorFormData) => void;
    updateErrorFormData: (errorFOrmData: ErrorFormData) => void;
}

export const useErrorFormData = (): ReturnType => {
    const dispatch = useDispatch();

    return {
        errorFormData: useSelector((state: RootState) => state.errorFormData),
        setErrorFormData: (value: ErrorFormData) => dispatch(setErrorFormData(value)),
        updateErrorFormData: (value: ErrorFormData) => dispatch(updateErrorFormData(value)),
    };
};
