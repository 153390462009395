import { useEffect, useState } from 'react';

import Account from 'static/models/Account';
import { AutocompletedLocation } from 'static/models/LocationAutocomplete';
import { getAutocompletedLocations } from 'toolboxes/server-application-buffer/location-data';
import { v4 as uuid } from 'uuid';
interface ReturnType {
    locations: AutocompletedLocation[];
    isLoading: boolean;
}

export function useLocationAutocomplete(query: string, origin?: Account): ReturnType {
    const [sessionToken] = useState(uuid()); // create a session ID and never update it for this use of useLocationAutocomplete
    const [locations, setLocations] = useState<AutocompletedLocation[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        let active = true; // keep track of whether we should let this request finish
        if (!query || query.length === 0) {
            setLocations([]);
            setIsLoading(false);
            return undefined; // don't do anything on cleanup
        }
        if (query.length > 0) {
            setIsLoading(true);
            const fetchSuggestions = async () => {
                const response = await getAutocompletedLocations(query, sessionToken, origin);
                if (active) {
                    setLocations(response);
                }
            };
            fetchSuggestions().then(() => {
                setIsLoading(false);
            });
        }
        return () => {
            // if this effect reruns before the async fetch finishes, set active to false for this instance
            // so we don't get results from this request coming back after the search query changes
            active = false;
        };
    }, [query, origin, sessionToken]);

    return { locations, isLoading };
}
