import React from 'react';

import { Typography } from '@material-ui/core';
import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import { useOpportunity } from 'hooks/useOpportunity';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { formatPhoneNumber } from 'toolboxes/reuseable-logic/string-formatters';

const TimingNotAvailableStopPageComponent: React.FunctionComponent<PropShape> = () => {
    const { opportunity } = useOpportunity();
    const { selectedAccount } = useSelectedAccount();

    return (
        <PageContentWrapperComponent title="We're Sorry">
            <Typography>
                Please call us at {formatPhoneNumber(selectedAccount.phoneNumber)} to speak with one of our team members
                so we can help you with your {opportunity.timing.toLocaleLowerCase()} funeral planning needs. We are
                available to help you now and in your time of need.
            </Typography>
        </PageContentWrapperComponent>
    );
};
interface PropShape {
    // Shape of passed in props
}

TimingNotAvailableStopPageComponent.defaultProps = {
    // Set default values for props
};

export default TimingNotAvailableStopPageComponent;
