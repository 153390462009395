import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Account from 'static/models/Account';

const initialState: Account[] = [];

export { initialState as initialAvailableAccounts };

const availableAccountsSlice = createSlice({
    name: 'availableAccounts',
    initialState,
    reducers: {
        setAvailableAccounts(state, action: PayloadAction<Account[]>) {
            return action.payload;
        },
    },
});

export const { setAvailableAccounts } = availableAccountsSlice.actions;
export default availableAccountsSlice.reducer;
