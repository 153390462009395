import { casket } from 'components/page-components/casket-page-component/casket-exit-functions';
import { enhancement } from 'components/page-components/enhancements-page-component/enhancements-exit-functions';
import ErrorExitFunctions from 'components/page-components/error-page-component/error-exit-functions';
import { limitedEnhancement } from 'components/page-components/limited-enhancements-page-component/limited-enhancements-exit-functions';
import loadOpportunityExitFunctions from 'components/page-components/load-opportunity/load-opportunity-exit-functions';
import { outerBurialContainer } from 'components/page-components/outer-burial-container-page-component/outer-burial-container-exit-functions';
import { packageSelection } from 'components/page-components/package-page-component/package-exit-functions';
import { pickupLocation } from 'components/page-components/pickup-location-page-component/pickup-location-exit-functions';
import { preferences } from 'components/page-components/preferences-page-component/preferences-exit-functions';
import { productQuestions } from 'components/page-components/product-questions-page-component/product-questions-exit-functions';
import { timingGate } from 'components/page-components/timing-gate-page-component/timing-gate-exit-functions';
import travelProtectionExitFunctions from 'components/page-components/travel-protection-page-component/travel-protection-exit-functions';
import { urn } from 'components/page-components/urn-page-component/urn-exit-functions';
import { Action } from 'history';
import { RootState } from 'redux/root';
import urlPaths from 'static/constants/enums/urlPaths';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import { RoutingParams } from 'static/models/RoutingParams';

// This function recieves the state, and can find out what we need to load
// If there are things that need to happen before we can show a url's page
// Return those things as executable asyncrenous functions here
// The promises should end by returning a boolean indicating success
export default function generatePageExitFunctions(
    state: RootState,
    dispatch: Function,
    urlPath: string,
    _params: RoutingParams,
    _navigationMethod: Action,
): BlockingFunction[] {
    switch (urlPath) {
        case urlPaths.loadOpportunity:
            return loadOpportunityExitFunctions(state, dispatch);
        case urlPaths.packages:
            return packageSelection(state);
        case urlPaths.preferences:
            return preferences(state, dispatch);
        case urlPaths.casket:
            return casket(state);
        case urlPaths.urn:
            return urn(state);
        case urlPaths.limitedEnhancements:
            return limitedEnhancement(state);
        case urlPaths.enhancements:
            return enhancement(state);
        case urlPaths.outerBurialContainer:
            return outerBurialContainer(state);
        case urlPaths.productQuestions:
            return productQuestions(state);
        case urlPaths.pickupLocation:
            return pickupLocation(state);
        case urlPaths.travelProtection:
            return travelProtectionExitFunctions(state, dispatch);
        case urlPaths.timingGate:
            return timingGate(state, dispatch);
        case urlPaths.error:
            return ErrorExitFunctions(state, dispatch);
        default:
            return [];
    }
}
