import getEnvironment from 'env-config/get-environment';
import { Disclaimer } from 'static/models/Disclaimer';
import BadRequestData from 'static/models/errorLogging/badRequestData';
import ErrorCaseCreationResponse from 'static/models/errorLogging/errorCaseCreationResponse';
import ErrorCaseUpdate from 'static/models/errorLogging/errorCaseUpdate';
import ErrorData from 'static/models/errorLogging/errorData';
import LoadedOpportunity from 'static/models/LoadedOpportunity';
import Opportunity from 'static/models/Opportunity';
import PackageUpdate from 'static/models/PackageUpdate';
import Product from 'static/models/Product';
import { ProductQuestionResponse } from 'static/models/ProductQuestionResponse';
import {
    getOrGenerateOriginToken,
    getReferralAccountIdentifier,
} from 'toolboxes/reuseable-logic/local-storage-getters-setters';

import productServiceClient from '../apis/productServiceClient';
import { sendRequest } from '../web-request-tools';

export function savePathToCustomerPathHistory(opportunityId: string, path: string): Promise<any> {
    return sendRequest(productServiceClient.post(`/opportunity/${opportunityId}/addToCustomerPath`, path)).catch(() => {
        return;
    });
}

export function runSaveCustomerPathAssociation(opportunityId: string, path: string): Promise<any> {
    return sendRequest(
        productServiceClient.post(`/opportunity/${opportunityId}/addCustomerPathAssociation`, path),
    ).catch(() => {
        return;
    });
}

export function createFuneralOpportunity(accountId: string): Promise<LoadedOpportunity> {
    const originToken = getOrGenerateOriginToken();
    return sendRequest(
        productServiceClient.post<LoadedOpportunity>(`/account/${accountId}/create_opportunity`, {
            originToken: originToken,
            referralAccount: getReferralAccountIdentifier(),
        }),
    );
}

export function fetchOpportunity(opportunityId: string): Promise<LoadedOpportunity> {
    return sendRequest(
        productServiceClient.post<LoadedOpportunity>(`/opportunity/${opportunityId}/load`, {
            OpportunityId: `${opportunityId}`,
        }),
    );
}

export async function fetchOpportunityDisclaimers(opportunityId: string): Promise<Disclaimer[]> {
    return sendRequest(productServiceClient.get(`/disclaimer/opportunity/${opportunityId}`));
}

export function runUpdatePackageOnOpportunity(packageUpdate: PackageUpdate): Promise<any> {
    return sendRequest(productServiceClient.post(`/packages/selectProducts`, packageUpdate));
}

export function runUpdateProductsForOpportunity(opportunityId: string, selectedProducts: Product[]): Promise<any> {
    return sendRequest(productServiceClient.post(`/packages/${opportunityId}/updateProducts`, selectedProducts));
}

export async function runUpdateProductQuestionResponses(
    opportunityId: string,
    productQuestionResponses: ProductQuestionResponse[],
): Promise<void> {
    await sendRequest(
        productServiceClient.post(`/packages/${opportunityId}/updateProductQuestions`, productQuestionResponses),
    );
}

export function updateOpportunity(accountId: string, opportunityData: Opportunity): Promise<void> {
    if (!opportunityData.opportunityId) {
        throw new Error('No opportunity Id provided');
    }
    if (!accountId) {
        throw new Error('No account Id provided');
    }
    return sendRequest(
        productServiceClient.post(`/account/${accountId}/create_opportunity`, {
            opportunityId: opportunityData.opportunityId,
            // If not creating demo records, don't override whatever is already set to avoid unexpected behavior
            demoRecord: getEnvironment().createDemoRecords ? true : null,
            ...opportunityData,
        }),
    );
}

export function runFailingRequest(data: BadRequestData): Promise<void> {
    return sendRequest(productServiceClient.post(`/endpointTests/FailingRequest`, data));
}
export function runCreateErrorCase(data: ErrorData): Promise<ErrorCaseCreationResponse> {
    return sendRequest(productServiceClient.post(`/Feedback/createErrorCase`, data));
}

export function runUpdateErrorCase(caseId: string, data: ErrorCaseUpdate): Promise<string> {
    return sendRequest(productServiceClient.post(`/Feedback/updateCase/${caseId}`, data));
}
