import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';
import { testEnvironment } from 'env-config/environment-configs/test';
import getEnvironment from 'env-config/get-environment';
import urlPaths from 'static/constants/enums/urlPaths';

import ButtonLinkComponent from '../layout-components/button-components/button-link-component/ButtonLinkComponent';
import MockLinkComponent from '../layout-components/mock-link-component/MockLinkComponent';

const useStyles = makeStyles(() => ({
    Button: {
        paddingLeft: 15,
        textAlign: 'center',
    },
    HiddenButton: {
        color: 'white',
    },
}));

const FailingRequestButtonComponent: React.FunctionComponent = () => {
    const classes = useStyles();
    const disabled = getEnvironment() !== testEnvironment;
    const [hidden, setHidden] = useState(true);

    return (
        <div className={classes.Button}>
            {hidden && (
                <MockLinkComponent onClick={() => setHidden(false)} id="toggleFailingButtonVisability">
                    <div className={classes.HiddenButton}>Show Failing Request Button</div>
                </MockLinkComponent>
            )}
            {!hidden && (
                <ButtonLinkComponent
                    id="sendFailingRequest"
                    disabled={disabled}
                    to={urlPaths.sendingFailingRequest}
                    onClick={() => setHidden(true)}
                >
                    Send Failing Request
                </ButtonLinkComponent>
            )}
        </div>
    );
};

export default FailingRequestButtonComponent;
