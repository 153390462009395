import React from 'react';

import { Box, Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import ButtonComponent from 'components/global-components/layout-components/button-components/button-component/ButtonComponent';
import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import { useOpportunity } from 'hooks/useOpportunity';
import { useDispatch } from 'react-redux';
import { resetAction } from 'redux/root';
import getDemoVideoSource from 'static/constants/paths';
import { clearOpportunityIdLocalStorage } from 'toolboxes/reuseable-logic/local-storage-getters-setters';

import DemoAppEndPageFormComponent from './DemoAppEndPageFormComponent';

const useStyles = makeStyles(_theme => ({
    video: {
        width: '100%',
    },
    cardBodyBold: {
        fontWeight: 'bold',
    },
    multilineRadioLabel: {
        display: 'inline-block',
        cursor: 'pointer',
        justifyContent: 'flex-start',
        textAlign: 'left',
        marginBottom: '1rem',
        backgroundColor: '#F0F0F0',
        '&:hover': {
            backgroundColor: '#a8a8a8',
            color: '#fff',
        },
    },
    marginAutoItem: {
        margin: 'auto',
    },
    greenButton: {
        backgroundColor: '#4B8607',
    },
}));

const DemoAppEndPageComponent: React.FunctionComponent = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { opportunity } = useOpportunity();

    function reset() {
        dispatch(resetAction());
        clearOpportunityIdLocalStorage();
        window.location.pathname = '/';
    }

    return (
        <PageContentWrapperComponent>
            <Grid container direction="column" alignContent="center" justifyContent="center" spacing={5}>
                <Box className={classes.marginAutoItem} width={1 / 4} maxWidth="220px">
                    <ButtonComponent
                        id={`restart-demo`}
                        className={classes.greenButton}
                        onClick={reset}
                        fullWidth
                        disableElevation
                    >
                        <Typography style={{ color: '#fff' }} variant="body2">
                            Restart Demo
                        </Typography>
                    </ButtonComponent>
                </Box>
                <Grid container item>
                    <Card elevation={4}>
                        <CardContent>
                            <Typography variant="h2">Let's find time to chat.</Typography>
                            <div>
                                <p>
                                    If you are ready to talk fill out the form below. Give us some detail about yourself
                                    and a team member from eFuneral will be in touch. We look forward to speaking with
                                    you.
                                </p>
                            </div>
                            <div>
                                <video className={classes.video} controls data-testid="demo-video">
                                    <source
                                        data-testid="demo-video-source"
                                        src={getDemoVideoSource(opportunity.timing)}
                                    />
                                </video>
                            </div>
                        </CardContent>
                    </Card>
                </Grid>

                <Card style={{ marginBottom: '2%' }} elevation={4}>
                    <DemoAppEndPageFormComponent reset={reset} />
                </Card>

                <Box className={classes.marginAutoItem} width={1 / 4} maxWidth="220px" paddingBottom="20px">
                    <ButtonComponent id={`restart-demo`} onClick={reset} fullWidth disableElevation>
                        <Typography variant="body2" className={classes.cardBodyBold}>
                            Restart
                        </Typography>
                    </ButtonComponent>
                </Box>
            </Grid>
        </PageContentWrapperComponent>
    );
};

export default DemoAppEndPageComponent;
