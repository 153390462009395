import React, { useState } from 'react';

import {
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Grid,
    makeStyles,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { sanitize } from 'dompurify';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import Package from 'static/models/Package';
import { getPackageText } from 'toolboxes/reuseable-logic/state-specific-language/package-language-change';
import { formatCurrency } from 'toolboxes/reuseable-logic/string-formatters';

import ButtonComponent from '../layout-components/button-components/button-component/ButtonComponent';
import ShowMoreButtonComponent from '../layout-components/button-components/show-more-button-component/ShowMoreButtonComponent';
import ContentOverflowComponent from '../layout-components/ui-components/content-overflow-component/ContentOverflowComponent';

const useStyles = makeStyles(theme => ({
    card: {
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 0,
        padding: '2rem',
    },
    cardBody: {
        display: 'flex',
        paddingBottom: 0,
    },
    cardDetails: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
    },
    cardMedia: {
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',
        },
    },
    cardMediaImage: {
        maxHeight: 186,
        objectFit: 'contain',
        [theme.breakpoints.down('sm')]: {
            maxHeight: 197,
        },
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 0,
        minHeight: '3rem',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    price: {
        fontWeight: 600,
        fontSize: '1rem',
        letterSpacing: 0,
        marginLeft: 'auto',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 'inherit',
            marginTop: '1rem',
            marginBottom: '2rem',
        },
    },
    cardActions: {
        padding: '0 1rem',
    },
    actionButtons: {
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    selectPackageButton: {
        width: 200,
    },
}));

const PackageCardComponent: React.FunctionComponent<PropShape> = (props: PropShape) => {
    const [hasHiddenContent, setHasHiddenContent] = useState(false);
    const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(true);
    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const { selectedAccount } = useSelectedAccount();

    const packageText = getPackageText(selectedAccount);

    const getButtonText = (): string => {
        if (props.selected) {
            return props.canUnselect ? 'UNSELECTED' : `${packageText.toUpperCase()} SELECTED`;
        }

        return props.singlePackageCard ? `CONFIRM ${packageText.toUpperCase()} SELECTION` : 'ADD';
    };

    const handlePackageSelected = (): void => {
        if (props.canUnselect && props.selected) {
            props.onUnselect(props.package);
        } else {
            props.onSelect(props.package);
        }
    };

    return (
        <Card className={classes.card} elevation={0}>
            <CardContent className={classes.cardBody}>
                <Grid container direction={isMobile ? 'column' : 'row'} spacing={2}>
                    {props.package.imageURL && (
                        <Grid item sm={isMobile ? undefined : 4} className={classes.cardMedia}>
                            <CardMedia
                                image={props.package.imageURL}
                                alt={`image of ${props.package.name}`}
                                className={classes.cardMediaImage}
                                component="img"
                            />
                        </Grid>
                    )}
                    <Grid item className={classes.cardDetails}>
                        <div className={classes.cardHeader}>
                            <Typography variant="h3" component="h3">
                                {props.package.name}
                            </Typography>
                            <Typography className={classes.price} variant="h3" color="primary">
                                {formatCurrency(props.package.price)}
                            </Typography>
                        </div>

                        <div>
                            <ContentOverflowComponent
                                maxHeight={props.singlePackageCard ? 288 : 144}
                                minHeight={96}
                                fadeHeight={64}
                                collapsed={isDescriptionCollapsed}
                                onHasHiddenContent={hasHidden => setHasHiddenContent(hasHidden)}
                            >
                                <Typography
                                    variant="body2"
                                    dangerouslySetInnerHTML={{ __html: sanitize(props.package.description) }}
                                />
                            </ContentOverflowComponent>
                        </div>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions disableSpacing className={classes.cardActions}>
                <Grid
                    container
                    direction={isMobile ? 'column' : 'row'}
                    spacing={isMobile ? 2 : 0}
                    className={classes.actionButtons}
                >
                    <Grid item>
                        {hasHiddenContent && (
                            <ShowMoreButtonComponent
                                id="show-more"
                                showMore={isDescriptionCollapsed}
                                onClick={() => setIsDescriptionCollapsed(!isDescriptionCollapsed)}
                            />
                        )}
                    </Grid>
                    <Grid item>
                        <ButtonComponent
                            id={`package-${props.package.id}`}
                            color="primary"
                            variant="outlined"
                            size="small"
                            disabled={!props.canUnselect && props.selected}
                            selected={props.selected}
                            className={classes.selectPackageButton}
                            onClick={() => handlePackageSelected()}
                        >
                            {getButtonText()}
                        </ButtonComponent>
                    </Grid>
                </Grid>
            </CardActions>
        </Card>
    );
};

interface PropShape {
    // Shape of passed in props
    package: Package;
    selected?: boolean;
    onSelect?: (p: Package) => void;
    singlePackageCard?: boolean;
    canUnselect?: boolean;
    onUnselect?: (p: Package) => void;
}

PackageCardComponent.defaultProps = {
    // Set default values for props
    singlePackageCard: false,
    selected: false,
    canUnselect: false,
    onSelect: () => {},
    onUnselect: () => {},
};

export default PackageCardComponent;
