import axios from 'axios';

import getEnvironment from '../../../env-config/get-environment';

const productServiceClient = axios.create({
    baseURL: getEnvironment().efuneralGatewayBaseUrl + '/api',
    headers: {
        'x-api-key': getEnvironment().efuneralGatewayKey,
        'Content-Type': 'application/json',
    },
});

export default productServiceClient;
