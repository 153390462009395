import { useSelector } from 'react-redux';
import { RootState } from 'redux/root';

interface ReturnType {
    urlParams: { [key: string]: string };
}

export const useUrlParams = (): ReturnType => {
    return {
        urlParams: useSelector((state: RootState) => state.urlParamTracking.loadParams),
    };
};
