import React from 'react';

import queryStringParser from 'query-string'; // TODO install
import { connect } from 'react-redux';
import { withRouter } from 'react-router'; // TODO install
import { setErrorData } from 'redux/errorLogging/root';
import { v4 as uuid } from 'uuid'; // TODO install

import { RootState } from '../../redux/root';
import BlockingFunction from '../../static/models/blockingFunctions/BlockingFunction';
import RouterFormattedBlockingFunction from '../../static/models/blockingFunctions/RouterFormattedBlockingFunction';
import generatePageEnterFunctions from '../../toolboxes/page-enter-functions/generate-page-enter-functions';
import generatePageExitFunctions from '../../toolboxes/page-exit-functions/generate-page-exit-functions';
import generatePageReroute from '../../toolboxes/page-reroute-functions/generate-page-reroutes';
import RoutingComponent from './RoutingComponent';

function appendIdsToBlockingFunctions(calculatedFunctions: BlockingFunction[]): RouterFormattedBlockingFunction[] {
    const appendedFunctions: RouterFormattedBlockingFunction[] = [];
    calculatedFunctions.forEach(blockingFunction => {
        const uniqueId = uuid();
        appendedFunctions.push({
            id: uniqueId,
            promiseGenerator: () =>
                new Promise<string>((resolve, reject) => {
                    blockingFunction
                        .promiseGenerator()
                        .then(() => {
                            return resolve(uniqueId);
                        })
                        .catch(error => {
                            return reject(error);
                        });
                }),
            blocksPageStarts: blockingFunction.blocksPageStarts,
        });
    });
    return appendedFunctions;
}

const mapStateToProps: any = (state: RootState, _myProps: any) => {
    // These values will be passed into the merge function as stateProps
    return {
        state,
    };
};

const mapDispatchToProps: any = (dispatch: Function) => {
    // These values will be passed into the merge function as dispatchProps
    return {
        dispatch,
        setErrorData: newValue => dispatch(setErrorData(newValue)),
    };
};

const mergeProps = (stateProps: any, dispatchProps: any, myProps: any) => {
    // These values will be passed in as props

    const state = stateProps.state;
    const dispatch = dispatchProps.dispatch;
    const setErrorData = dispatchProps.setErrorData;
    const currentPage = myProps.currentUrl;
    const previousUrl = myProps.previousUrl;
    const previousUrlFull = myProps.previousUrlFull;
    const queryParams = myProps.queryParams;
    const showDebugInfo = myProps.showDebugInfo;
    const navigationMethod = myProps.history.action;
    const basicPageEnterFunctions = generatePageEnterFunctions(
        state,
        dispatch,
        currentPage,
        queryParams,
        navigationMethod,
    );
    const basicPageExitFunctions = generatePageExitFunctions(
        state,
        dispatch,
        currentPage,
        queryParams,
        navigationMethod,
    );
    const pageEnterFunctions = appendIdsToBlockingFunctions(basicPageEnterFunctions);
    const pageExitFunctions = appendIdsToBlockingFunctions(basicPageExitFunctions);
    const pageReroute = generatePageReroute(state, currentPage, previousUrl, queryParams, navigationMethod);
    return {
        currentPage,
        previousUrlFull,
        pageEnterFunctions,
        pageReroute,
        pageExitFunctions,
        showDebugInfo,
        setErrorData,
    };
};

// @ts-ignore
const NakedRoutingComponent = connect(mapStateToProps, mapDispatchToProps, mergeProps)(RoutingComponent);

class RoutingWrapper extends React.Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            currentUrl: props.location.pathname,
            currentUrlFull: window.location.href,
            previousUrl: props.location.pathname,
            previousUrlFull: window.location.href,
        };
    }

    static getDerivedStateFromProps(nextProps: any, prevState: any) {
        if (nextProps.location.pathname !== prevState.currentUrl) {
            return {
                currentUrl: nextProps.location.pathname,
                currentUrlFull: window.location.href,
                previousUrl: prevState.currentUrl,
                previousUrlFull: prevState.currentUrlFull,
            };
        }
        return {
            ...prevState,
        };
    }

    render() {
        let queryParams = {};
        try {
            queryParams = queryStringParser.parse(this.props.location.search);
        } catch (e) {}
        return <NakedRoutingComponent {...this.props} {...this.state} queryParams={queryParams} />;
    }
}

export default withRouter(RoutingWrapper);
