import React from 'react';

import { makeStyles } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import MockLinkComponent from '../../mock-link-component/MockLinkComponent';

const useStyles = makeStyles({
    showMoreLink: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    showMoreIcon: {
        fontSize: 'inherit',
    },
});

const ShowMoreButtonComponent: React.FunctionComponent<PropShape> = props => {
    const classes = useStyles();

    return (
        <MockLinkComponent id={`show-more`} onClick={props.onClick} className={classes.showMoreLink}>
            {props.showMore ? (
                <>
                    show more details <ExpandMoreIcon />
                </>
            ) : (
                <>
                    show less details <ExpandLessIcon />
                </>
            )}
        </MockLinkComponent>
    );
};
interface PropShape {
    // Shape of passed in props
    id: string;
    showMore: boolean;
    className?: string;
    onClick?: (e: React.MouseEvent) => void;
}

ShowMoreButtonComponent.defaultProps = {
    // Set default values for props
};

export default ShowMoreButtonComponent;
