import { useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';

import { FormData, FormErrors, FormReturnType, FormTouched } from './FormTypes';

/*
    useForm provides form functions from a piece of state
*/
export const useForm = <T>(
    values: T,
    dispatchFunction: (payload: T) => void,
    validateFunction: (value: T) => FormErrors<T>,
): FormReturnType<T> => {
    const dispatch = useDispatch();
    const [touched, setToucheed] = useState<FormTouched<T>>({});

    const errors = useMemo(() => {
        return validateFunction(values);
    }, [values, validateFunction]);

    // Transform state values, errors and touched into a single data object
    const formData: FormData<T> = {};
    Object.keys(values).forEach(key => {
        formData[key] = {
            name: key,
            value: values[key],
            error: errors ? errors[key] : null,
            touched: touched[key],
        };
    });

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(dispatchFunction({ ...values, [e.target.name]: e.target.value }));
    };

    const handleOnBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        setToucheed({ ...touched, [e.target.name]: true });
    };

    const handleOnSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
    };

    return {
        //values: initialValues,
        formData,
        handleOnChange,
        handleOnBlur,
        handleOnSubmit,
    };
};
