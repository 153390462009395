import React, { useState } from 'react';

import { makeStyles, Typography, Grid, CardMedia, Card, CardContent } from '@material-ui/core';
import { sanitize } from 'dompurify';
import { useSelectedPackage } from 'hooks/product-package-questions/useSelectedPackage';
import { useSelectedProducts } from 'hooks/product-package-questions/useSelectedProducts';
import { useSelectedTravelPackage } from 'hooks/travel/useSelectedTravelPackage';
import { usePickupLocation } from 'hooks/usePickupLocation';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { getProductsForCart } from 'toolboxes/reuseable-logic/products/productFunctions';
import { formatPhoneNumber } from 'toolboxes/reuseable-logic/string-formatters';

import ShowMoreButtonComponent from '../layout-components/button-components/show-more-button-component/ShowMoreButtonComponent';
import ContentOverflowComponent from '../layout-components/ui-components/content-overflow-component/ContentOverflowComponent';
import ReceiptCartLineItemsComponent from './ReceiptCartLineItemsComponent';

const useStyles = makeStyles(theme => ({
    cardDetails: {
        padding: 5,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        textAlign: 'left',
    },
    cardMedia: {
        width: '100%',
        alignSelf: 'center',
    },
    cardMediaImage: {
        maxHeight: 127,
        objectFit: 'contain',
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 10,
        color: '#000000',
        paddingTop: 5,
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
    },
    price: {
        fontWeight: 'bold',
        paddingBottom: 10,
    },
    cardActions: {
        padding: '1rem',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'center',
        },
    },
    addButton: {
        width: 200,
    },
    paddingTop: {
        paddingTop: 10,
        width: '100%',
    },
    paddingBottom: {
        paddingBottom: 10,
    },
    textAlignLeft: {
        textAlign: 'left',
    },
    topLine: {
        borderTopWidth: 1,
        borderTopColor: theme.palette.primary.main,
        borderTopStyle: 'solid',
    },
    fontSizeSm: {
        fonstSize: '0.875rem',
    },
    fonstSizeMd: {
        fontSize: '1rem',
    },
    accountName: {
        color: '#000000',
    },
}));

const ReceiptOrderSummaryComponent: React.FunctionComponent = () => {
    const classes = useStyles();
    const [hasHiddenContent, setHasHiddenContent] = useState(false);
    const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(true);
    const { selectedProducts } = useSelectedProducts();
    const { selectedPackage } = useSelectedPackage();
    const { selectedAccount } = useSelectedAccount();
    const { selectedTravelPackage } = useSelectedTravelPackage();
    const { pickupLocation } = usePickupLocation();

    const groupedProducts = getProductsForCart(
        selectedProducts,
        selectedTravelPackage.products,
        pickupLocation,
        selectedAccount.mileageCharge,
    );

    return (
        <Grid container direction="column" spacing={2}>
            <div className={classes.paddingTop}>
                <Typography variant="h3" className={`${classes.accountName} ${classes.paddingBottom}`}>
                    {selectedAccount.name}
                </Typography>
                <div className={classes.paddingBottom}>
                    <Typography variant="body2">{selectedAccount.address}</Typography>
                    <Typography variant="body2">
                        {selectedAccount.city}, {selectedAccount.state} {selectedAccount.zip}
                    </Typography>
                    {selectedAccount.phoneNumber && (
                        <Typography variant="body2">{formatPhoneNumber(selectedAccount.phoneNumber)}</Typography>
                    )}
                </div>
                {selectedPackage.id && (
                    <Card elevation={0}>
                        <CardContent className={classes.cardDetails}>
                            <Grid container spacing={2}>
                                {selectedPackage.imageURL && (
                                    <Grid item xs={12} sm={4} className={classes.cardMedia}>
                                        <CardMedia
                                            id="cart-package-image"
                                            image={selectedPackage.imageURL}
                                            alt={`image of ${selectedPackage.name}`}
                                            className={classes.cardMediaImage}
                                            component="img"
                                        />
                                    </Grid>
                                )}
                                <Grid item className={classes.cardDetails}>
                                    <div className={classes.cardHeader}>
                                        <Typography variant="h3" component="h3">
                                            {selectedPackage.name}
                                        </Typography>
                                    </div>

                                    <div>
                                        <ContentOverflowComponent
                                            maxHeight={100}
                                            minHeight={0}
                                            fadeHeight={64}
                                            collapsed={isDescriptionCollapsed}
                                            onHasHiddenContent={hasHidden => setHasHiddenContent(hasHidden)}
                                        >
                                            <Typography
                                                variant="body2"
                                                dangerouslySetInnerHTML={{
                                                    __html: sanitize(selectedPackage.description),
                                                }}
                                            />
                                        </ContentOverflowComponent>
                                        {hasHiddenContent && (
                                            <ShowMoreButtonComponent
                                                id="show-more"
                                                showMore={isDescriptionCollapsed}
                                                onClick={() => setIsDescriptionCollapsed(!isDescriptionCollapsed)}
                                            />
                                        )}
                                    </div>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                )}
            </div>
            <div>
                <Grid item>
                    <span id="cart-line-item-summary" className={classes.textAlignLeft}>
                        {groupedProducts.map((x, i) => {
                            return <ReceiptCartLineItemsComponent key={i} productGroup={x} orderSummaryPage={true} />;
                        })}
                    </span>
                </Grid>
            </div>
        </Grid>
    );
};

export default ReceiptOrderSummaryComponent;
