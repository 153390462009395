import { useDispatch, useSelector } from 'react-redux';
import {
    setProductQuestionResponse,
    setProductQuestionResponses,
} from 'redux/product-package-questions/productQuestionResponses/root';
import { RootState } from 'redux/root';
import { ProductQuestionResponse } from 'static/models/ProductQuestionResponse';

interface ReturnType {
    productQuestionResponses: ProductQuestionResponse[];
    setProductQuestionResponses: (rs: ProductQuestionResponse[]) => void;
    setProductQuestionResponse: (r: ProductQuestionResponse) => void;
}

export const useProductQuestionResponses = (): ReturnType => {
    const dispatch = useDispatch();

    return {
        productQuestionResponses: useSelector((state: RootState) => state.productQuestionResponses),
        setProductQuestionResponses: (productQuestionResponses: ProductQuestionResponse[]) =>
            dispatch(setProductQuestionResponses(productQuestionResponses)),
        setProductQuestionResponse: (productQuestionResponse: ProductQuestionResponse) =>
            dispatch(setProductQuestionResponse(productQuestionResponse)),
    };
};
