import React from 'react';

import { Grid, Typography } from '@material-ui/core';
import TextBoxComponent from 'components/form-components/text-box-component/TextBoxComponent';
import { useForm } from 'hooks/forms/useForm';
import { leadContactValidator } from 'hooks/forms/validators';
import { useLeadContact } from 'hooks/useLeadContact';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { LeadContact } from 'static/models/LeadContact';

const TimingGateComponent: React.FunctionComponent = () => {
    const { selectedAccount } = useSelectedAccount();
    const { leadContact, updateLeadContact } = useLeadContact();
    const { formData, handleOnChange, handleOnBlur } = useForm<LeadContact>(
        leadContact,
        updateLeadContact,
        leadContactValidator,
    );

    return (
        <>
            <Typography style={{ marginBottom: '10px' }} aria-label="heading">
                Thank you for your interest in arranging with {selectedAccount.name}. Unfortunately, we aren’t able to
                complete your arrangements entirely online. However, we can give you more clarity of the process and a
                cost estimate, if you would like to proceed.
            </Typography>
            <Typography style={{ marginBottom: '20px' }}>
                <b>Please provide your information below,</b> to further understand your funeral options.
            </Typography>
            <Grid container item direction="column" spacing={2}>
                <Grid container item spacing={2}>
                    <Grid item xs={6}>
                        <TextBoxComponent
                            formName="leadContact"
                            fieldName={formData.firstName?.name}
                            labelString="First Name"
                            value={formData.firstName?.value}
                            showError={formData.firstName?.touched}
                            errorMessage={formData.firstName?.error}
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextBoxComponent
                            formName="leadContact"
                            fieldName={formData.lastName?.name}
                            labelString="Last Name"
                            value={formData.lastName?.value}
                            showError={formData.lastName?.touched}
                            errorMessage={formData.lastName?.error}
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                        />
                    </Grid>
                </Grid>
                <Grid container item spacing={2}>
                    <Grid item xs={6}>
                        <TextBoxComponent
                            formName="leadContact"
                            fieldName={formData.email?.name}
                            labelString="Email"
                            value={formData.email?.value}
                            showError={formData.email?.touched}
                            errorMessage={formData.email?.error}
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextBoxComponent
                            formName="leadContact"
                            fieldName={formData.phone?.name}
                            labelString="Phone Number"
                            value={formData.phone?.value}
                            showError={formData.phone?.touched}
                            errorMessage={formData.phone?.error}
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default TimingGateComponent;
