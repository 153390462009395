import React, { useCallback } from 'react';

import { getMembershipSignUpSubtitle, getMembershipSignUpTitle, MembershipSignUp } from '@efuneral/memberships';
import CardComponent from 'components/global-components/layout-components/ui-components/card-component/CardComponent';
import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import { sanitize } from 'dompurify';
import getEnvironment from 'env-config/get-environment';
import { useMembership } from 'hooks/useMembership';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { Redirect } from 'react-router';
import urlPaths from 'static/constants/enums/urlPaths';

const MembershipSignUpPageComponent: React.FunctionComponent = () => {
    const { selectedAccount } = useSelectedAccount();
    const { setMembershipSignUpValid, isMembershipSignUpValid } = useMembership();

    const handleOnFormSave = useCallback(
        (paymentStatus: boolean) => {
            if (paymentStatus !== isMembershipSignUpValid) {
                setMembershipSignUpValid(paymentStatus);
            }
        },
        [setMembershipSignUpValid, isMembershipSignUpValid],
    );

    if (isMembershipSignUpValid === true) {
        return <Redirect to={urlPaths.membershipPurchaseComplete} />;
    }

    const environment = getEnvironment();
    return (
        <PageContentWrapperComponent
            title={'Membership'}
            subtitle={
                'We require a membership to purchase our services online. Please answer the following questions prior to starting an online funeral arrangement.'
            }
        >
            <CardComponent
                title={getMembershipSignUpTitle()}
                subtitle={
                    <div
                        dangerouslySetInnerHTML={{
                            __html: sanitize(
                                getMembershipSignUpSubtitle(
                                    selectedAccount.membershipCost,
                                    selectedAccount.membershipFormPageText,
                                ),
                            ),
                        }}
                    />
                }
            >
                <MembershipSignUp
                    showTitle={false}
                    showSubTitle={false}
                    accountId={selectedAccount.id}
                    baseUrl={`${getEnvironment().efuneralGatewayBaseUrl}/api`}
                    apiKey={getEnvironment().efuneralGatewayKey}
                    membershipCost={selectedAccount.membershipCost}
                    stripeApiKey={environment.stripeKey}
                    onPaymentComplete={handleOnFormSave}
                />
            </CardComponent>
        </PageContentWrapperComponent>
    );
};

export default MembershipSignUpPageComponent;
