import { createTheme, Theme as CustomTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

// Generate breakpoints so we can use them in the theme definition
const breakpoints = createBreakpoints({});

// See here: https://material-ui.com/guides/typescript/#customization-of-theme
declare module '@material-ui/core/styles' {
    interface Theme {
        custom: {
            headerBottomBorderColor?: string;
            headerBackgroundColor?: string;
            headerFontColor?: string;
            footerBackgroundColor?: string;
            footerFontColor?: string;
            sectionSeperatorColor?: string;
            headerLogoMaxHeight?: number;
        };
    }
    interface ThemeOptions {
        custom?: {
            headerBottomBorderColor?: string;
            headerBackgroundColor?: string;
            headerFontColor?: string;
            footerBackgroundColor?: string;
            footerFontColor?: string;
            sectionSeperatorColor?: string;
            headerLogoMaxHeight?: number;
        };
    }
}

export const getDefaultTheme = (primaryColor = '#D3A02A', secondaryColor = '#596257'): CustomTheme => {
    return createTheme({
        palette: {
            background: {
                default: '#F5F5F5',
                paper: '#FFF',
            },
            primary: {
                main: primaryColor,
                contrastText: '#FFFFFF',
            },
            secondary: {
                main: secondaryColor,
                contrastText: '#FFFFFF',
            },
        },
        typography: {
            fontFamily: 'Inter, sans-serif',
            fontSize: 16,
            h1: {
                // page title
                color: '#000000',
                textAlign: 'left',
                fontSize: '1.563rem',
                fontWeight: 600,
                textTransform: 'uppercase',
                margin: 0,
                [breakpoints.down('sm')]: {
                    fontSize: '1rem',
                },
                letterSpacing: 0,
            },
            h2: {
                color: '#000000',
                textAlign: 'left',
                fontSize: '1.25rem',
                textTransform: 'uppercase',
                fontWeight: 400,
            },
            h3: {
                // used for card titles and product question and preferences card titles
                color: '#000000',
                textAlign: 'left',
                fontSize: '1.25rem',
                textTransform: 'uppercase',
                fontWeight: 600,
                letterSpacing: 0,
                [breakpoints.down('sm')]: {
                    fontSize: '1rem',
                },
            },
            body1: {
                color: '#000000',
                fontWeight: 400,
                fontSize: '1.25rem',
                fontHeight: '1.5rem',
                letterSpacing: '0em',
                [breakpoints.down('sm')]: {
                    fontSize: '0.875rem',
                },
            },
            body2: {
                // used for card body and product question and preferences options
                color: '#000000',
                fontWeight: 'normal',
                lineHeight: 1.5,
                letterSpacing: 0,
                fontSize: '1rem',
                [breakpoints.down('sm')]: {
                    fontSize: '0.875rem',
                },
            },
            subtitle1: {
                // page subtitle
                fontSize: '1.25rem',
                lineHeight: 1.5,
                [breakpoints.down('sm')]: {
                    fontSize: '0.875rem',
                },
            },
            subtitle2: {
                // product question summaries and preferences summaries
                color: '#000000',
                fontWeight: 'normal',
                fontSize: '1.1rem',
                fontHeight: '1.5rem',
                letterSpacing: '0.015em',
            },
            button: {
                fontSize: '1.2rem',
            },
        },
        spacing: 8,
        shape: {
            borderRadius: 2,
        },
        props: {
            MuiInput: {
                disableUnderline: true,
            },
        },
        overrides: {
            MuiInput: {
                root: {
                    '&$error': {
                        border: '1px solid red',
                    },
                    border: '1px solid #D5D8DC',
                    borderRadius: 4,
                    boxShadow: '0px 2px 0px rgba(210, 213, 218, 0.4)',
                    padding: 10,
                },
            },
            MuiInputBase: {
                root: {
                    fontWeight: 'normal',
                },
            },
            MuiButton: {
                root: {
                    textTransform: 'none',
                    borderRadius: 4,
                },
                outlined: {
                    borderWidth: 3,
                },
                outlinedPrimary: {
                    borderColor: primaryColor,
                    borderWidth: 3,
                    '&:hover': {
                        borderWidth: 3,
                    },
                },
            },
            MuiFormLabel: {
                root: {
                    fontWeight: 'normal',
                },
            },
        },
        custom: {
            headerBottomBorderColor: '#D3A02A',
            headerBackgroundColor: '#FFFFFF',
            headerFontColor: '#D3A02A',
            footerBackgroundColor: '#FFFFFF',
            footerFontColor: '#000000',
            sectionSeperatorColor: '#BDBDBD',
        },
    });
};
