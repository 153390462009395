export enum OpportunityStage {
    Visitor = 'Visitor',
    DemographicInformation = 'DemographicInformation',
    PaymentInfo = 'PaymentInfo',
    PurchaserIdentityVerification = 'PurchaserIdentityVerification',
    PurchaserSignContract = 'PurchaserSignContract',
    AgentSignContract = 'AgentSignContract',
    ClosedWon = 'ClosedWon',
    ClosedLost = 'ClosedLost',
}

const orderedStages = [
    OpportunityStage.Visitor,
    OpportunityStage.DemographicInformation,
    OpportunityStage.PaymentInfo,
    OpportunityStage.PurchaserIdentityVerification,
    OpportunityStage.PurchaserSignContract,
    OpportunityStage.AgentSignContract,
    OpportunityStage.ClosedWon,
    OpportunityStage.ClosedLost,
];

export default orderedStages;
