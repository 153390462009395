import getEnvironment from 'env-config/get-environment';
import Account from 'static/models/Account';
import { GeographicState } from 'static/models/enums/states';

export function shouldShowPricelist(account: Account): boolean {
    return account.state === GeographicState.TX;
}

export function getPricelistUrl(account: Account): string {
    return `${getEnvironment().efuneralMediaBucket}/pricelists/${account.subdomain}.pdf`;
}

export function getCPRADisclosureUrl(): string {
    return `${getEnvironment().efuneralMediaBucket}/notices/CA/CPRA+Notice+Rev1222.pdf`;
}
