import { RoutingNode } from 'hooks/useRouteCalculator/routing-graph';
import { RootState } from 'redux/root';
import urlPaths from 'static/constants/enums/urlPaths';

const membershipSignUpPageRouting: RoutingNode = {
    forwardEdges: [
        {
            destination: urlPaths.membershipPurchaseComplete,
            condition: () => true,
        },
    ],
    backwardEdges: [
        {
            destination: urlPaths.membershipQuestion,
            condition: () => true,
        },
    ],
    forAllForwardEdges: (state: RootState) => {
        return state.membership.membershipSignUpValid;
    },
};

export default membershipSignUpPageRouting;
