import { useDispatch, useSelector } from 'react-redux';
import { setDemoFormData, updateDemoFormData } from 'redux/demoApp/root';
import DemoFormData from 'static/models/demo/demoFormData';

import { RootState } from '../redux/root';

interface ReturnType {
    demoFormData: DemoFormData;
    setDemoFormData: (errorFOrmData: DemoFormData) => void;
    updateDemoFormData: (errorFOrmData: DemoFormData) => void;
}

export const useDemoAppFormData = (): ReturnType => {
    const dispatch = useDispatch();

    return {
        demoFormData: useSelector((state: RootState) => state.demoFormData),
        setDemoFormData: (value: DemoFormData) => dispatch(setDemoFormData(value)),
        updateDemoFormData: (value: DemoFormData) => dispatch(updateDemoFormData(value)),
    };
};
