import Product from 'static/models/Product';
import { filterProductsForPackage } from 'toolboxes/reuseable-logic/products/productFunctions';

import { useAvailableProducts } from './product-package-questions/useAvailableProducts';
import { useSelectedPackage } from './product-package-questions/useSelectedPackage';
import { useOpportunity } from './useOpportunity';

export function useFilteredProducts(category: string, subcategory: string, showAll = false): Product[] {
    const availableProducts = useAvailableProducts();
    const { selectedPackage } = useSelectedPackage();
    const {
        opportunity: { timing },
    } = useOpportunity();

    return filterProductsForPackage(selectedPackage, availableProducts, category, subcategory, timing, showAll);
}
