import React, { useCallback } from 'react';

import { MembershipQuestion } from '@efuneral/memberships';
import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { Redirect, useHistory } from 'react-router-dom';

import urlPaths from '../../../static/constants/enums/urlPaths';

const MembershipPageComponent: React.FunctionComponent = () => {
    const history = useHistory();
    const { selectedAccount } = useSelectedAccount();

    const handleAlreadyAMemberClick = useCallback(() => {
        history.push(urlPaths.membershipVerification);
    }, [history]);
    const handleNewMemberSignUpClick = useCallback(() => {
        history.push(urlPaths.membershipSignUp);
    }, [history]);

    if (!selectedAccount?.id) {
        return <Redirect to={urlPaths.locationSelection} />;
    }

    return (
        <PageContentWrapperComponent title={''} subtitle={''}>
            <MembershipQuestion
                accountName={selectedAccount.name}
                membershipCost={selectedAccount.membershipCost}
                customText={selectedAccount.membershipQuestionPageText}
                onAlreadyAMemberClick={handleAlreadyAMemberClick}
                onNewMemberSignUpClick={handleNewMemberSignUpClick}
            />
        </PageContentWrapperComponent>
    );
};

export default MembershipPageComponent;
