import React from 'react';

import MembershipPageComponent from 'components/membership-page-components/membership-page-component/MembershipPageComponent';
import MembershipPurchaseCompletePageComponent from 'components/membership-page-components/membership-purchase-complete-page-component/MembershipPurchaseCompletePageComponent';
import MembershipSignUpPageComponent from 'components/membership-page-components/membership-sign-up-page-component/MembershipSignUpPageComponent';
import MembershipVerificationCompletePageComponent from 'components/membership-page-components/membership-verification-complete-page-component/MembershipVerificationCompletePageComponent';
import MembershipVerificationPageComponent from 'components/membership-page-components/membership-verification-page-component/MembershipVerificationPageComponent';
import CasketPageComponent from 'components/page-components/casket-page-component/CasketPageComponent';
import DemoAppEndPageComponent from 'components/page-components/demo-app-end-page-component/DemoAppEndPageComponent';
import EnhancementsPageComponent from 'components/page-components/enhancements-page-component/EnhancementsPageComponent';
import ErrorPageWrapper from 'components/page-components/error-page-component/ErrorPageWrapper';
import SendingFailingRequestComponent from 'components/page-components/error-page-component/sending-failing-request-component/sendingFailingRequestComponent';
import ErrorSubmissionComponent from 'components/page-components/error-submission-component/ErrorSubmissionComponent';
import InitialLoadAndCreateComponent from 'components/page-components/initial-load-and-create-component/InitialLoadAndCreateComponent';
import LimitedEnhancementsPageComponent from 'components/page-components/limited-enhancements-page-component/LimitedEnhancementsPageComponent';
import LoadOpportunityPageComponent from 'components/page-components/load-opportunity/LoadOpportunityPageComponent';
import LocationSelectionPageComponent from 'components/page-components/location-selection-page-component/LocationSelectionPageComponent';
import OrderSummaryPageComponent from 'components/page-components/order-summary-component/OrderSummaryPageComponent';
import OuterBurialContainerPageComponent from 'components/page-components/outer-burial-container-page-component/OuterBurialContainerPageComponent';
import PackagePageComponent from 'components/page-components/package-page-component/PackagePageComponent';
import PickupLocationPageComponent from 'components/page-components/pickup-location-page-component/PickupLocationPageComponent';
import PreferencesPageComponent from 'components/page-components/preferences-page-component/PreferencesPageComponent';
import ProductQuestionsPageComponent from 'components/page-components/product-questions-page-component/ProductQuestionsPageComponent';
import ReturningOpportunityPageComponent from 'components/page-components/returning-opportunity/ReturningOpportunityPageComponent';
import StartPageComponent from 'components/page-components/start-page-component/StartPageComponent';
import TimingNotAvailableStopPageComponent from 'components/page-components/StopPages/timing-not-available-stop-page-component/TimingNotAvailableStopPageComponent';
import TimingGatePageComponent from 'components/page-components/timing-gate-page-component/TimingGatePageComponent';
import TravelProtectionPageComponent from 'components/page-components/travel-protection-page-component/TravelProtectionPageComponent';
import UrnPageComponent from 'components/page-components/urn-page-component/UrnPageComponent';
import { Route } from 'react-router-dom';
import urlPaths from 'static/constants/enums/urlPaths';

export default [
    <Route exact key="casket" path={urlPaths.casket} component={CasketPageComponent} />,
    <Route exact key="orderSummary" path={urlPaths.orderSummary} component={OrderSummaryPageComponent} />,
    <Route
        exact
        key="returningOpportunity"
        path={urlPaths.returningOpportunity}
        component={ReturningOpportunityPageComponent}
    />,
    <Route
        exact
        key="limitedEnhancements"
        path={urlPaths.limitedEnhancements}
        component={LimitedEnhancementsPageComponent}
    />,
    <Route exact key="enhancements" path={urlPaths.enhancements} component={EnhancementsPageComponent} />,
    <Route exact key="initalLoad" path={urlPaths.initialLoadAndCreate} component={InitialLoadAndCreateComponent} />,
    <Route
        exact
        key="locationSelection"
        path={urlPaths.locationSelection}
        component={LocationSelectionPageComponent}
    />,
    <Route
        exact
        key="outerBurialContainer"
        path={urlPaths.outerBurialContainer}
        component={OuterBurialContainerPageComponent}
    />,
    <Route exact key="preferences" path={urlPaths.preferences} component={PreferencesPageComponent} />,
    <Route exact key="packages" path={urlPaths.packages} component={PackagePageComponent} />,
    <Route exact key="start" path={urlPaths.start} component={StartPageComponent} />,
    <Route exact key="urn" path={urlPaths.urn} component={UrnPageComponent} />,
    <Route exact key="productQuestions" path={urlPaths.productQuestions} component={ProductQuestionsPageComponent} />,
    <Route key="loadOpportunity" path={urlPaths.loadOpportunity} component={LoadOpportunityPageComponent} />,
    <Route
        key="timingNotAvailable"
        exact
        path={urlPaths.timingNotAvailable}
        component={TimingNotAvailableStopPageComponent}
    />,
    <Route key="travelProtection" exact path={urlPaths.travelProtection} component={TravelProtectionPageComponent} />,
    <Route exact key="pickupLocation" path={urlPaths.pickupLocation} component={PickupLocationPageComponent} />,
    <Route
        exact
        key="sendingFailingRequest"
        path={urlPaths.sendingFailingRequest}
        component={SendingFailingRequestComponent}
    />,
    <Route exact key="error" path={urlPaths.error} component={ErrorPageWrapper} />,
    <Route exact key="errorSubmission" path={urlPaths.errorSubmission} component={ErrorSubmissionComponent} />,
    <Route exact key="membership" path={urlPaths.membershipQuestion} component={MembershipPageComponent} />,
    <Route
        exact
        key="membershipVerification"
        path={urlPaths.membershipVerification}
        component={MembershipVerificationPageComponent}
    />,
    <Route exact key="end" path={urlPaths.demoAppEndPageComponent} component={DemoAppEndPageComponent} />,
    <Route
        exact
        key="membershipVerificationComplete"
        path={urlPaths.membershipVerificationComplete}
        component={MembershipVerificationCompletePageComponent}
    />,
    <Route exact key="membershipSignUp" path={urlPaths.membershipSignUp} component={MembershipSignUpPageComponent} />,
    <Route
        exact
        key="membershipPurchaseComplete"
        path={urlPaths.membershipPurchaseComplete}
        component={MembershipPurchaseCompletePageComponent}
    />,
    <Route exact key="timingGate" path={urlPaths.timingGate} component={TimingGatePageComponent} />,
];
