import React, { SVGProps } from 'react';

import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { getGuaranteedText } from 'toolboxes/reuseable-logic/state-specific-language/guaranteed-language-change';

const GuaranteedIconComponent: React.FunctionComponent<PropShape> = ({ size }) => {
    const { selectedAccount } = useSelectedAccount();

    const guaranteedText = getGuaranteedText(selectedAccount, true);

    return (
        <div title={guaranteedText}>
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
                <g id="Ellipse_2">
                    <circle fill="none" cx="14.2" cy="9.8" r="8" />
                    <circle fill="none" stroke="#14A84A" strokeWidth="1.2" cx="14.2" cy="9.8" r="7.4" />
                </g>
                <text
                    transform="matrix(1 0 0 1 8.8264 15.0912)"
                    fill="#14A84A"
                    fontSize="14"
                    fontFamily="Inter"
                    fontWeight="400"
                    color="#000000"
                    letterSpacing="0"
                    height="17"
                >
                    <tspan x="0" y="0">
                        {guaranteedText.substring(0, 1).toUpperCase()}
                    </tspan>
                </text>
            </svg>
        </div>
    );
};

interface PropShape extends SVGProps<SVGSVGElement> {
    size?: number;
}

GuaranteedIconComponent.defaultProps = {
    size: 24,
};

export default GuaranteedIconComponent;
