export function createNavigationURL(
    protocol: string,
    host: string,
    newSubdomain: string,
    newPath?: string,
    queryParams?: { [key: string]: string },
): string {
    // this function creates a URL to navigate to the specified path under the specified subdomain.
    // if you only need to navigate to a new path (not a new subdomain), you should be using React Router and useHistory!!

    // All but newPath are required
    // An empty string could be passed in for newPath in previous logic. If we want to require newPath I can change it.
    if (!protocol || !host || !newSubdomain) {
        console.error('Did not provide one of the following: protocol, host, newSubdomain');
        return null;
    }

    const subdomain = host.split('.')[0];
    if (subdomain === newSubdomain) {
        // Tell people when they're using this wrong but still return a valid url
        console.error(
            "You shouldn't be using createNavigationURL if you aren't changing subdomains! Consider using useHistory and history.push from React Router instead.",
        );
        return new URL(`${protocol}//${host}${!newPath?.startsWith('/') ? `/${newPath}` : `${newPath}`}`).toString();
    }

    const newHost = host.replace(subdomain, newSubdomain);
    const url = new URL(`${protocol}//${newHost}${!newPath?.startsWith('/') ? `/${newPath}` : `${newPath}`}`);
    if (queryParams) {
        for (const [key, value] of Object.entries(queryParams)) {
            url.searchParams.append(key, value);
        }
    }
    return url.toString();
}
