import React from 'react';

import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';

// This is the Styling/Css make the classes and define the style in there
const useStyles = makeStyles(theme => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

const LoadingComponent: React.FunctionComponent<PropShape> = props => {
    const classes = useStyles();

    return (
        <Backdrop className={classes.backdrop} open={props.show}>
            <CircularProgress color="inherit" />
        </Backdrop>
    );
};
interface PropShape {
    // Shape of passed in props
    show?: boolean;
}

LoadingComponent.defaultProps = {
    // Set default values for props
    show: false,
};

export default LoadingComponent;
