import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { useRouteCalculator } from 'hooks/useRouteCalculator/useRouteCalculator';

import ButtonLinkComponent from '../layout-components/button-components/button-link-component/ButtonLinkComponent';

const useStyles = makeStyles(theme => ({
    backButton: {
        backgroundColor: 'transparent',
        color: theme.typography.h1.color,
        boxShadow: 'none',
        padding: 0,
        minWidth: 0, // unset material's default of 64
        textAlign: 'start',
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
            textDecoration: 'underline',
            color: 'black',
        },
    },
    backButtonContainer: {
        margin: '1em 0em 1em 0.5em',
    },
    backButtonFont: {
        textTransform: 'uppercase',
        fontSize: '0.875rem',
        lineHeight: 0, // don't let line height mess with the height of the container
    },
    backButtonSpacer: {
        height: '1em',
    },
}));

const BackButtonComponent: React.FunctionComponent = () => {
    const { previousPage } = useRouteCalculator();
    const classes = useStyles();
    return (
        <div className={`${classes.backButtonFont} ${classes.backButtonContainer}`}>
            {previousPage ? (
                <ButtonLinkComponent id="" to={previousPage} className={classes.backButton} disableRipple>
                    <ChevronLeft className={classes.backButtonFont} />
                    <Typography variant="body2" className={classes.backButtonFont}>
                        Back
                    </Typography>
                </ButtonLinkComponent>
            ) : (
                // Add a spacer when the back button is gone so stuff doesn't jump around
                <div className={classes.backButtonSpacer} />
            )}
        </div>
    );
};

export default BackButtonComponent;
