import React, { ReactNode } from 'react';

import { makeStyles, Typography, CardContent } from '@material-ui/core';
import ButtonComponent from 'components/global-components/layout-components/button-components/button-component/ButtonComponent';

const useStyles = makeStyles(theme => ({
    textUppercase: {
        textTransform: 'uppercase',
        flexGrow: 1,
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        minHeight: theme.spacing(4.5),
    },
    cardSubtitle: {
        paddingTop: '5px',
        paddingBottom: '10px',
    },
    cardBodyBold: {
        fontWeight: 'bold',
    },
    editButton: {
        minHeight: theme.spacing(4.5),
        color: '#707070',
        '&:hover': {
            backgroundColor: 'transparent',
        },
    },
}));

const ProductQuestionCardHeaderComponent: React.FunctionComponent<PropShape> = ({
    answerSelected,
    cardHeader,
    cardSubheader,
    editButtonId,
    editButtonTitle,
    onEditClick,
    selectedText,
}) => {
    const classes = useStyles();

    return (
        <CardContent>
            <div className={classes.titleContainer}>
                <Typography variant="h3" component="h3" className={classes.textUppercase}>
                    {cardHeader}
                </Typography>
                {answerSelected && (
                    <ButtonComponent
                        id={editButtonId}
                        title={editButtonTitle}
                        variant="text"
                        size="small"
                        className={classes.editButton}
                        onClick={onEditClick}
                    >
                        EDIT
                    </ButtonComponent>
                )}
            </div>
            {answerSelected ? (
                <Typography variant="subtitle2">{selectedText}</Typography>
            ) : (
                <Typography variant="subtitle2">{cardSubheader}</Typography>
            )}
        </CardContent>
    );
};

interface PropShape {
    // Shape of passed in props
    editButtonId: string;
    editButtonTitle: string;
    selectedText: string;
    answerSelected: boolean;
    onEditClick: () => void;
    cardHeader: ReactNode;
    cardSubheader?: ReactNode;
}

ProductQuestionCardHeaderComponent.defaultProps = {
    // Set default values for props
    answerSelected: false,
    editButtonId: '',
    selectedText: '',
    onEditClick: () => {},
    cardHeader: '',
    cardSubheader: null,
};

export default ProductQuestionCardHeaderComponent;
