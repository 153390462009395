import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Package from 'static/models/Package';

const initialState: Package = null;

export { initialState as initialSelectedPackage };

const selectedPackage = createSlice({
    name: 'selectedPackage',
    initialState,
    reducers: {
        setSelectedPackage(state, action: PayloadAction<Package>) {
            return action.payload;
        },
    },
});

export const { setSelectedPackage } = selectedPackage.actions;
export default selectedPackage.reducer;
