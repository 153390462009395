import React from 'react';

import { makeStyles } from '@material-ui/core';

// This is the Styling/Css make the classes and define the style in there
const useStyles = makeStyles(theme => ({
    root: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
    },
}));

const MockLinkComponent: React.FunctionComponent<PropShape> = props => {
    const classes = useStyles();

    const handleOnClick = (e: React.MouseEvent): void => {
        e.preventDefault();
        props.onClick(e);
    };

    return (
        <a
            href="#"
            id={props.id}
            onClick={(e: React.MouseEvent) => handleOnClick(e)}
            className={`${classes.root} ${props.className}`}
        >
            {props.children}
        </a>
    );
};
interface PropShape {
    // Shape of passed in props
    id: string;
    className?: string;
    onClick?: (e: React.MouseEvent) => void;
}

MockLinkComponent.defaultProps = {
    // Set default values for props
    onClick: () => {},
};

export default MockLinkComponent;
