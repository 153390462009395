export default interface Account {
    id: string;
    parentId: string;
    state: string;
    phoneNumber: string;
    subdomain: string;
    facebookUrl: string;
    instagramUrl: string;
    twitterUrl: string;
    yelpUrl: string;
    address: string;
    city: string;
    funeralHomeNumber: string;
    zip: string;
    name: string;
    mediaDomain: string;
    latitude: string;
    longitude: string;
    googlePlaceId: string;
    serviceRadius?: number;
    salesTax?: number;
    membershipCost: string;
    membershipFormPageText: string;
    membershipQuestionPageText: string;
    // The agent doesn't work because the backend doesn't pull in the agent information
    // agent: {
    //     id: string;
    //     agentCode: string;
    //     canAddProducts: boolean;
    // };
    serviceRadiusAreas?: string;
    mileageCharge?: number;
    serviceRadiusMaximum?: string;
    website: string;
    contractLogoUrl: string;
    googleTagManagerId: string;
    efuneralServiceTier: string;

    feature_ImmediateSales: boolean;
    feature_FutureSales: boolean;
    feature_ImminentSales: boolean;
    feature_DynamicPackage: boolean;
    feature_EnhancementSelection: boolean;
    feature_MerchandiseSelection: boolean;
    feature_CasketSelection: boolean;
    feature_UrnSelection: boolean;
    feature_OBCSelection: boolean;
    feature_Membership: boolean;
    feature_TimingGateLocation: string;

    customColors?: string;
}

export const defaultAccount: Account = {
    id: '',
    parentId: '',
    state: '',
    phoneNumber: '',
    subdomain: '',
    facebookUrl: '',
    instagramUrl: '',
    twitterUrl: '',
    yelpUrl: '',
    address: '',
    city: '',
    funeralHomeNumber: '',
    zip: '',
    name: '',
    mediaDomain: '',
    latitude: '',
    longitude: '',
    googlePlaceId: '',
    website: '',
    contractLogoUrl: '',
    membershipCost: '',
    membershipFormPageText: '',
    membershipQuestionPageText: '',
    googleTagManagerId: '',
    efuneralServiceTier: '',

    feature_ImmediateSales: false,
    feature_FutureSales: false,
    feature_ImminentSales: false,
    feature_DynamicPackage: false,
    feature_EnhancementSelection: false,
    feature_MerchandiseSelection: false,
    feature_CasketSelection: false,
    feature_UrnSelection: false,
    feature_OBCSelection: false,
    feature_Membership: false,
    feature_TimingGateLocation: null,

    customColors: '',
};
