import Environment from '../../static/models/Environment';

export const prodEnvironment: Environment = {
    stripeKey: 'pk_live_vWaqOJ3o4HJ5m8T8P7VZ7sWJ',
    efuneralGatewayBaseUrl: 'https://plan.efuneral.com',
    // efuneralGatewayBaseUrl: 'https://api.funeral.dev/v1',
    customerContractOrReceiptUrl: 'https://plan.efuneral.com/api/document',
    smartlookKey: '49c387b78497614c6f81c24e14e8323fef45a51d',
    efuneralGatewayKey: 'DKtLU2TsXt2C1attfKXbT6iFL1O6MKkz8wNjmmHC',
    sentryUrl: 'https://79fba47d2b904750aa72a8dfef066574@sentry.io/1731161',
    efuneralMediaBucket: 'https://media.efuneral.com',
    featuresUrl: 'https://features.efuneral.com/api/v1',
    tulipBaseUrl: 'planahead.tulipcremation.com',
    clearRepresentativeOnPurchaserEmailLinkLoad: false,
    agentSignupUrl: 'https://efuneralagent.com',
    googleTagManagerId: 'GTM-PXCJQJZ',
    createDemoRecords: false,
    googleMapsUri: 'https://api.efuneral.com/google/maps',
    generalHellosignKey: 'dbe4b436df04ce00358af0128c610872',
    tulipHelloSignKey: '170aa0a8411147c9552849c5c77d9a88',
    verifyHelloSignDomain: true,
    purchaserFlowUrl: 'efuneral.com/checkout/',
    productionEnvironment: true,
    demoEnvironment: false,
    defaultSubdomain: '',
};
