import { RootState } from 'redux/root';
import urlPaths from 'static/constants/enums/urlPaths';
import { RoutingParams } from 'static/models/RoutingParams';
import { getOpportunityIdLocalStorage } from 'toolboxes/reuseable-logic/local-storage-getters-setters';

export default function loadOpportunityReroutes(state: RootState, params: RoutingParams): string {
    const savedOpportunityId = getOpportunityIdLocalStorage(state);
    if (!(params && 'id' in params) && !savedOpportunityId) {
        return urlPaths.error;
    }
}
