import React from 'react';

import { makeStyles } from '@material-ui/core';
import Package from 'static/models/Package';
import { formatCurrency } from 'toolboxes/reuseable-logic/string-formatters';

const useStyles = makeStyles({
    packageTitle: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

const TravelProtectionCardHeader: React.FunctionComponent<PropShape> = ({ travelPackage }) => {
    const classes = useStyles();
    return (
        <div className={classes.packageTitle}>
            <div>{travelPackage.name}</div>
            <div>{formatCurrency(travelPackage.price)}</div>
        </div>
    );
};

interface PropShape {
    travelPackage: Package;
}

export default TravelProtectionCardHeader;
