import React from 'react';

import { makeStyles } from '@material-ui/core';
import ButtonComponent from 'components/global-components/layout-components/button-components/button-component/ButtonComponent';

const useStyles = makeStyles(theme => ({
    addButton: {
        fontSize: '1em',
        height: theme.spacing(4.5),
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        },
    },
}));

const NonQuantityActions: React.FunctionComponent<PropShape> = ({
    anySelected,
    onSelect,
    productId,
    selected,
    title = '',
}) => {
    const classes = useStyles();
    const buttonText = selected ? 'Selected' : anySelected ? 'Select' : 'Add';
    return (
        <ButtonComponent
            id={`add-${productId}`}
            color="primary"
            variant={selected ? 'contained' : 'outlined'}
            className={classes.addButton}
            disabled={selected}
            onClick={onSelect}
            title={`${buttonText} ${title}`}
            fullWidth
            disableElevation
        >
            {buttonText}
        </ButtonComponent>
    );
};

interface PropShape {
    anySelected: boolean;
    onSelect: () => void;
    productId: string;
    selected: boolean;
    title?: string;
}

export default NonQuantityActions;
