import React from 'react';

import { useAvailableAccounts } from 'hooks/useAvailableAccounts';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { Redirect } from 'react-router';
import urlPaths from 'static/constants/enums/urlPaths';

const StartPageComponent: React.FunctionComponent<PropShape> = () => {
    /*
    Need to check local storage for opportunity id in reroute and send down that path
    If no opportunity id in local storage enter page functions need to load the accounts. 
    
    On this page
    If only one account available/an account has already been selected route to the prferences page.
    If more than one account available route to the location selection page
    If no accounts available route to an error page.
    */
    const { selectedAccount } = useSelectedAccount();
    const availableAccounts = useAvailableAccounts();

    if (selectedAccount.id) {
        return <Redirect to={urlPaths.initialLoadAndCreate} />;
    } else if (availableAccounts.length > 1) {
        return <Redirect to={urlPaths.locationSelection} />;
    } else {
        return <Redirect to={urlPaths.error} />;
    }
};
interface PropShape {
    // Shape of passed in props
}
StartPageComponent.defaultProps = {
    // Set default values for props
};
export default StartPageComponent;
