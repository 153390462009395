import { initialProduct } from 'redux/product-package-questions/product/root';
import { RootState } from 'redux/root';
import { productCategoryOrder } from 'static/constants/productCategoryOrder';
import { Disposition } from 'static/models/enums/disposition';
import { PackageSelectionType } from 'static/models/enums/PackageSelectionType';
import { productCategories } from 'static/models/enums/productCategories';
import { Timing } from 'static/models/enums/timing';
import Package from 'static/models/Package';
import { PickupLocation } from 'static/models/PickupLocation';
import Product, { ProductGroup } from 'static/models/Product';

import { matchPackageAttributes } from '../package-functions';
import { sortByNumbers, sortBySortingArray, sortByStrings } from '../sort-functions';
import { formatCurrency } from '../string-formatters';

export const getTotalPrice = (product: Product): number => {
    return product.price * product.quantity;
};

export const getProductTax = (product: Product, salesTaxPercent: number): number => {
    if (product.explicitSalesTax) {
        return product.quantity * product.explicitSalesTax;
    }
    if (salesTaxPercent && salesTaxPercent !== 0 && product.salesTaxApplicable) {
        if (salesTaxPercent > 1) {
            salesTaxPercent = salesTaxPercent / 100;
        }
        return product.quantity * product.price * salesTaxPercent;
    } else {
        return 0;
    }
};

export const getFormattedPriceForProduct = (product: Product): string => {
    if (product.included) {
        return 'Included';
    }

    return formatCurrency(product.price);
};

export const getFormattedPriceForLineItem = (product: Product): string => {
    if (product.included) {
        return 'Included';
    }

    const lineItemTotal = product.price * product.quantity;
    return formatCurrency(lineItemTotal);
};

export const getFuneralSubTotalPrice = (products: Product[]): number =>
    products.reduce((previousPrice, currentProduct) => previousPrice + getTotalPrice(currentProduct), 0);

export const getFormattedFuneralSubTotalPrice = (
    products: Product[],
    pickupLocation?: PickupLocation,
    mileageCharge?: number,
): string => {
    const productsToTotal = [...products];
    if (pickupLocation?.mileageCost) {
        productsToTotal.push(convertPickupLocationToProduct(pickupLocation, mileageCharge));
    }
    const funeralTotal = getFuneralSubTotalPrice(productsToTotal);
    return formatCurrency(funeralTotal);
};

export const getFuneralTaxTotalPrice = (products: Product[], salesTaxPercent: number): number => {
    return products.reduce(
        (previousPrice, currentProduct) => previousPrice + getProductTax(currentProduct, salesTaxPercent),
        0,
    );
};

export const getFormattedFuneralTaxTotalPrice = (products: Product[], salesTaxPercent: number): string => {
    const funeralTaxTotal = getFuneralTaxTotalPrice(products, salesTaxPercent);
    return formatCurrency(funeralTaxTotal);
};

export const getProductPriceWithTax = (product: Product, salesTaxPercent: number): number => {
    const productPrice = getTotalPrice(product);
    const productTaxPrice = getProductTax(product, salesTaxPercent);
    return productPrice + productTaxPrice;
};

export const getFuneralTotalWithTaxIncluded = (products: Product[], salesTaxPercent: number): number =>
    products.reduce(
        (previousPrice, currentProduct) => previousPrice + getProductPriceWithTax(currentProduct, salesTaxPercent),
        0,
    );

export const getFormattedFuneralTotalWithTaxIncluded = (
    products: Product[],
    salesTaxPercent: number,
    travelProtectionPrice: number,
    pickupLocation?: PickupLocation,
    mileageCharge?: number,
): string => {
    const productsToTotal = [...products];
    if (pickupLocation?.mileageCost) {
        productsToTotal.push(convertPickupLocationToProduct(pickupLocation, mileageCharge));
    }
    const total = getFuneralTotalWithTaxIncluded(productsToTotal, salesTaxPercent);
    return formatCurrency(total + travelProtectionPrice);
};

export const getTaxableProducts = (products: Product[]): Product[] => {
    // There are 111 instances in prod where a product has explicitSalesTax but salesTaxApplicable is false
    return products.filter(x => x.salesTaxApplicable || x.explicitSalesTax > 0);
};

export const getFormattedQuantity = (product: Product): string => {
    return product.quantity ? product.quantity.toFixed(0) : '';
};

export const getFormattedLineTotal = (product: Product): string => {
    return product.lineItemTotal ? product.lineItemTotal.toFixed(2) : '';
};

export const filterProductsByCategory = (
    products: Product[],
    category: string,
    subcategory: string = null,
): Product[] => {
    return products.filter(product => {
        if (category && category !== product.category) {
            return false;
        }
        if (subcategory && subcategory !== product.subCategory) {
            return false;
        }

        return true;
    });
};

export const filterProductsForPackage = (
    funeralPackage: Package,
    allProducts: Product[],
    category: string,
    subcategory: string,
    timing: Timing,
    forceShowAllProducts?: boolean,
): Product[] => {
    const filterFunc = (p: Product) =>
        !p.isHiddenFromConsumer &&
        (p.subCategory === subcategory || (!subcategory && p.category === category)) &&
        matchPackageAttributes<Disposition>(p.dispositionTypes, funeralPackage.disposition) &&
        matchPackageAttributes<Timing>(p.timingTypes, timing);

    let filteredProducts: Product[] = [];
    if (!forceShowAllProducts && funeralPackage.packageSelectionType === PackageSelectionType.Limited) {
        filteredProducts = funeralPackage.packageProducts.filter(filterFunc);
    } else {
        filteredProducts = allProducts.filter(filterFunc);
    }

    return filteredProducts.sort(sortByTagAndUnitPrice);
};

export const convertPickupLocationToProduct = (pickupLocation: PickupLocation, mileageCharge: number): Product => {
    const mileageProduct: Product = {
        ...initialProduct,
        id: 'mileage',
        name: 'Mileage Fee',
        category: productCategories.Transportation,
        subCategory: 'Mileage Fee',
        price: mileageCharge,
        quantity: Math.round(pickupLocation.distanceBeyondServiceArea),
        lineItemTotal: pickupLocation.mileageCost,
    };
    return mileageProduct;
};

export const getProductsForCart = (
    selectedProducts: Product[],
    selectedTravelProducts: Product[],
    pickupLocation: PickupLocation,
    mileageCharge: number,
): ProductGroup[] => {
    const products = [...selectedProducts];
    // if there is a mileage cost, add it to the transportation category
    // this is a display product only and does not get saved to salesforce
    if (pickupLocation?.mileageCost) {
        const mileageProduct: Product = {
            ...initialProduct,
            id: 'mileage',
            name: 'Mileage Fee',
            category: productCategories.Transportation,
            subCategory: 'Mileage Fee',
            price: mileageCharge,
            quantity: Math.round(pickupLocation.distanceBeyondServiceArea),
            lineItemTotal: pickupLocation.mileageCost,
        };

        products.push(mileageProduct);
    }

    const groupedProducts = groupProductsByCategory(products);

    // add travel products if selected
    if (selectedTravelProducts?.length > 0) {
        groupedProducts.push({
            groupName: 'Travel Protection',
            products: selectedTravelProducts,
        });
    }

    return groupedProducts;
};

export const groupProductsByField = (products: Product[], field: string): ProductGroup[] => {
    // create a map of subcategories and products
    const mappedGroup = products.reduce((previousValue: Map<string, Product[]>, currentValue: Product) => {
        if (previousValue.has(currentValue[field])) {
            previousValue.get(currentValue[field]).push(currentValue);
        } else {
            previousValue.set(currentValue[field], [currentValue]);
        }
        return previousValue;
    }, new Map<string, Product[]>());

    // turn the Map into a ProductGroup array
    const groupedProducts: ProductGroup[] = Array.from(mappedGroup, ([name, value]) => ({
        groupName: name,
        products: value,
    }));
    return groupedProducts;
};

export const groupProductsByCategory = (products: Product[]): ProductGroup[] => {
    const groupedProducts = groupProductsByField(products, 'category');

    const categoryOrdered = productCategoryOrder.map(x => x.category);
    const orderedGroups = groupedProducts.sort((x, y) => sortBySortingArray(x.groupName, y.groupName, categoryOrdered));

    orderedGroups.forEach(group => {
        const subcategoryOrder = productCategoryOrder.find(x => x.category === group.groupName);
        group.products = group.products.sort((x, y) =>
            sortBySortingArray(x.subCategory, y.subCategory, subcategoryOrder.subCategories),
        );
    });

    return orderedGroups;
};

export const groupProductsBySubcategory = (products: Product[], category: string): ProductGroup[] => {
    const groupedProducts = groupProductsByField(products, 'subCategory');

    // get our ordered list of subcategories
    let subCategoryOrdered = [];
    const categoryOrdered = productCategoryOrder.find(x => x.category === category);
    if (categoryOrdered) {
        subCategoryOrdered = categoryOrdered.subCategories;
    }

    // sort the product groups by subcategory using the ordered list of subcategories
    return groupedProducts.sort((x, y) => sortBySortingArray(x.groupName, y.groupName, subCategoryOrdered));
};

export const packageHasProductsWithSubcategory = (
    state: RootState,
    subcategory: 'Casket' | 'Urn' | 'Outer Burial Container',
): boolean => {
    const {
        selectedPackage,
        availableProducts,
        opportunity: { timing },
    } = state;
    if (!state.selectedPackage) {
        return false;
    }
    return filterProductsForPackage(selectedPackage, availableProducts, null, subcategory, timing).length > 0;
};

export const productsAreTheSame = (first: Product, second: Product): boolean => {
    return (
        (!first.includedInPackage || !second.includedInPackage) &&
        first.id === second.id &&
        first.packageProduct === second.packageProduct &&
        first.questionId === second.questionId
    );
};

const sortByTagAndUnitPrice = (a: Product, b: Product): number => {
    const tagSort = sortByStrings(a.tag, b.tag, true, false);

    if (tagSort) {
        return tagSort;
    }

    return sortByNumbers(a.price, b.price, false);
};
