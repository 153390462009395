import { setAccount } from 'redux/account/root';
import { RootState } from 'redux/root';
import urlPaths from 'static/constants/enums/urlPaths';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import { getOpportunityIdLocalStorage } from 'toolboxes/reuseable-logic/local-storage-getters-setters';
import { getAccountByOpportunityId } from 'toolboxes/server-application-buffer/account-data';

export default function ReturningOpportunityEnterFunctions(state: RootState, dispatch: Function): BlockingFunction[] {
    const functionsToRun: BlockingFunction[] = [];

    functionsToRun.push({
        promiseGenerator: () =>
            new Promise<void>(async (resolve, reject) => {
                try {
                    const response = await getAccountByOpportunityId(getOpportunityIdLocalStorage(state));
                    dispatch(setAccount(response));
                    return resolve();
                } catch {
                    return reject();
                }
            }),
        blocksPageStarts: [urlPaths.returningOpportunity],
    });

    return functionsToRun;
}
