import React, { SVGProps } from 'react';

import { ReactComponent as Icon } from './ShowMoreModal.svg';

const ShowMoreModalIconComponent: React.FunctionComponent<PropShape> = ({ size, ...otherProps }) => {
    return <Icon {...otherProps} height={size} width={size} fill="currentColor" title="guaranteed" />;
};

interface PropShape extends SVGProps<SVGSVGElement> {
    size?: number;
}

ShowMoreModalIconComponent.defaultProps = {
    size: 24,
};

export default ShowMoreModalIconComponent;
