import React, { useState } from 'react';

import { makeStyles, Typography, Grid, CardMedia } from '@material-ui/core';
import { sanitize } from 'dompurify';
import { useSelectedPackage } from 'hooks/product-package-questions/useSelectedPackage';
import { useSelectedProducts } from 'hooks/product-package-questions/useSelectedProducts';
import { useSelectedTravelPackage } from 'hooks/travel/useSelectedTravelPackage';
import { usePickupLocation } from 'hooks/usePickupLocation';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { getProductsForCart } from 'toolboxes/reuseable-logic/products/productFunctions';
import { formatCurrency } from 'toolboxes/reuseable-logic/string-formatters';

import ShowMoreButtonComponent from '../layout-components/button-components/show-more-button-component/ShowMoreButtonComponent';
import ContentOverflowComponent from '../layout-components/ui-components/content-overflow-component/ContentOverflowComponent';
import ReceiptCartLineItemsComponent from './ReceiptCartLineItemsComponent';

const useStyles = makeStyles(theme => ({
    cardDetails: {
        backgroundColor: '#efefef',
        padding: 5,
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        textAlign: 'left',
    },
    cardMedia: {
        width: '100%',
        alignSelf: 'center',
    },
    cardMediaImage: {
        maxHeight: 127,
        objectFit: 'contain',
    },
    cardHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 10,
        color: '#000000',
        paddingTop: 5,
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    price: {
        fontWeight: 'bold',
        paddingBottom: 10,
    },
    cardActions: {
        padding: '1rem',
        justifyContent: 'flex-end',
        [theme.breakpoints.down('sm')]: {
            justifyContent: 'center',
        },
    },
    addButton: {
        width: 200,
    },
    paddingTop: {
        paddingTop: 10,
        width: '100%',
    },
    paddingBottom: {
        paddingBottom: 10,
    },
    textAlignLeft: {
        textAlign: 'left',
    },
    topLine: {
        borderTopWidth: 1,
        borderTopColor: theme.palette.primary.main,
        borderTopStyle: 'solid',
    },
    fontSizeSm: {
        fonstSize: '0.875rem',
    },
    fonstSizeMd: {
        fontSize: '1rem',
    },
}));

const ReceiptCartComponent: React.FunctionComponent = () => {
    const classes = useStyles();
    const [hasHiddenContent, setHasHiddenContent] = useState(false);
    const [isDescriptionCollapsed, setIsDescriptionCollapsed] = useState(true);
    const { selectedProducts } = useSelectedProducts();
    const { selectedPackage } = useSelectedPackage();
    const { selectedTravelPackage } = useSelectedTravelPackage();
    const { pickupLocation } = usePickupLocation();
    const { selectedAccount } = useSelectedAccount();

    const groupedProducts = getProductsForCart(
        selectedProducts,
        selectedTravelPackage.products,
        pickupLocation,
        selectedAccount.mileageCharge,
    );

    return (
        <Grid container direction="column" spacing={2} className={classes.topLine}>
            <div className={classes.paddingTop}>
                {selectedPackage.id && selectedPackage.imageURL && (
                    <Grid item className={classes.cardMedia}>
                        <CardMedia
                            id="cart-package-image"
                            image={selectedPackage.imageURL}
                            alt={`image of ${selectedPackage.name}`}
                            className={classes.cardMediaImage}
                            component="img"
                        />
                    </Grid>
                )}
                {selectedPackage.id && (
                    <Grid item className={classes.cardDetails}>
                        <Typography variant="h3" component="h3" className={classes.fonstSizeMd}>
                            <span id="cart-package-name" className={classes.cardHeader}>
                                {selectedPackage.name}
                            </span>
                        </Typography>
                        <Typography
                            id="cart-package-price"
                            className={`${classes.price} ${classes.fonstSizeMd}`}
                            variant="h3"
                            color="primary"
                        >
                            {formatCurrency(selectedPackage.price)}
                        </Typography>

                        <div className={classes.paddingBottom}>
                            <ContentOverflowComponent
                                maxHeight={100}
                                minHeight={0}
                                fadeHeight={64}
                                fadeInColor={'#efefef'}
                                collapsed={isDescriptionCollapsed}
                                onHasHiddenContent={hasHidden => setHasHiddenContent(hasHidden)}
                            >
                                <Typography
                                    id="cart=package-description"
                                    variant="body2"
                                    dangerouslySetInnerHTML={{
                                        __html: sanitize(
                                            selectedPackage.description
                                                .trim()
                                                .replace(/>\s+</g, '><')
                                                .replace(/\s+</g, '<')
                                                .replace(/>s+/g, '>'),
                                        ),
                                    }}
                                />
                            </ContentOverflowComponent>
                            {hasHiddenContent && (
                                <ShowMoreButtonComponent
                                    id="show-more"
                                    showMore={isDescriptionCollapsed}
                                    onClick={() => setIsDescriptionCollapsed(!isDescriptionCollapsed)}
                                />
                            )}
                        </div>
                    </Grid>
                )}
            </div>
            <div>
                <Grid item>
                    <span id="cart-line-item-summary" className={classes.textAlignLeft}>
                        {groupedProducts.map((x, i) => (
                            <ReceiptCartLineItemsComponent key={i} productGroup={x} />
                        ))}
                    </span>
                </Grid>
            </div>
        </Grid>
    );
};

export default ReceiptCartComponent;
