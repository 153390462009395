import React from 'react';

import { makeStyles, Grid } from '@material-ui/core';

import ReceiptCartComponent from './ReceiptCartComponent';
import ReceiptOrderSummaryComponent from './ReceiptOrderSummaryComponent';

const useStyles = makeStyles(() => ({
    paddingBottom: {
        paddingBottom: '50px',
    },
    paddingTop: {
        paddingTop: '10px',
    },
}));

const ReceiptComponent: React.FunctionComponent<PropShape> = props => {
    const classes = useStyles();

    return (
        <Grid>
            {props.orderSummary ? (
                <div className={classes.paddingBottom}>
                    <ReceiptOrderSummaryComponent />
                </div>
            ) : (
                <div className={`${classes.paddingBottom} ${classes.paddingTop}`}>
                    <ReceiptCartComponent />
                </div>
            )}
        </Grid>
    );
};

interface PropShape {
    orderSummary?: boolean;
}

ReceiptComponent.defaultProps = {
    orderSummary: false,
};

export default ReceiptComponent;
