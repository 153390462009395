import React from 'react';

import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import ProductQuestionSelectionComponent from 'components/global-components/product-question-selection-component/ProductQuestionSelectionComponent';

/*
On page exit
    Save question responses and selected products to opportunity
On page reroute
    Filter questions based on timing and disposition
    if no questions exists, go to Pickup Location Page
*/
const ProductQuestionsPageComponent: React.FunctionComponent<PropShape> = () => {
    return (
        <PageContentWrapperComponent
            title="Additional Required Information"
            subtitle={
                <>
                    Please answer the following questions. Once answered, click "Continue" and we will provide you with
                    a summary of your selections and allow you to make any necessary changes before the checkout
                    process.
                </>
            }
        >
            <ProductQuestionSelectionComponent />
        </PageContentWrapperComponent>
    );
};
interface PropShape {
    // Shape of passed in props
}
ProductQuestionsPageComponent.defaultProps = {
    // Set default values for props
};
export default ProductQuestionsPageComponent;
