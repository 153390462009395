import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TravelOpportunity } from 'static/models/TravelOpportunity';

const initialState: TravelOpportunity = {
    id: '',
    packageId: '',
};

export { initialState as initialTravelOpportunity };

const travelOpportunitySlice = createSlice({
    name: 'travelOpportunity',
    initialState,
    reducers: {
        setTravelOpportunity(state, action: PayloadAction<TravelOpportunity>) {
            return action.payload;
        },
    },
});

export const { setTravelOpportunity } = travelOpportunitySlice.actions;
export default travelOpportunitySlice.reducer;
