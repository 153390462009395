export const sortByStrings = (a: string, b: string, ascending = true, emptyFirst = true): number => {
    if (!emptyFirst) {
        if (!a && b) {
            return 1;
        }
        if (a && !b) {
            return -1;
        }
        if (!a && !b) {
            return 0;
        }
    }

    let stringCompare = a.localeCompare(b);

    if (!ascending) {
        stringCompare *= -1;
    }

    return stringCompare;
};

export const sortByNumbers = (a: number, b: number, ascending = true): number => {
    if (ascending) {
        return a - b;
    } else {
        return b - a;
    }
};

// sort an array using a reference sorting array for the order
export const sortBySortingArray = (a: string, b: string, sortingArray: string[]): number => {
    const aIndex = sortingArray.findIndex(x => x === a);
    const bIndex = sortingArray.findIndex(x => x === b);

    return aIndex - bIndex;
};
