import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Product from 'static/models/Product';
import { QuestionProducts } from 'static/models/QuestionProducts';
import { productsAreTheSame } from 'toolboxes/reuseable-logic/products/productFunctions';

const initialState: Product[] = [];

export { initialState as initialSelectedProducts };

const selectedProducts = createSlice({
    name: 'selectedProducts',
    initialState,
    reducers: {
        setSelectedProducts(state, action: PayloadAction<Product[]>) {
            return action.payload;
        },
        addSelectedProduct(state, action: PayloadAction<Product>) {
            const productToAdd: Product = action.payload;
            const otherProducts: Product[] = state.filter(p => !productsAreTheSame(productToAdd, p));
            const existingQuantity: number = state.find(p => productsAreTheSame(productToAdd, p))?.quantity;

            if (existingQuantity) {
                return [...otherProducts, { ...productToAdd, quantity: existingQuantity + 1 }];
            }
            return [...state, { ...productToAdd, quantity: 1 }];
        },
        removeSelectedProduct(state, action: PayloadAction<Product>) {
            const productToRemove = action.payload;
            const existingQuanitity = state.find(p => productsAreTheSame(productToRemove, p))?.quantity;

            if (!existingQuanitity) {
                console.error("Tried to remove a product that isn't selected"); // Fail gracefully
                return state;
            }

            const otherProducts = state.filter(p => !productsAreTheSame(productToRemove, p));

            if (existingQuanitity > 1) {
                return [...otherProducts, { ...productToRemove, quantity: existingQuanitity - 1 }];
            }
            return [...otherProducts];
        },
        updateQuestionProducts(state, action: PayloadAction<QuestionProducts>) {
            const nonQuestionProducts = state.filter(x => x.questionId !== action.payload.questionId);
            return [...nonQuestionProducts, ...action.payload.products];
        },
    },
});

export const {
    setSelectedProducts,
    addSelectedProduct,
    removeSelectedProduct,
    updateQuestionProducts,
} = selectedProducts.actions;
export default selectedProducts.reducer;
