import React from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import CartComponent from 'components/global-components/cart-component/CartComponent';
import GuaranteedLegendComponent from 'components/global-components/guaranteed-legend-component/GuaranteedLegendComponent';
import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import ReceiptComponent from 'components/global-components/receipt-component/ReceiptComponent';
import { useOpportunity } from 'hooks/useOpportunity';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { Timing } from 'static/models/enums/timing';

const useStyles = makeStyles(theme => ({
    cart: {
        display: 'flex',
        alignItems: 'top',
        justifyContent: 'flex-end',
        color: theme.palette.primary.main,
    },
    receiptWidth: {
        [theme.breakpoints.up('sm')]: {
            width: '60%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '95%',
        },
    },
    cartWdith: {
        [theme.breakpoints.up('sm')]: {
            width: '40%',
        },
        [theme.breakpoints.down('xs')]: {
            width: '95%',
        },
    },
    displayFlex: {
        display: 'flex',
        margin: 8,
    },
    paddingTop: {
        paddingTop: 10,
    },
}));

const OrderSummaryPageComponent: React.FunctionComponent = () => {
    const classes = useStyles();
    const { opportunity } = useOpportunity();
    const showGuaranteed = opportunity.timing !== Timing.IMMEDIATE;
    const { selectedAccount } = useSelectedAccount();

    const isTimingUnavailable =
        (opportunity.timing === Timing.IMMINENT && !selectedAccount.feature_ImminentSales) ||
        (opportunity.timing === Timing.FUTURE && !selectedAccount.feature_FutureSales);

    return (
        <PageContentWrapperComponent
            title="Review Your Selections"
            subtitle={
                <>
                    Below is a summary of the services you have selected.
                    {isTimingUnavailable ? '' : ' When ready, click “Continue.”'}
                    {showGuaranteed && (
                        <div className={classes.paddingTop}>
                            <GuaranteedLegendComponent />
                        </div>
                    )}
                </>
            }
        >
            <Grid container className={classes.displayFlex}>
                <Grid item className={classes.receiptWidth}>
                    <ReceiptComponent orderSummary={true} />
                </Grid>
                <Grid item className={`${classes.cart} ${classes.cartWdith}`}>
                    <CartComponent orderSummaryComponent={true} />
                </Grid>
            </Grid>
        </PageContentWrapperComponent>
    );
};

export default OrderSummaryPageComponent;
