import { connect } from 'react-redux';

import { RootState } from '../../../redux/root';
import LoadingScreenComponent from './LoadingScreenComponent';

const mapStateToProps: any = (_state: RootState) => {
    // These values will be passed in as props
    return {};
};

const mapDispatchToProps: any = (_dispatch: Function) => {
    // These functions will be passed in as props
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(LoadingScreenComponent);
