import { RootState } from 'redux/root';
import {
    updateProductQuestionResponses,
    updateProductsOnOpportunity,
} from 'toolboxes/server-application-buffer/opportunity-data';

import urlPaths from '../../../static/constants/enums/urlPaths';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';

export function productQuestions(state: RootState): BlockingFunction[] {
    return [
        {
            promiseGenerator: () => updateProductQuestionResponses(state),
            blocksPageStarts: [urlPaths.orderSummary],
        },
        {
            promiseGenerator: () => updateProductsOnOpportunity(state),
            blocksPageStarts: [urlPaths.orderSummary],
        },
    ];
}
