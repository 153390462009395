import { useSelector, useDispatch } from 'react-redux';
import { setLeadContact, updateLeadContact } from 'redux/leadContact/root';
import { LeadContact } from 'static/models/LeadContact';

import { RootState } from '../redux/root';

interface ReturnType {
    leadContact: LeadContact;
    setLeadContact: (leadContact: LeadContact) => void;
    updateLeadContact: (leadContact: LeadContact) => void;
}

export const useLeadContact = (): ReturnType => {
    const dispatch = useDispatch();

    return {
        leadContact: useSelector((state: RootState) => state.leadContact),
        setLeadContact: (leadContact: LeadContact) => dispatch(setLeadContact(leadContact)),
        updateLeadContact: (leadContact: LeadContact) => dispatch(updateLeadContact(leadContact)),
    };
};
