import axios from 'axios';

import getEnvironment from '../../env-config/get-environment';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const logUrlTracking = (opportunityId: string, params: any, location = window.location): void => {
    const baseIncomingUrl = location.protocol + '//' + location.host + location.pathname;
    const baseUrl = getEnvironment().efuneralGatewayBaseUrl;
    axios.post(
        `${baseUrl}/api/urlparametertracking`,
        {
            OpportunityId: opportunityId,
            URL: baseIncomingUrl,
            URLParams: params,
        },
        {
            headers: {
                'x-api-key': getEnvironment().efuneralGatewayKey,
                'Content-Type': 'application/json',
            },
        },
    );
};
