import React from 'react';

import { makeStyles } from '@material-ui/core';
import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import ProductGridListComponent from 'components/global-components/product-grid-component/ProductGridListComponent';
import { useSelectedPackage } from 'hooks/product-package-questions/useSelectedPackage';
import { useSelectedProducts } from 'hooks/product-package-questions/useSelectedProducts';
import { useFilteredProducts } from 'hooks/useFilteredProducts';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { getCountOfRequiredProducts } from 'toolboxes/reuseable-logic/package-functions';
import { getPackageText } from 'toolboxes/reuseable-logic/state-specific-language/package-language-change';

const useStyles = makeStyles({
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
});

const LimitedEnhancementsPageComponent: React.FunctionComponent = () => {
    const classes = useStyles();
    const products = useFilteredProducts('Additional Items', null);
    const { selectedPackage } = useSelectedPackage();
    const { selectedProducts } = useSelectedProducts();
    const { selectedAccount } = useSelectedAccount();

    const packageText = getPackageText(selectedAccount);

    const productCount = getCountOfRequiredProducts(selectedProducts);
    const productDiff = (selectedPackage.addonRequiredQuantity ?? 0) - productCount;

    const getAddOrRemoveText = (): string => {
        if (!selectedPackage.addonRequiredQuantity || productDiff === 0) {
            return '';
        }
        const absProductDiff = Math.abs(productDiff);
        const itemText = absProductDiff === 1 ? 'item' : 'items';
        const actionText = productDiff > 0 ? 'select' : 'remove';

        return `Please ${actionText} ${absProductDiff} additional ${itemText} to include in your arrangement as a part of this ${packageText.toLowerCase()}. `;
    };

    const title = (
        <div className={classes.titleContainer}>
            <div>Select Your Enhancements</div>
            {selectedPackage?.addonRequiredQuantity > 0 && (
                <div>
                    {productCount}/{selectedPackage.addonRequiredQuantity}
                </div>
            )}
        </div>
    );

    return (
        <PageContentWrapperComponent
            title={title}
            subtitle={
                <>
                    Below you will find additional goods & services to add to your arrangement. We offer a variety of
                    standard or customizable merchandise and service enhancements to choose from. {getAddOrRemoveText()}
                    Please make sure to denote the quantity where applicable. Once you’ve made your selections, click
                    "Continue."
                </>
            }
        >
            <ProductGridListComponent products={products} groupBySubcategory={true} multiselect enableQuantity />
        </PageContentWrapperComponent>
    );
};

export default LimitedEnhancementsPageComponent;
