import React from 'react';

import { sanitize } from 'dompurify';
import Package from 'static/models/Package';

const TravelProtectionCardSubheader: React.FunctionComponent<PropShape> = ({ travelPackage }) => {
    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: sanitize(travelPackage.description) }} />
            <div>Would you like to add travel protection at this time?</div>
        </>
    );
};

interface PropShape {
    travelPackage: Package;
}

export default TravelProtectionCardSubheader;
