import productServiceClient from '../apis/productServiceClient';
import { sendRequest } from '../web-request-tools';

// Product Requests Below
export function fetchAllProducts(accountId: string): Promise<any> {
    return sendRequest(productServiceClient.post(`/account/${accountId}/all_products`));
}

// Package Requests Below
export function fetchAllPackages(accountId: string): Promise<any> {
    return sendRequest(productServiceClient.get(`/packages/account/${accountId}`));
}

// Product Question Requests Below
export function fetchAllProductQuestions(accountId: string): Promise<any> {
    return sendRequest(productServiceClient.get(`/account/${accountId}/question_products`));
}
