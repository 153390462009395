interface ProductCategory {
    category: string;
    subCategories: string[];
}

export const productCategoryOrder: ProductCategory[] = [
    {
        category: 'Professional Services',
        subCategories: ['Staff', 'Facilities'],
    },
    {
        category: 'Transportation',
        subCategories: ['Transfer', 'Procession'],
    },
    {
        category: 'Merchandise',
        subCategories: ['Cakset', 'Urn', 'Outer Burial Container'],
    },
    {
        category: 'Additional Items',
        subCategories: ['Personalizations', 'Tributes', 'Flowers', 'Keepsakes', 'Jewelry'],
    },
];
