import { MembershipSignUpFormModel, MembershipVerificationFormModel } from '@efuneral/memberships';
import { useSelector, useDispatch } from 'react-redux';
import {
    setMembershipSignUpValid,
    setMembershipVerificationValid,
    setMembershipSignUpContact,
    setMembershipVerificationContact,
} from 'redux/membership/root';
import Membership from 'static/models/Membership';

import { RootState } from '../redux/root';

interface ReturnType {
    membership: Membership;
    isMembershipVerificationValid: boolean;
    isMembershipSignUpValid: boolean;
    membershipSignUpContact: MembershipSignUpFormModel;
    membershipVerificationContact: MembershipVerificationFormModel;
    setMembershipSignUpValid: (isValid: boolean) => void;
    setMembershipSignUpContact: (membershipContact: MembershipSignUpFormModel) => void;
    setMembershipVerificationValid: (isValid: boolean) => void;
    setMembershipVerificationContact: (membershipContact: MembershipVerificationFormModel) => void;
}

export const useMembership = (): ReturnType => {
    const dispatch = useDispatch();

    return {
        membership: useSelector((state: RootState) => state.membership),
        isMembershipVerificationValid: useSelector((state: RootState) => state.membership.membershipVerificationValid),
        isMembershipSignUpValid: useSelector((state: RootState) => state.membership.membershipSignUpValid),
        membershipSignUpContact: useSelector((state: RootState) => state.membership.membershipSignUpContact),
        membershipVerificationContact: useSelector(
            (state: RootState) => state.membership.membershipVerificationContact,
        ),
        setMembershipSignUpValid: (isValid: boolean) => dispatch(setMembershipSignUpValid(isValid)),
        setMembershipVerificationValid: (isValid: boolean) => dispatch(setMembershipVerificationValid(isValid)),
        setMembershipSignUpContact: (membershipContact: MembershipSignUpFormModel) =>
            dispatch(setMembershipSignUpContact(membershipContact)),
        setMembershipVerificationContact: (membershipContact: MembershipVerificationFormModel) =>
            dispatch(setMembershipVerificationContact(membershipContact)),
    };
};
