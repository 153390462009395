import { RoutingNode } from 'hooks/useRouteCalculator/routing-graph';
import { RootState } from 'redux/root';
import { filterProductQuestions } from 'toolboxes/reuseable-logic/product-question-functions';

const productQuestionsPageRouting: RoutingNode = {
    forwardEdges: [],
    backwardEdges: [],
    forAllForwardEdges: (state: RootState) => {
        const relevantQuestions = filterProductQuestions(state.availableProductQuestions, state.opportunity);
        return state.productQuestionResponses.length === relevantQuestions.length;
    },
};

export default productQuestionsPageRouting;
