import React from 'react';

import LoadingComponent from 'components/global-components/layout-components/ui-components/loading-component/LoadingComponent';

const SendingFailingRequestComponent: React.FunctionComponent = () => {
    /*
    on the page enter functions
        Sends a bad request to the backend. Backend will throw back a 500 code which will
            cause the rounting component to pick up that it's an error and redirect to error page.
    */
    return <LoadingComponent show={true} />;
};

export default SendingFailingRequestComponent;
