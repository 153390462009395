import React, { useState } from 'react';

import { makeStyles, Typography, CardContent } from '@material-ui/core';
import ButtonComponent from 'components/global-components/layout-components/button-components/button-component/ButtonComponent';
import ButtonOption from 'static/models/ButtonOption';

import StyledRadio from './StyledRadio';

const useStyles = makeStyles(theme => ({
    buttonTitleContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    card: {
        margin: 8,
        position: 'relative',
        overflow: 'visible',
        borderRadius: 0,
    },
    cardHeader: {
        paddingBottom: 0,
    },
    cardSubtitle: {
        paddingTop: '5px',
        paddingBottom: '10px',
    },
    cardBodyBold: {
        fontWeight: 'bold',
    },
    editButton: {
        float: 'right',
        paddingLeft: 10,
        paddingRight: 10,
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
            color: '#fff',
        },
    },
    multilineRadioLabel: {
        display: 'inline-block',
        cursor: 'pointer',
        justifyContent: 'flex-start',
        textAlign: 'left',
        marginBottom: '1rem',
        backgroundColor: '#F0F0F0',
        '&:hover': {
            backgroundColor: '#a8a8a8',
            color: '#fff',
        },
    },
}));

function CardButtonOptionsComponent<T>(props: PropShape<T>): React.ReactElement<PropShape<T>> {
    const classes = useStyles();
    const { buttonIdBase, onClick, options, showRadio } = props;
    const [radioChecked, setRadioChecked] = useState(Array(options.length).fill(false));

    const handleMouseEvent = (index: number, value: boolean) => {
        if (showRadio) {
            const checkedCopy = [...radioChecked];
            checkedCopy[index] = value;
            setRadioChecked(checkedCopy);
        }
    };

    return (
        <CardContent>
            {options?.map((option, i) => (
                <ButtonComponent
                    key={i}
                    id={`${buttonIdBase}` + `-` + `${option.title.toLowerCase()}`}
                    onClick={() => onClick(option.value)}
                    fullWidth
                    disableElevation
                    className={classes.multilineRadioLabel}
                    onMouseEnter={() => handleMouseEvent(i, true)}
                    onMouseLeave={() => handleMouseEvent(i, false)}
                >
                    <div className={classes.buttonTitleContainer}>
                        {showRadio && <StyledRadio checked={radioChecked[i]} />}
                        <Typography variant="body2" className={classes.cardBodyBold}>
                            {option.title}
                        </Typography>
                    </div>
                    {option.subtitle ? <Typography variant="body2">{option.subtitle}</Typography> : ''}
                </ButtonComponent>
            ))}
        </CardContent>
    );
}

interface PropShape<T> {
    // Shape of passed in props
    buttonIdBase: string;
    showRadio?: boolean;
    onClick: (opt: T) => void;
    options: ButtonOption<T>[];
}

CardButtonOptionsComponent.defaultProps = {
    // Set default values for props
    buttonIdBase: '',
    showRadio: true,
    onClick: () => {},
};

export default CardButtonOptionsComponent;
