import { RoutingNode } from 'hooks/useRouteCalculator/routing-graph';

const travelProtectionPageRouting: RoutingNode = {
    forwardEdges: [],
    backwardEdges: [],
    forAllForwardEdges: state =>
        state.selectedTravelPackage?.declinedTravelProtection || state.selectedTravelPackage?.package.id.length > 0,
};

export default travelProtectionPageRouting;
