import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Disposition } from 'static/models/enums/disposition';

import { OpportunityStage } from '../../static/models/enums/opportunityStages';
import { PreneedContractType } from '../../static/models/enums/preneedContractType';
import Opportunity from '../../static/models/Opportunity';

const initialState: Opportunity = {
    opportunityId: '',
    stageName: OpportunityStage.Visitor,
    timing: null,
    disposition: null,
    preneedContractType: PreneedContractType.NotApplicable,
    stopPage: false,
    stopPageDetail: '',
    returnToReact: false,
    closeDate: '',
    closedToUser: false,
    ceremony: '',
    visitation: '',
    productSelectionLocked: false,
    parsedCustomerPath: [],
    hidePriceListDisclaimer: false,
    customerPathObject: [],
    leadId: null,
};

export { initialState as initialOpportunity };

const opportunitySlice = createSlice({
    name: 'opportunity',
    initialState,
    reducers: {
        setOpportunity(state, action: PayloadAction<Opportunity>) {
            return action.payload;
        },
        setDisposition(state, action: PayloadAction<Disposition>) {
            return { ...state, disposition: action.payload };
        },
        setHidePriceListDisclaimer(state, action: PayloadAction<boolean>) {
            state.hidePriceListDisclaimer = action.payload;
        },
        setOpportunityLeadId(state, action: PayloadAction<string>) {
            return { ...state, leadId: action.payload };
        },
    },
});

export const { setDisposition, setHidePriceListDisclaimer, setOpportunity, setOpportunityLeadId } =
    opportunitySlice.actions;
export default opportunitySlice.reducer;
