import React from 'react';

import { Redirect } from 'react-router';
import urlPaths from 'static/constants/enums/urlPaths';

const LoadOpportunityPageComponent: React.FunctionComponent<PropShape> = () => {
    /*
    On Reroute
    Check local storage and the state for and opportunity id. If no opportunity id send to 
    error page.

    On the page enter function 
    send the request to the endpoint to load the opportunity info.
    
    On the page exit function
    send the request to the endpoint to load the account from the opportunity.
    send the request to the endpoint to load the travel opportunity.
    send the request to the endpoint to load the membership opportunity, maybe down the road?
    */

    return <Redirect to={urlPaths.initialLoadAndCreate} />;
};
interface PropShape {
    // Shape of passed in props
}
LoadOpportunityPageComponent.defaultProps = {
    // Set default values for props
};
export default LoadOpportunityPageComponent;
