import React from 'react';

import { getCPRADisclosureUrl } from 'toolboxes/reuseable-logic/pricelist';

import ExternalLinkComponent from '../external-link-component/ExternalLinkComponent';

const CPRADisclosureLinkComponent: React.FunctionComponent<PropShape> = ({ children, className }) => {
    return (
        <ExternalLinkComponent id="cpradisclosure" href={getCPRADisclosureUrl()} className={className}>
            {children}
        </ExternalLinkComponent>
    );
};

interface PropShape {
    className?: string;
}

CPRADisclosureLinkComponent.defaultProps = {
    className: '',
};

export default CPRADisclosureLinkComponent;
