import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import DemoFormData from 'static/models/demo/demoFormData';

const initialState: DemoFormData = {
    title: '',
    firstName: '',
    lastName: '',
    phone: '',
    email: '',
    website: '',
    company: '',
    callsPerYear: '',
};

export { initialState as initialDemoFormData };

const DemoFormDataSlice = createSlice({
    name: 'demoFormData',
    initialState,
    reducers: {
        setDemoFormData(state, action: PayloadAction<DemoFormData>) {
            return action.payload;
        },
        updateDemoFormData(state, action: PayloadAction<DemoFormData>) {
            return { ...state, ...action.payload };
        },
    },
});

export const { setDemoFormData, updateDemoFormData } = DemoFormDataSlice.actions;
export default DemoFormDataSlice.reducer;
