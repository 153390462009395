import { useSelector, useDispatch } from 'react-redux';
import { setPickupLocation, updatePickupLocation } from 'redux/pickupLocation/root';
import { PickupLocation } from 'static/models/PickupLocation';

import { RootState } from '../redux/root';

interface ReturnType {
    pickupLocation: PickupLocation;
    setPickupLocation: (pickupLocation: PickupLocation) => void;
    updatePickupLocation: (pickupLocation: PickupLocation) => void;
}

export const usePickupLocation = (): ReturnType => {
    const dispatch = useDispatch();

    return {
        pickupLocation: useSelector((state: RootState) => state.pickupLocation),
        setPickupLocation: (pickupLocation: PickupLocation) => dispatch(setPickupLocation(pickupLocation)),
        updatePickupLocation: (pickupLocation: PickupLocation) => dispatch(updatePickupLocation(pickupLocation)),
    };
};
