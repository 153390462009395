import React from 'react';

import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { RootState } from 'redux/root';
import { jumpToPageCalculator } from 'toolboxes/reuseable-logic/jump-to-page-calculator';

const InitialLoadAndCreateComponent: React.FunctionComponent = () => {
    /*
    on the page enter functions
        If no opportunityId in Redux create an opportunity
        Also need to pull in accounts packages, products, product questions, and travel provider and products
    */
    const state = useSelector((state: RootState) => state);
    const destination = jumpToPageCalculator(state);
    return <Redirect to={destination} />;
};

export default InitialLoadAndCreateComponent;
