import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Package from 'static/models/Package';

const initialState: Package[] = [];

export { initialState as initialAvailablePackages };

const availablePackagesSlice = createSlice({
    name: 'availablePackages',
    initialState,
    reducers: {
        setAvailablePackages(state, action: PayloadAction<Package[]>) {
            return action.payload;
        },
    },
});

export const { setAvailablePackages } = availablePackagesSlice.actions;
export default availablePackagesSlice.reducer;
