import { RootState } from 'redux/root';
import Package from 'static/models/Package';
import { TravelOpportunity } from 'static/models/TravelOpportunity';
import { TravelOpportunityResponse } from 'static/models/TravelOpportunityResponse';
import { TravelProviderAndPackages } from 'static/models/TravelProviderAndPackages';
import {
    fetchTravelProviderAndProducts,
    fetchTravelOpportunityFromOpportunityId,
    runDeleteTravelOpportunityFromOpportunityId,
    runCreateTravelOpportunityFromOpportunityId,
    runSaveTravelPackageToTravelOpportunity,
} from 'toolboxes/web-requests/specific-requests/travel-requests';

import { setAvailableTravelPackages } from '../../redux/travel-protection/availableTravelPackages/root';
import { setSelectedTravelPackage } from '../../redux/travel-protection/selectedTravelPackage/root';
import { initialTravelOpportunity, setTravelOpportunity } from '../../redux/travel-protection/travelOpportunity/root';
import { setTravelProvider } from '../../redux/travel-protection/travelProvider/root';
import { localSetAccount } from './account-data';

export async function getTravelProviderAndPackages(funeralHomeId: string): Promise<TravelProviderAndPackages> {
    return await fetchTravelProviderAndProducts(funeralHomeId);
}

export async function createTravelOpportunityFromOpportunityId(state: RootState): Promise<string> {
    const salesOpporutnity = state.opportunity.opportunityId;
    const traveProvider = state.travelProvider.id;
    const selectedProducts = state.selectedTravelPackage.package.products;
    return await runCreateTravelOpportunityFromOpportunityId(traveProvider, salesOpporutnity, selectedProducts);
}

export async function getTravelOpportunityFromSalesOpportunity(opportunityId: string): Promise<TravelOpportunity> {
    const response = await fetchTravelOpportunityFromOpportunityId(opportunityId);
    return mapResponseToTravelOpportunity(response);
}

export async function deleteTravelOpportunityFromOpportunityId(state: RootState): Promise<TravelOpportunity> {
    const travelOpportunityId = state.travelOpportunity.id;
    const salesOpportunityId = state.opportunity.opportunityId;

    const response = await runDeleteTravelOpportunityFromOpportunityId(travelOpportunityId, salesOpportunityId);
    return mapResponseToTravelOpportunity(response);
}

export async function getAndDispatchTravelData(state: RootState, dispatch: Function): Promise<void> {
    const account = await localSetAccount(state, dispatch);
    const response = await getTravelProviderAndPackages(account.id);
    dispatch(setTravelProvider(response.travelProvider));
    dispatch(setAvailableTravelPackages(response.packages));

    if (state.travelOpportunity.id && state.travelOpportunity.packageId) {
        const selectedTravelPackage = response.packages.find(x => x.id === state.travelOpportunity.packageId);

        if (selectedTravelPackage) {
            dispatch(setSelectedTravelPackage(selectedTravelPackage));
        } else {
            // entered here because the travel opporunity package loaded in did
            // not match any available package from the travel provider.
            await deleteTravelOpportunityFromOpportunityId(state);
            dispatch(setTravelOpportunity(initialTravelOpportunity));
        }
    }
    return;
}

export const saveTravelPackageToTravelOpportunity = async (
    opportunityId: string,
    travelPackage: Package,
): Promise<string> => await runSaveTravelPackageToTravelOpportunity(opportunityId, travelPackage);

const mapResponseToTravelOpportunity = (response: TravelOpportunityResponse): TravelOpportunity => ({
    id: response.Id,
    packageId: response.Package__c,
});
