import React from 'react';

import { Grid } from '@material-ui/core';

import TravelProtectionCards from './TravelProtectionCards';

const TravelPackageSelectionComponent: React.FunctionComponent = () => (
    <Grid container direction="column" spacing={2}>
        <TravelProtectionCards />
    </Grid>
);

export default TravelPackageSelectionComponent;
