import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import ErrorFormData from 'static/models/errorLogging/errorFormData';

const initialState: ErrorFormData = {
    name: '',
    phone: '',
    email: '',
    subject: '',
    details: '',
};

export { initialState as initialErrorFormData };

const ErrorFormDataSlice = createSlice({
    name: 'errorFormData',
    initialState,
    reducers: {
        setErrorFormData(state, action: PayloadAction<ErrorFormData>) {
            return action.payload;
        },
        updateErrorFormData(state, action: PayloadAction<ErrorFormData>) {
            return { ...state, ...action.payload };
        },
    },
});

export const { setErrorFormData, updateErrorFormData } = ErrorFormDataSlice.actions;
export default ErrorFormDataSlice.reducer;
