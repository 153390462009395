import { RootState } from 'redux/root';
import { setTravelOpportunity, initialTravelOpportunity } from 'redux/travel-protection/travelOpportunity/root';
import urlPaths from 'static/constants/enums/urlPaths';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import { updateProductsOnTravelOpportunity } from 'toolboxes/server-application-buffer/opportunity-data';
import {
    deleteTravelOpportunityFromOpportunityId,
    createTravelOpportunityFromOpportunityId,
    saveTravelPackageToTravelOpportunity,
} from 'toolboxes/server-application-buffer/travel-data';

export default function travelProtectionExitFunctions(state: RootState, dispatch: Function): BlockingFunction[] {
    const deleteTravelOpportunityFunction: BlockingFunction = {
        promiseGenerator: async () => {
            await deleteTravelOpportunityFromOpportunityId(state);
            dispatch(setTravelOpportunity(initialTravelOpportunity));
        },
        blocksPageStarts: [urlPaths.orderSummary],
    };
    /*
        if the user declined travel protection, delete existing travel opp or do nothing
        if there is a travel opportunity but no selected products delete the travel opportunity
        if there is a travel opportunity and selected travel products call the update products on Travel Opportunity endpoint
        if there is no travel opporutnity and selected travel products call the creation endpoint
    */
    if (state.selectedTravelPackage.declinedTravelProtection) {
        if (state.travelOpportunity.id) {
            return [deleteTravelOpportunityFunction];
        }
        return [];
    }

    const selectedPackage = state.selectedTravelPackage.package;

    if (state.travelOpportunity.id && !selectedPackage.id) {
        // if there is a travel opportunity but no selected products delete the travel opportunity
        return [deleteTravelOpportunityFunction];
    }
    if (state.travelOpportunity.id && selectedPackage.id) {
        // if there is a travel opportunity and selected travel products call the update products on Travel Opportunity endpoint
        return [
            {
                promiseGenerator: async () => await updateProductsOnTravelOpportunity(state),
                blocksPageStarts: [urlPaths.orderSummary],
            },
        ];
    }
    if (!state.travelOpportunity.id && selectedPackage.id) {
        // if there is no travel opporutnity and selected travel products call the creation endpoint
        return [
            {
                promiseGenerator: async () => {
                    const response = await createTravelOpportunityFromOpportunityId(state);
                    await saveTravelPackageToTravelOpportunity(response, selectedPackage);
                    dispatch(setTravelOpportunity({ id: response, packageId: selectedPackage.id }));
                },
                blocksPageStarts: [urlPaths.orderSummary],
            },
        ];
    }

    return [];
}
