import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Account, { defaultAccount } from 'static/models/Account';

const initialState: Account = { ...defaultAccount };

export { initialState as initialTravelProvider };

const travelProviderSlice = createSlice({
    name: 'travelProvider',
    initialState,
    reducers: {
        setTravelProvider(state, action: PayloadAction<Account>) {
            return action.payload;
        },
    },
});

export const { setTravelProvider } = travelProviderSlice.actions;
export default travelProviderSlice.reducer;
