import React from 'react';

import { Grid, makeStyles, Typography } from '@material-ui/core';

import ExpansionButton from './ExpansionButton';
import { DescriptionOverflowType } from './types';

const useStyles = makeStyles(theme => ({
    actionRow: {
        alignItems: 'center',
        justifyContent: 'space-between',
        fontSize: '1em',
    },
    showMoreLink: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    showMoreIcon: {
        fontSize: 'inherit',
    },
    price: {
        fontSize: '1em',
        fontWeight: 'bold',
    },
    actionBarContainer: {
        padding: theme.spacing(0.5),
    },
    actionChildren: {
        width: 110,
    },
    expansionButtonSpacer: {
        height: theme.spacing(3),
    },
}));

const ActionBar: React.FunctionComponent<PropShape> = props => {
    const classes = useStyles();
    const { children, formattedPrice, descriptionOverflow, hasOverflow, ...expansionProps } = props;
    return (
        <Grid container direction="column" spacing={2} className={classes.actionBarContainer}>
            <Grid item xs={12}>
                {descriptionOverflow !== 'scroll' && hasOverflow ? (
                    <ExpansionButton descriptionOverflow={descriptionOverflow} {...expansionProps} />
                ) : (
                    <div className={classes.expansionButtonSpacer} />
                )}
            </Grid>
            <Grid item xs={12}>
                <Grid container direction="row" className={classes.actionRow}>
                    <Grid item>
                        <Typography className={classes.price} component="h6" color="primary">
                            {formattedPrice}
                        </Typography>
                    </Grid>
                    <Grid item className={classes.actionChildren}>
                        {children}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

interface PropShape {
    descriptionOverflow: DescriptionOverflowType;
    isExpanded: boolean;
    formattedPrice: string;
    hasOverflow: boolean;
    onOpen: () => void;
    onExpand: () => void;
    onCollapse: () => void;
}

export default ActionBar;
