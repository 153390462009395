import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState = {
    loadParams: null,
};

export { initialState as initialParams };

const initialParamsSlice = createSlice({
    name: 'initalParams',
    initialState,
    reducers: {
        setInitialParams(state, action: PayloadAction<any>) {
            return { loadParams: action.payload };
        },
    },
});

export const { setInitialParams } = initialParamsSlice.actions;
export default initialParamsSlice.reducer;
