import { RoutingNode } from 'hooks/useRouteCalculator/routing-graph';
import { RootState } from 'redux/root';
import { getCountOfRequiredProducts } from 'toolboxes/reuseable-logic/package-functions';

const limitedEnhancementsPageRouting: RoutingNode = {
    forwardEdges: [],
    backwardEdges: [],
    forAllForwardEdges: (state: RootState) => {
        const quantitySelected = getCountOfRequiredProducts(state.selectedProducts);
        return (
            !state.selectedPackage.addonRequiredQuantity ||
            quantitySelected === state.selectedPackage.addonRequiredQuantity
        );
    },
};

export default limitedEnhancementsPageRouting;
