import React, { useState } from 'react';

import { Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import SelectComponent from 'components/form-components/SelectComponent.ts/SelectComponent';
import TextBoxComponent from 'components/form-components/text-box-component/TextBoxComponent';
import { useForm } from 'hooks/forms/useForm';
import { pickupLocationValidator } from 'hooks/forms/validators';
import { useOpportunity } from 'hooks/useOpportunity';
import { usePickupLocation } from 'hooks/usePickupLocation';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { pickupLocationTypeOptions } from 'static/constants/pickupLocationOptions';
import { GeographicState } from 'static/models/enums/states';
import { AutocompletedLocation } from 'static/models/LocationAutocomplete';
import { PickupLocation } from 'static/models/PickupLocation';
import ValueDisplay from 'static/models/ValueDisplay';
import { convertPlaceDetailsToPickupLocation } from 'toolboxes/reuseable-logic/location-functions';
import { formatCurrency, formatPhoneNumber } from 'toolboxes/reuseable-logic/string-formatters';
import { valueDisplaysFromEnum } from 'toolboxes/reuseable-logic/value-displays';
import { getPlaceDetails, getServiceAreaData } from 'toolboxes/server-application-buffer/location-data';

import LoadingComponent from '../layout-components/ui-components/loading-component/LoadingComponent';
import LocationSearchComponent from '../location-search-component/LocationSearchComponent';

/*
    Page flow:
    handleOnLocationChange() - When a place is selected (using LocationSearchComponent)
    if: place_id is null
        setPlaceNotListed(true)
        end
    else:
        get place details
        calculate distance and cost
        check if valid location

*/
const PickupLocationSelectionComponent: React.FunctionComponent = () => {
    const { selectedAccount } = useSelectedAccount();
    const { opportunity } = useOpportunity();
    const { pickupLocation, updatePickupLocation } = usePickupLocation();
    const { formData, handleOnChange, handleOnBlur } = useForm<PickupLocation>(
        pickupLocation,
        updatePickupLocation,
        pickupLocationValidator,
    );
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [hasSearched, setHasSearched] = useState<boolean>(false);
    const [placeNotListed, setPlaceNotListed] = useState<boolean>(false);
    const [hasError, setHasError] = useState<boolean>(false);

    const getPickupLocationOptions = (): ValueDisplay[] => {
        return pickupLocationTypeOptions.map(option => {
            return {
                value: option,
                display: option,
            };
        });
    };

    const handleOnLocationChange = async (location: AutocompletedLocation) => {
        setHasSearched(true);
        setHasError(false);

        if (location.place_id) {
            setIsSearching(true);
            // reset place not listed incase it was previously selected
            setPlaceNotListed(false);

            try {
                const detailsResponse = await getPlaceDetails(
                    location.place_id,
                    location.description,
                    opportunity.opportunityId,
                );
                const pickupLocation = convertPlaceDetailsToPickupLocation(detailsResponse);
                const serviceArea = await getServiceAreaData(location.place_id, pickupLocation, selectedAccount);

                pickupLocation.pickupLocationDescription = location.description;
                pickupLocation.mileageDistance = serviceArea.distance;
                pickupLocation.distanceBeyondServiceArea = serviceArea.distanceBeyondServiceArea;
                pickupLocation.mileageCost =
                    selectedAccount.mileageCharge * Math.round(serviceArea.distanceBeyondServiceArea);

                updatePickupLocation({
                    ...pickupLocation,
                    isWithinServiceRadius: serviceArea.withinMaxServiceArea,
                    isEnteredManually: false,
                });

                // set hasError if we got an error or the address is missing a street address
                if (!!serviceArea.errorMessage || serviceArea.distanceBeyondServiceArea === null) {
                    setHasError(true);
                }
            } catch {
                setHasError(true);
            }

            setIsSearching(false);
        } else {
            // if placeNotListed is true
            // we need to set isWithinServiceRadius to true in order to pass validation
            setPlaceNotListed(true);

            updatePickupLocation({
                pickupLocationStreet: null,
                pickupLocationCity: null,
                pickupLocationCounty: null,
                pickupLocationState: null,
                pickupLocationZip: null,
                pickupLocationDescription: location.description,
                mileageDistance: null,
                mileageCost: null,
                isWithinServiceRadius: true,
                isEnteredManually: true,
            });
        }
    };

    const handlePickupLocationTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const newPickupLocation: PickupLocation = { ...pickupLocation, pickupLocationType: e.target.value };
        updatePickupLocation(newPickupLocation);
    };

    const handleOnClear = (cleared: boolean) => {
        if (cleared) {
            const pickupLocation: PickupLocation = {
                pickupLocationStreet: null,
                pickupLocationCity: null,
                pickupLocationCounty: null,
                pickupLocationState: null,
                pickupLocationZip: null,
                pickupLocationDescription: null,
                mileageDistance: null,
                mileageCost: null,
                isWithinServiceRadius: false,
            };
            updatePickupLocation(pickupLocation);
            setPlaceNotListed(false);
        }
    };

    const renderErrorMessage = () => {
        if (isSearching) {
            return null;
        }

        if (hasError) {
            return (
                <Alert severity="error">
                    Sorry, we were unable to calculate your mileage. Please verify the address you have entered is
                    correct. If our system still cannot locate your address, please contact us at{' '}
                    {formatPhoneNumber(selectedAccount.phoneNumber)} to make arrangements over the phone.
                </Alert>
            );
        }

        if (placeNotListed) {
            return (
                <Alert severity="warning">
                    Since we can not verify your address, please note that if this location is outside of our facility's
                    service area, a fee of {formatCurrency(selectedAccount?.mileageCharge, true)} per mile will be
                    assessed.
                </Alert>
            );
        }
        return (
            <>
                {hasSearched && !pickupLocation.isWithinServiceRadius && (
                    <Alert severity="error">
                        The address provided is outside of the online service area for {selectedAccount?.name}. One of
                        our team members may be able to provide you with better options. Please call
                        {formatPhoneNumber(selectedAccount?.phoneNumber)} to speak with a member of our team.
                    </Alert>
                )}
                {pickupLocation.distanceBeyondServiceArea > 0 && pickupLocation.isWithinServiceRadius && (
                    <Alert severity="warning">
                        The address entered is {pickupLocation.distanceBeyondServiceArea} miles outside of our standard
                        service range. With a {formatCurrency(selectedAccount?.mileageCharge, true)} per mile charge,{' '}
                        {formatCurrency(pickupLocation.mileageCost, true)} will be added to the total.
                    </Alert>
                )}
            </>
        );
    };

    return (
        <Grid container direction="column" spacing={2}>
            <Grid item>
                <LocationSearchComponent
                    onLocationChange={value => handleOnLocationChange(value)}
                    onClear={cleared => handleOnClear(cleared)}
                    value={pickupLocation.pickupLocationDescription}
                />
            </Grid>
            {placeNotListed && (
                <Grid container item direction="column" spacing={2}>
                    <Grid item>
                        <TextBoxComponent
                            formName="pickupLocation"
                            fieldName={formData.pickupLocationStreet?.name}
                            labelString="Street Address"
                            value={formData.pickupLocationStreet?.value}
                            showError={formData.pickupLocationStreet?.touched}
                            errorMessage={formData.pickupLocationStreet?.error}
                            onChange={handleOnChange}
                            onBlur={handleOnBlur}
                        />
                    </Grid>
                    <Grid item>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextBoxComponent
                                    formName="pickupLocation"
                                    fieldName={formData.pickupLocationCity?.name}
                                    labelString="City"
                                    value={formData.pickupLocationCity?.value}
                                    showError={formData.pickupLocationCity?.touched}
                                    errorMessage={formData.pickupLocationCity?.error}
                                    onChange={handleOnChange}
                                    onBlur={handleOnBlur}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <SelectComponent
                                    formName="pickupLocation"
                                    fieldName={formData.pickupLocationState?.name}
                                    labelString="State"
                                    value={formData.pickupLocationState?.value}
                                    showError={formData.pickupLocationState?.touched}
                                    errorMessage={formData.pickupLocationState?.error}
                                    onChange={handleOnChange}
                                    onBlur={handleOnBlur}
                                    options={valueDisplaysFromEnum(GeographicState)}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}>
                                <TextBoxComponent
                                    formName="pickupLocation"
                                    fieldName={formData.pickupLocationZip?.name}
                                    labelString="Zip Code"
                                    value={formData.pickupLocationZip?.value}
                                    showError={formData.pickupLocationZip?.touched}
                                    errorMessage={formData.pickupLocationZip?.error}
                                    onChange={handleOnChange}
                                    onBlur={handleOnBlur}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            )}
            <Grid item>
                <SelectComponent
                    formName="pickupLocation"
                    fieldName="locationType"
                    labelString="This location is a..."
                    value={pickupLocation.pickupLocationType}
                    onChange={handlePickupLocationTypeChange}
                    options={getPickupLocationOptions()}
                />
            </Grid>
            <Grid item>{renderErrorMessage()}</Grid>
            <LoadingComponent show={isSearching} />
        </Grid>
    );
};

export default PickupLocationSelectionComponent;
