import { updateLeadContact } from 'redux/leadContact/root';
import { setOpportunityLeadId } from 'redux/opportunity/root';
import { RootState } from 'redux/root';
import { createLead } from 'toolboxes/server-application-buffer/lead-data';
import { updateOpportunity } from 'toolboxes/web-requests/specific-requests/opportunity-requests';

import urlPaths from '../../../static/constants/enums/urlPaths';
import BlockingFunction from '../../../static/models/blockingFunctions/BlockingFunction';

export function timingGate(state: RootState, dispatch: Function): BlockingFunction[] {
    return [
        {
            promiseGenerator: async () => {
                const lead = { ...state.leadContact, accountId: state.account.id };
                const leadId = await createLead(lead);
                const opportunity = { ...state.opportunity, leadId };
                await updateOpportunity(state.account.id, opportunity);

                dispatch(setOpportunityLeadId(leadId));
                dispatch(updateLeadContact({ ...lead, id: leadId }));
            },
            blocksPageStarts: [urlPaths.packages],
        },
    ];
}
