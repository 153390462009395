import { RoutingNode } from 'hooks/useRouteCalculator/routing-graph';

import { RootState } from '../../../redux/root';
import urlPaths from '../../../static/constants/enums/urlPaths';

const orderSummaryRouting: RoutingNode = {
    forwardEdges: [
        {
            destination: urlPaths.demoAppEndPageComponent,
            condition: (state: RootState): boolean => {
                return state.isDemoApp;
            },
        },
    ],
    backwardEdges: [],
};

export default orderSummaryRouting;
