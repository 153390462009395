import { getPackageDispositions } from 'toolboxes/reuseable-logic/package-functions';

import ButtonOption from './ButtonOption';
import { Disposition } from './enums/disposition';
import Package from './Package';

const dispositionOptions: ButtonOption<Disposition>[] = [
    {
        title: Disposition.Burial,
        value: Disposition.Burial,
        subtitle: 'Remains interred in a cemetery, mausoleum or crypt',
    },
    {
        title: Disposition.Cremation,
        value: Disposition.Cremation,
        subtitle: 'Remains are cremated and can be shared, scattered or buried',
    },
];

export function getAvailableDispositionOptions(availablePackages: Package[]): ButtonOption<Disposition>[] {
    const dispositions = getPackageDispositions(availablePackages);
    return dispositionOptions.filter(o => dispositions.includes(o.value));
}

export default dispositionOptions;
