import React from 'react';

import { makeStyles } from '@material-ui/core';
import PriceListLinkComponent from 'components/global-components/price-list-link-component/PriceListLinkComponent';

import CPRADisclosureLinkComponent from '../../global-components/ccpa-disclosure-link-component/CPRADisclosureLinkComponent';

const useStyles = makeStyles(theme => ({
    link: {
        textDecoration: 'none',
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
        },
    },
}));

const beforeText = 'Before we begin, we want to provide you with our ';
const linkText = 'Price List';
const cpraLinkText = 'CPRA Disclosure';
const afterText =
    ' for your reference. We have also included a link to our price list at the bottom of each page. When clicking these links, our offerings will open in a new tab where you can download a copy.';

const PriceListDisclaimerText: React.FunctionComponent = () => {
    const classes = useStyles();
    return (
        <div>
            {beforeText}
            <PriceListLinkComponent className={classes.link}>{linkText}</PriceListLinkComponent> and{' '}
            <CPRADisclosureLinkComponent className={classes.link}>{cpraLinkText}</CPRADisclosureLinkComponent>
            {afterText}
        </div>
    );
};

export const disclaimerText = `${beforeText}${linkText} and ${cpraLinkText}${afterText}`;
export default PriceListDisclaimerText;
