import { RoutingNode } from 'hooks/useRouteCalculator/routing-graph';
import { RootState } from 'redux/root';
import urlPaths from 'static/constants/enums/urlPaths';
import { Timing } from 'static/models/enums/timing';

const preferencesPageRouting: RoutingNode = {
    forwardEdges: [
        {
            destination: urlPaths.timingNotAvailable,
            condition: (state: RootState): boolean => {
                const { timing } = state.pickedPreferences;
                const { feature_ImmediateSales, feature_ImminentSales, feature_FutureSales } = state.account;
                if (state.featureFlags.enableTimingGatePage) {
                    return timing === Timing.IMMEDIATE && !feature_ImmediateSales;
                } else {
                    return (
                        (timing === Timing.IMMEDIATE && !feature_ImmediateSales) ||
                        (timing === Timing.IMMINENT && !feature_ImminentSales) ||
                        (timing === Timing.FUTURE && !feature_FutureSales)
                    );
                }
            },
        },
    ],
    backwardEdges: [],
    forAllForwardEdges: state => !!state.pickedPreferences?.timing && !!state.pickedPreferences?.disposition,
    forAllBackwardEdges: () => false,
};

export default preferencesPageRouting;
