import React from 'react';

import { IconButton, makeStyles, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { getGuaranteedText } from 'toolboxes/reuseable-logic/state-specific-language/guaranteed-language-change';

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(0.5),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
    },
    titleContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    uppercase: {
        textTransform: 'uppercase',
    },
    iconButton: {
        fontSize: '1rem',
        color: 'black',
    },
}));

const ExplainerBody: React.FunctionComponent<PropShape> = ({ onClose }) => {
    const classes = useStyles();
    const { selectedAccount } = useSelectedAccount();

    const guaranteedText = getGuaranteedText(selectedAccount).toLowerCase();

    return (
        <div className={classes.container}>
            <div className={classes.titleContainer}>
                <Typography variant="subtitle1" className={classes.uppercase}>
                    What does this mean?
                </Typography>
                <IconButton onClick={onClose} id="close-popup" title="close popup">
                    <Close className={classes.iconButton} />
                </IconButton>
            </div>
            <Typography variant="body2">
                The funeral provider accepts prepayment for an item at the time of the arrangement as payment in full,{' '}
                {guaranteedText}. So, even if the price of the item increases over time, the funeral provider is
                responsible for covering the difference and your loved ones will not incur any additional out-of-pocket
                costs. Should a product not be available at the time of service a comparable substitute will used.
            </Typography>
        </div>
    );
};

interface PropShape {
    onClose: () => void;
}

export default ExplainerBody;
