import ButtonOption from './ButtonOption';
import { Timing } from './enums/timing';

const timingOptions: ButtonOption<Timing>[] = [
    { value: Timing.IMMEDIATE, title: 'Now', subtitle: 'I have lost a loved one' },
    {
        value: Timing.IMMINENT,
        title: 'Soon',
        subtitle: `I'm planning for the final stages of life (within 6 months)`,
    },
    { value: Timing.FUTURE, title: 'In the Future', subtitle: `I'm planning ahead (beyond 6 months)` },
];

export default timingOptions;
