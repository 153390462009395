import React from 'react';

const LoadingScreenComponent: React.FunctionComponent<PropShape> = () => {
    return <div>Loading...</div>;
};
interface PropShape {
    // Shape of passed in props
}

LoadingScreenComponent.defaultProps = {
    // Set default values for props
};
export default LoadingScreenComponent;
