export function stripAttributes(html: string): string {
    if (!html) return;
    const root = new DOMParser().parseFromString(html, 'text/html');
    stripAttributesFromElement(root.body);
    return root.body.innerHTML;
}

function stripAttributesFromElement(element: Element) {
    for (let i = 0; i < element.attributes.length; i++) {
        element.removeAttribute(element.attributes[i].name);
    }
    // this will not run if element.children.length === 0, so no infinite recursion
    for (let i = 0; i < element.children.length; i++) {
        stripAttributesFromElement(element.children[i]);
    }
}
