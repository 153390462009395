import { setAccount } from '../../redux/account/root';
import { RootState } from '../../redux/root';
import Account from '../../static/models/Account';
import {
    fetchAccountBySubdomain,
    fetchAccountCssBySubdomain,
    fetchParentAndChildrenAccountsBySubdomain,
    fecthAccountByOpportunityId,
} from '../web-requests/specific-requests/account-requests';

export async function getAccountBySubdomain(subdomain: string): Promise<Account> {
    return await fetchAccountBySubdomain(subdomain);
}

export async function getAccountByOpportunityId(opportunityId: string): Promise<Account> {
    return await fecthAccountByOpportunityId(opportunityId);
}

export async function getParentAndChildrenAccountsBySubdomain(subdomain: string): Promise<Account[]> {
    return await fetchParentAndChildrenAccountsBySubdomain(subdomain);
}

export function getAccountCssBySubdomain(subdomain: string): Promise<string> {
    return fetchAccountCssBySubdomain(subdomain);
}

export async function localSetAccount(state: RootState, dispatch: Function): Promise<Account> {
    let account = state.account;

    if (!account.id) {
        const subdomain = window.location.hostname.split('.')[0];
        account = await getAccountBySubdomain(subdomain);
        dispatch(setAccount(account));
    }

    if (state.isDemoApp) {
        account = await getAccountBySubdomain('acmebasic');
        dispatch(setAccount(account));
    }

    return account;
}
