import React from 'react';

import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardMedia,
    Divider,
    Grid,
    makeStyles,
    Typography,
} from '@material-ui/core';

const cardWidth = 300;
const cardHeight = 370;

const useStyles = makeStyles(theme => ({
    card: {
        margin: 8,
        position: 'relative',
        overflow: 'visible',
        width: cardWidth,
        height: cardHeight,
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    addressContent: {
        height: '5em',
    },
    addressContainer: {
        paddingBottom: 0,
        '&:last-child': {
            // see https://stackoverflow.com/questions/54236623/cant-remove-padding-bottom-from-card-content-in-material-ui
            paddingBottom: 0,
        },
    },
    media: {
        height: 100,
        width: cardWidth,
        marginLeft: 'auto',
        marginRight: 'auto',
        backgroundSize: '100%',
        backgroundColor: theme.custom.headerBackgroundColor,
    },
    cardActions: {
        paddingTop: 0,
        width: '100%',
    },
    actionButton: {
        width: '50%',
        margin: '0 auto',
    },
    cardMedia: {
        width: '100%',
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            alignSelf: 'center',
        },
    },
    cardMediaImage: {
        height: '100%',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            maxHeight: cardHeight,
        },
    },
}));

const LocationSelectionCardComponent: React.FunctionComponent<PropShape> = ({
    logoUrl,
    streetAddress,
    city,
    state,
    zip,
    onSelect,
    distanceComponent,
}) => {
    const classes = useStyles();

    return (
        <Card className={classes.card} elevation={0} aria-label={'location card'}>
            <CardContent className={classes.cardMedia}>
                <Grid container spacing={2}>
                    <Grid item className={classes.cardMedia}>
                        <CardMedia className={classes.cardMediaImage} image={logoUrl} component="img" />
                    </Grid>
                    <Grid xs={12} item>
                        <div className={classes.addressContent}>
                            <Typography variant="body2" align="center">
                                {streetAddress}
                            </Typography>
                            <Typography variant="body2" align="center">
                                {`${city}, ${state} ${zip}`}
                            </Typography>
                        </div>
                        <Divider />
                        {distanceComponent}
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions className={classes.cardActions}>
                <Button
                    aria-label={'select location'}
                    className={classes.actionButton}
                    color="primary"
                    variant="outlined"
                    onClick={onSelect}
                >
                    Select
                </Button>
            </CardActions>
        </Card>
    );
};

interface PropShape {
    logoUrl: string;
    streetAddress: string;
    city: string;
    state: string;
    zip: string;
    distanceComponent: JSX.Element;
    onSelect: () => void;
}

LocationSelectionCardComponent.defaultProps = {
    // Set default values for props
};

export default LocationSelectionCardComponent;
