import { GooglePlaceDetailsRequest } from 'static/models/location/GooglePlaceDetailsRequest';
import { GooglePlaceDetailsResponse } from 'static/models/location/GooglePlaceDetailsResponse';
import { ServiceAreaRequest } from 'static/models/location/ServiceAreaRequest';
import { ServiceAreaResponse } from 'static/models/location/ServiceAreaResponse';
import { LocationAutocompleteResponse } from 'static/models/LocationAutocomplete';
import { LocationAutocompleteRequest } from 'static/models/LocationAutocompleteRequest';

import locationClient from '../apis/locationClient';
import { sendRequest } from '../web-request-tools';

export async function fetchAutocompletedLocations(
    autocompleteRequest: LocationAutocompleteRequest,
): Promise<LocationAutocompleteResponse> {
    return sendRequest(locationClient.post<LocationAutocompleteResponse>('/autocomplete', autocompleteRequest));
}

export async function fetchPlaceDetails(
    placeDetailsRequest: GooglePlaceDetailsRequest,
): Promise<GooglePlaceDetailsResponse> {
    return sendRequest(locationClient.post<GooglePlaceDetailsResponse>('/details', placeDetailsRequest));
}

export async function fetchServiceArea(request: ServiceAreaRequest): Promise<ServiceAreaResponse> {
    return sendRequest(locationClient.post<ServiceAreaResponse>('/serviceArea', request));
}
