import { RoutingNode } from 'hooks/useRouteCalculator/routing-graph';

const casketPageRouting: RoutingNode = {
    forwardEdges: [],
    backwardEdges: [],
    forAllForwardEdges: state =>
        state.selectedProducts.filter(products => products.subCategory === 'Casket').length > 0,
};

export default casketPageRouting;
