import React from 'react';

import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import ProductGridListComponent from 'components/global-components/product-grid-component/ProductGridListComponent';
import { useFilteredProducts } from 'hooks/useFilteredProducts';

const EnhancementsPageComponent: React.FunctionComponent = () => {
    const products = useFilteredProducts('Additional Items', null, true);
    return (
        <PageContentWrapperComponent
            title="Select Your Enhancements"
            subtitle={
                <>
                    Below you will find additional goods & services to add to your arrangement. We offer a variety of
                    standard or customizable merchandise and service enhancements to choose from. Please make sure to
                    denote the quantity if applicable. Once you’ve made your selections, click "Continue."
                </>
            }
        >
            <ProductGridListComponent
                products={products}
                productsCategory="Additional Items"
                groupBySubcategory={true}
                multiselect
                enableQuantity
            />
        </PageContentWrapperComponent>
    );
};

export default EnhancementsPageComponent;
