import React, { useState } from 'react';

import { Button, Grid, makeStyles, Popover, Typography } from '@material-ui/core';
import GuaranteedIconComponent from 'components/icon-components/guaranteed-icon-component/GuaranteedIconComponent';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { getGuaranteedText } from 'toolboxes/reuseable-logic/state-specific-language/guaranteed-language-change';

import ExplainerBody from './ExplainerBody';

const useStyles = makeStyles(theme => ({
    container: {
        alignItems: 'center',
        fontSize: '0.875rem',
    },
    text: {
        fontSize: '1em',
    },
    link: {
        marginLeft: 4,
        fontSize: '1em',
        padding: 0,
        fontStyle: 'italic',
        color: theme.palette.primary.main,
        textDecoration: 'none',
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
        },
    },
    paper: {
        maxWidth: 475,
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        border: '1px solid black',
    },
}));

const GuaranteedLegendComponent: React.FunctionComponent = () => {
    const classes = useStyles();
    const { selectedAccount } = useSelectedAccount();
    const [anchor, setAnchor] = useState<HTMLButtonElement | null>(null);

    const guaranteedText = getGuaranteedText(selectedAccount, true);

    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (anchor === null) {
            setAnchor(e.currentTarget);
        }
    };
    const handleClose = () => {
        if (anchor !== null) {
            setAnchor(null);
        }
    };
    return (
        <Grid container direction="row" className={classes.container}>
            <GuaranteedIconComponent />
            <Typography variant="body2" className={classes.text}>
                = {guaranteedText}
            </Typography>

            <Popover
                anchorEl={anchor}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{ elevation: 0, className: classes.paper }}
                onClose={handleClose}
                open={anchor !== null}
            >
                <ExplainerBody onClose={handleClose} />
            </Popover>
            <Button id="guaranteed-explanation" disableRipple onClick={handleClick} className={classes.link}>
                (What is this?)
            </Button>
        </Grid>
    );
};

export default GuaranteedLegendComponent;
