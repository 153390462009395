import { RoutingNode } from 'hooks/useRouteCalculator/routing-graph';

const membershipPageRouting: RoutingNode = {
    forwardEdges: [],
    backwardEdges: [],
    forAllForwardEdges: () => {
        return true;
    },
    forAllBackwardEdges: () => {
        return false;
    },
};

export default membershipPageRouting;
