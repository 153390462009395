import React, { useEffect, useState } from 'react';

import { makeStyles, Button } from '@material-ui/core';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import PopupComponent from 'components/form-components/popup-component/PopupComponent';
import CartComponent from 'components/global-components/cart-component/CartComponent';
import { useSelectedProducts } from 'hooks/product-package-questions/useSelectedProducts';
import { useSelectedTravelPackage } from 'hooks/travel/useSelectedTravelPackage';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { getFormattedFuneralTotalWithTaxIncluded } from 'toolboxes/reuseable-logic/products/productFunctions';

import { usePickupLocation } from '../../../../hooks/usePickupLocation';

// This is the Styling/Css make the classes and define the style in there
const useStyles = makeStyles(theme => ({
    cart: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        color: theme.custom.footerFontColor,
    },
    '@-webkit-keyframes pulse_animation': {
        '0%': {
            webkitTransform: 'scale(1)',
            transform: 'scale(1)',
        },
        '50%': {
            webkitTransform: 'scale(1.3)',
            transform: 'scale(1.3)',
        },
        '100%': {
            webkitTransform: 'scale(1)',
            transform: 'scale(1)',
        },
    },
    '@keyframes pulse_animation': {
        '0%': {
            webkitTransform: 'scale(1)',
            transform: 'scale(1)',
        },
        '50%': {
            webkitTransform: 'scale(1.3)',
            transform: 'scale(1.3)',
        },
        '100%': {
            webkitTransform: 'scale(1)',
            transform: 'scale(1)',
        },
    },
    pulse: {
        webkitAnimationName: '$pulse_animation',
        animationName: '$pulse_animation',
        webkitAnimationDuration: '0.25s',
        animationDuration: '0.25s',
        webkitTransformOrigin: 70,
        transformOrigin: 70,
        webkitAnimationIterationCount: '1',
        animationIterationCount: '1',
        webkitAnimationTimingFunction: 'linear',
        animationTimingFunction: 'linear',
    },
}));

const HeaderCartComponent: React.FunctionComponent<PropShape> = () => {
    const classes = useStyles();
    const { selectedProducts } = useSelectedProducts();
    const [cartPulsing, setCartPulsing] = useState<Boolean>(false);
    const { selectedAccount } = useSelectedAccount();
    const { selectedTravelPackage } = useSelectedTravelPackage();
    const { pickupLocation } = usePickupLocation();

    const [closeCart, setCloseCart] = useState<boolean>(false);

    useEffect(() => {
        setCartPulsing(true);
    }, [selectedProducts]);

    useEffect(() => {
        if (cartPulsing) {
            // This will set the pulsing to false so it can pulse again on cart updates
            // So it'll pulse and any cart changes that happen while it's pulsing will not
            // make it pulse again. After it finishes the pulsing 1 time, the next cart update
            // will make it pulse again
            setTimeout(() => setCartPulsing(false), 250);
        }
    }, [cartPulsing, setCartPulsing]);

    const handleClose = () => {
        setCloseCart(true);
    };

    useEffect(() => {
        if (closeCart) {
            setTimeout(() => setCloseCart(false), 1);
        }
    }, [closeCart, setCloseCart]);

    return (
        <span className={classes.cart}>
            {selectedProducts.length === 0 && (
                <div>
                    <PopupComponent
                        button={
                            <Button id="empty-cart-button" disableRipple>
                                <ShoppingCartIcon id="empty-cart-icon" className={classes.cart} />
                            </Button>
                        }
                        popupId="empty-cart-popup"
                        closed={closeCart}
                    >
                        <CartComponent shouldShowCloseIcon={true} onCloseIconFunction={handleClose} />
                    </PopupComponent>
                </div>
            )}
            {selectedProducts.length !== 0 && (
                <div>
                    <PopupComponent
                        button={
                            <Button id="non-empty-cart-icon" disableRipple>
                                <div className={`${classes.cart} ${cartPulsing ? classes.pulse : ''}`}>
                                    <AddShoppingCartIcon id="non-empty-cart-icon" />
                                    <span id="cart-header-total-price">
                                        {getFormattedFuneralTotalWithTaxIncluded(
                                            selectedProducts,
                                            selectedAccount.salesTax,
                                            selectedTravelPackage.price,
                                            pickupLocation,
                                            selectedAccount.mileageCharge,
                                        )}
                                    </span>
                                </div>
                            </Button>
                        }
                        popupId="non-empty-cart-icon"
                        closed={closeCart}
                    >
                        <CartComponent
                            shouldShowMore={true}
                            shouldShowCloseIcon={true}
                            onCloseIconFunction={handleClose}
                        />
                    </PopupComponent>
                </div>
            )}
        </span>
    );
};

export interface PropShape {}

export default HeaderCartComponent;
