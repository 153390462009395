import DemoFormData from 'static/models/demo/demoFormData';
import { LeadContact } from 'static/models/LeadContact';

import productServiceClient from '../apis/productServiceClient';
import { sendRequest } from '../web-request-tools';

export function runCreateLead(lead: LeadContact): Promise<string> {
    return sendRequest(productServiceClient.post('/lead', lead));
}

export function runCreateLeadFromDemo(lead: DemoFormData): Promise<void> {
    return sendRequest(productServiceClient.post(`/lead/demo`, lead));
}
