import { useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { RootState } from 'redux/root';
import urlPaths from 'static/constants/enums/urlPaths';

import { ignoredRoutes } from './ignored-routes';
import { pageGraph as defaultPageGraph, RoutingNode } from './routing-graph';

interface ReturnType {
    nextPage: urlPaths | null;
    previousPage: urlPaths | null;
}

export function useRouteCalculator(pageGraph = defaultPageGraph): ReturnType {
    const location = useLocation();
    const state = useSelector((state: RootState) => state);
    if (ignoredRoutes.includes(location.pathname)) {
        return {
            nextPage: null,
            previousPage: null,
        };
    }
    const currentNode = pageGraph[location.pathname];
    if (!currentNode) {
        console.error(
            `Route ${location.pathname} not found in page graph. Did you add it to src/hooks/useRouteCalculator/routing-graph.ts?`,
        );
        return {
            nextPage: null,
            previousPage: null,
        };
    }
    const nextPage = findValidEdge(currentNode, 'forward', state);
    const previousPage = findValidEdge(currentNode, 'backward', state);
    return {
        nextPage,
        previousPage,
    };
}

function findValidEdge(node: RoutingNode, direction: 'forward' | 'backward', state: RootState): urlPaths | null {
    if (direction === 'forward' && node.forAllForwardEdges && !node.forAllForwardEdges(state)) {
        // there's a forAll transition test and it failed
        return null;
    }
    if (direction === 'backward' && node.forAllBackwardEdges && !node.forAllBackwardEdges(state)) {
        // there's a forAll transition test and it failed
        return null;
    }
    const edges = direction === 'forward' ? node.forwardEdges : node.backwardEdges;
    // because nodes are loaded into the pageGraph using mergeEdges, we know that any edges leading to pages earlier
    // in the flow than currentLocation have already been chopped off.
    for (let i = 0; i < edges.length; i++) {
        if (edges[i].condition(state)) {
            return edges[i].destination;
        }
    }
    return null;
}
