import React, { useEffect } from 'react';

import { Divider, makeStyles, Theme, Typography } from '@material-ui/core';
import GuaranteedIconComponent from 'components/icon-components/guaranteed-icon-component/GuaranteedIconComponent';
import { sanitize } from 'dompurify';
import { useContentOverflow } from 'hooks/useContentOverflow';
import Product from 'static/models/Product';

import { DescriptionOverflowType } from './types';

interface StyleProps {
    isExpanded: boolean;
    isScrollable: boolean;
    height: string | number;
}

const useStyles = makeStyles<Theme, StyleProps>(theme => ({
    fadeOut: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: '70%',
        width: '100%',
        backgroundImage: `linear-gradient(
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0.8) 50%,
            rgba(255, 255, 255, 1) 100%    
        )`,
    },
    content: {
        padding: theme.spacing(1),
        fontSize: '1em',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        height: ({ isExpanded, height }) => (isExpanded ? null : height),
        minHeight: ({ isExpanded, height }) => (isExpanded ? height : 'none'),
        [theme.breakpoints.down('sm')]: {
            maxHeight: ({ isExpanded, height }) => (isExpanded ? 'none' : height),
        },
    },
    titleFont: {
        fontSize: '0.875rem',
        textTransform: 'uppercase',
        wordWrap: 'break-word',
    },
    divider: {
        marginTop: theme.spacing(0.5),
        marginBottom: theme.spacing(0.5),
    },
    titleContainer: {
        minHeight: '3em',
    },
    iconContainer: {
        minHeight: theme.spacing(3),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-end',
    },
    descriptionContainer: {
        flex: 1,
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
    },
    description: {
        flex: 1,
        overflowY: ({ isScrollable }) => (isScrollable ? 'auto' : 'hidden'),
        overflowX: 'hidden',
    },
}));

const ProductCardContent: React.FunctionComponent<PropShape> = ({
    descriptionOverflow,
    height,
    isExpanded,
    product,
    showGuaranteed,
    onContentOverflowChange,
}) => {
    const classes = useStyles({
        isExpanded: isExpanded && descriptionOverflow !== 'scroll',
        isScrollable: descriptionOverflow === 'scroll',
        height,
    });

    const { ref, hasOverflow } = useContentOverflow();
    useEffect(() => {
        onContentOverflowChange(hasOverflow);
    }, [hasOverflow, onContentOverflowChange]);

    return (
        <div className={classes.content}>
            <div className={classes.iconContainer}>
                {product.guaranteed && showGuaranteed && <GuaranteedIconComponent />}
            </div>
            <div className={classes.titleContainer}>
                <Typography variant="h5" component="h5" align="center" className={classes.titleFont}>
                    {product.name}
                </Typography>
            </div>
            <Divider className={classes.divider} />
            <div className={classes.descriptionContainer}>
                {!isExpanded && descriptionOverflow !== 'scroll' && hasOverflow && <div className={classes.fadeOut} />}
                <Typography
                    variant="body2"
                    className={classes.description}
                    ref={ref}
                    dangerouslySetInnerHTML={{ __html: sanitize(product.salesDescription) }}
                />
            </div>
        </div>
    );
};

interface PropShape {
    descriptionOverflow: DescriptionOverflowType;
    height: number | string;
    isExpanded: boolean;
    product: Product;
    showGuaranteed: boolean;
    onContentOverflowChange: (val: boolean) => void;
}

export default ProductCardContent;
