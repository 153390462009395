import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Preferences } from 'static/models/Preferences';

const initialState: Preferences = {
    timing: null,
    disposition: null,
};

export { initialState as initialPickedPreferences };

const pickedPreferencesSlice = createSlice({
    name: 'pickedPreferences',
    initialState,
    reducers: {
        setPickedPreferences(state, action: PayloadAction<Preferences>) {
            return action.payload;
        },
    },
});

export const { setPickedPreferences } = pickedPreferencesSlice.actions;
export default pickedPreferencesSlice.reducer;
