import React, { Fragment } from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { useSelectedProducts } from 'hooks/product-package-questions/useSelectedProducts';
import { useOpportunity } from 'hooks/useOpportunity';
import { Timing } from 'static/models/enums/timing';
import { ProductGroup } from 'static/models/Product';

import LineItemComponent from './LineItemComponent';

const useStyles = makeStyles(theme => ({
    categoryHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        paddingBottom: 5,
        paddingTop: 10,
        fontSize: '1rem',
        color: '#000000',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
    },
    categoryInnerLine: {
        borderBottomWidth: 1,
        borderBottomColor: '#000000',
        borderBottomStyle: 'solid',
    },
    paddingBottom: {
        paddingBottom: 5,
    },
}));

const ReceiptCartLineItemsComponent: React.FunctionComponent<PropShape> = props => {
    const classes = useStyles();
    const { selectedProducts } = useSelectedProducts();
    const { opportunity } = useOpportunity();
    const showQuantitySpace = selectedProducts.some(p => p.quantity > 1);
    const showGuaranteed = opportunity.timing !== Timing.IMMEDIATE;

    return (
        <Fragment>
            <div className={classes.categoryInnerLine}>
                <Typography variant="h3" component="h3">
                    <span id="cart-line-item-summary-category-header" className={classes.categoryHeader}>
                        {props.productGroup.groupName}
                    </span>
                </Typography>
                <div id="cart-category-line-items" className={classes.paddingBottom}>
                    {props.productGroup.products.map((x, i) => {
                        return (
                            <LineItemComponent
                                key={i}
                                showQuantitySpace={showQuantitySpace}
                                orderSummaryPage={props.orderSummaryPage}
                                product={x}
                                showGuaranteed={showGuaranteed}
                            />
                        );
                    })}
                </div>
            </div>
        </Fragment>
    );
};

interface PropShape {
    productGroup: ProductGroup;
    orderSummaryPage?: boolean;
}

ReceiptCartLineItemsComponent.defaultProps = {
    orderSummaryPage: false,
};

export default ReceiptCartLineItemsComponent;
