import axios from 'axios';

import getEnvironment from '../../env-config/get-environment';
import { FeatureFlagResult } from '../../static/models/network/FeatureFlagResult';

export async function getGlobalFeatureFlags(): Promise<Array<FeatureFlagResult>> {
    const response = await axios.get<Array<FeatureFlagResult>>('/flags', {
        baseURL: getEnvironment().featuresUrl,
        validateStatus: () => true,
    });
    return response.data || [];
}
