import React from 'react';

import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import { useErrorData } from 'hooks/useErrorData';

const ErrorSubmissionComponent: React.FunctionComponent = () => {
    const { errorLogging } = useErrorData();

    return (
        <PageContentWrapperComponent
            title={!errorLogging.errorCaseCreationFailed ? 'Case Received' : 'Failed to Make Bug Ticket'}
            subtitle={
                <>
                    {!errorLogging.errorCaseCreationFailed
                        ? `Your case has been sent to our support team.${' '}
                    ${errorLogging.errorCaseNumber ? 'Your case number is: ' + errorLogging.errorCaseNumber : ''}`
                        : `Sorry, there's seems to be a problem on our end, and we couldn't make the bug ticket.\n
                        Please email us at help@efuneral.com for assistance.`}
                </>
            }
        ></PageContentWrapperComponent>
    );
};

export default ErrorSubmissionComponent;
