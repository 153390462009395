import Account from '../../../static/models/Account';
import productServiceClient from '../apis/productServiceClient';
import s3Client from '../apis/s3Client';
import { sendRequest } from '../web-request-tools';

export function fetchAccountBySubdomain(subdomain: string): Promise<Account> {
    return sendRequest(productServiceClient.get<Account>(`/FuneralHome/SubDomain/${subdomain}`));
}

export function fecthAccountByOpportunityId(opportunityId: string): Promise<Account> {
    return sendRequest(productServiceClient.get<Account>(`/FuneralHome/opportunity/${opportunityId}`));
}

export function fetchParentAndChildrenAccountsBySubdomain(subdomain: string): Promise<Account[]> {
    return sendRequest(
        productServiceClient.get<Account[]>(`/FuneralHome/SubDomain/${subdomain}/RetreiveParentAndChildren`),
    );
}

export function fetchAccountCssBySubdomain(subdomain: string): Promise<string> {
    // add timestamp to query param to avoid caching issues
    return sendRequest(s3Client.get<string>(`/custom-styles/${subdomain}-v2.css?${new Date().getTime()}`));
}
