import React, { useEffect, useRef, useState } from 'react';

import { Collapse, makeStyles, Theme, alpha } from '@material-ui/core';

type StyleProps = {
    maxHeight: number;
    fadeHeight: number;
    fadeInColor: string;
};

const useStyles = makeStyles<Theme, StyleProps>({
    collapseContainer: {
        position: 'relative',
    },
    fadeContainer: {
        overflow: 'hidden',
        position: 'relative',
    },
    fadeContainerCollapsed: {
        maxheight: ({ maxHeight }) => maxHeight,
    },
    fadeContainerExpanded: {
        height: '100%',
    },
    fadeOut: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        height: ({ maxHeight }) => maxHeight * 0.25,
        width: '100%',
        backgroundImage: ({ fadeInColor }) => `linear-gradient(
            ${alpha(fadeInColor, 0)} 0%,
            ${alpha(fadeInColor, 1)} 100%    
        )`,
    },
    showMoreLink: {
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
    },
    showMoreIcon: {
        fontSize: 'inherit',
    },
});

const ContentOverflowComponent: React.FunctionComponent<PropShape> = ({
    children,
    fadeHeight,
    maxHeight,
    minHeight,
    fadeInColor,
    collapsed,
    onHasHiddenContent,
    onCollapsedHeightSet,
}) => {
    const ref = useRef(null);
    const [hasHiddenContent, setHasHiddenContent] = useState<boolean>(false);
    const [collapsedHeight, setCollapsedHeight] = useState<number>(0);

    const classes = useStyles({
        maxHeight,
        fadeHeight,
        fadeInColor,
    });

    // allowable offset for determining if content is hidden
    const offset = 8;

    useEffect(() => {
        const fullContentHeight = Number(ref.current.firstChild.clientHeight);
        const hasHiddenContent = fullContentHeight > maxHeight + offset;
        setHasHiddenContent(hasHiddenContent);

        onHasHiddenContent(hasHiddenContent);

        let collapsedHeight = maxHeight;

        if (!hasHiddenContent) {
            if (fullContentHeight < minHeight) {
                collapsedHeight = minHeight;
            } else {
                collapsedHeight = fullContentHeight;
            }
        }

        setCollapsedHeight(collapsedHeight);
        onCollapsedHeightSet(collapsedHeight);
    }, [maxHeight, minHeight, onCollapsedHeightSet, onHasHiddenContent, ref]);

    return (
        <div className={classes.collapseContainer}>
            <Collapse ref={ref} in={!collapsed} collapsedSize={collapsedHeight}>
                <div className={`${classes.fadeContainer}`}>{children}</div>
            </Collapse>
            {collapsed && hasHiddenContent && <div className={classes.fadeOut}></div>}
        </div>
    );
};

interface PropShape {
    // Shape of passed in props
    maxHeight: number;
    minHeight: number;
    fadeHeight: number;
    fadeInColor?: string;
    collapsed?: boolean;
    showHidden?: boolean;
    onHasHiddenContent?: (hidden: boolean) => void;
    onCollapsedHeightSet?: (value: number) => void;
}

ContentOverflowComponent.defaultProps = {
    // Set default values for props
    fadeInColor: '#FFFFFF',
    collapsed: true,
    showHidden: false,
    onHasHiddenContent: () => {},
    onCollapsedHeightSet: () => {},
};

export default ContentOverflowComponent;
