import ErrorEnterFunctions from 'components/page-components/error-page-component/error-enter-functions';
import SendingFailingRequestFunctions from 'components/page-components/error-page-component/sending-failing-request-component/sendingFailingRequestEnterFunctions';
import ReturningOpportunityEnterFunctions from 'components/page-components/returning-opportunity/returning-opportunity-enter-functions';
import { Action } from 'history';
import { RoutingParams } from 'static/models/RoutingParams';

import InitialLoadAndCreateFunctions from '../../components/page-components/initial-load-and-create-component/initial-load-and-create-enter-functions';
import LoadOpportunityFunctions from '../../components/page-components/load-opportunity/load-opportunity-enter-functions';
import { RootState } from '../../redux/root';
import { setInitialParams } from '../../redux/urlParamTracking/root';
import urlPaths from '../../static/constants/enums/urlPaths';
import BlockingFunction from '../../static/models/blockingFunctions/BlockingFunction';
import {
    addCustomerPathAssociation,
    addPathToCustomerPathHistory,
} from '../server-application-buffer/opportunity-data';
import { loadAccounts } from './load-accounts';
import { logUrlTracking } from './log-url-tracking';

// This function receives the state, and can find out what we need to load
// If there are things that need to happen before we can show a url's page
// Return those things as executable asyncrenous functions here
// The promises should end by returning a boolean indicating success
function generateBasePageEnterFunctions(
    state: RootState,
    dispatch: Function,
    urlPath: string,
    params: RoutingParams,
    _navigationMethod: Action,
): BlockingFunction[] {
    // These are pages that don't need an account set in the redux store
    switch (urlPath) {
        case urlPaths.returningOpportunity:
            return ReturningOpportunityEnterFunctions(state, dispatch);
        case urlPaths.sendingFailingRequest:
            return SendingFailingRequestFunctions(state);
        case urlPaths.error:
            return ErrorEnterFunctions(state, dispatch);
    }

    // will skip the enter functions below if there is no account set is the redux store
    if (!state.account.id) {
        return loadAccounts(state, dispatch);
    }

    if (state.opportunity.opportunityId && state.urlParamTracking.loadParams) {
        dispatch(setInitialParams(null));
        logUrlTracking(state.opportunity.opportunityId, state.urlParamTracking.loadParams);
    }

    // These will get SKIPPED if there is no account in the redux store,
    // therefore, these pages REQUIRE and account to be selected.
    switch (urlPath) {
        case urlPaths.initialLoadAndCreate:
            return InitialLoadAndCreateFunctions(state, dispatch);
        case urlPaths.loadOpportunity:
            return LoadOpportunityFunctions(state, dispatch, params);
        default:
            return [];
    }
}

// This is used to keep track of each page entry
export default function generatePageEnterFunctions(
    state: RootState,
    dispatch: Function,
    urlPath: string,
    params: RoutingParams,
    navigationMethod: Action,
): BlockingFunction[] {
    const baseEntryFunctions = generateBasePageEnterFunctions(state, dispatch, urlPath, params, navigationMethod);
    baseEntryFunctions.push({
        promiseGenerator: () => {
            if (state.featureFlags.useCustomerPathAssociationObject) {
                return addCustomerPathAssociation(state.opportunity.opportunityId, urlPath);
            } else {
                return addPathToCustomerPathHistory(state.opportunity.opportunityId, urlPath);
            }
        },
        blocksPageStarts: [],
    });
    return baseEntryFunctions;
}
