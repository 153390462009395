import urlPaths from 'static/constants/enums/urlPaths';
import { CustomerPathEntry } from 'static/models/Opportunity';

export function parseCustomerPath(rawCustomerPath: string): CustomerPathEntry[] {
    if (!rawCustomerPath) {
        return [];
    }
    const customerPath: CustomerPathEntry[] = [];
    const parts = rawCustomerPath.split('\n');
    parts.forEach(p => {
        const [path, time] = parsePathEntry(p);
        // we can use as here because we know that path can only come back as urlPaths
        customerPath.push({ path: path as urlPaths, time });
    });
    return customerPath;
}

function parsePathEntry(entry: string): [string, Date] {
    const timeStr = entry.replace(/:\s(\/\w*)+/i, '');
    const pathStr = entry.replace(`${timeStr}: `, '');
    return [pathStr, new Date(timeStr)];
}
