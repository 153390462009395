import { RootState } from 'redux/root';
import BadRequestData from 'static/models/errorLogging/badRequestData';
import ErrorCaseCreationResponse from 'static/models/errorLogging/errorCaseCreationResponse';
import ErrorCaseUpdate from 'static/models/errorLogging/errorCaseUpdate';
import ErrorData from 'static/models/errorLogging/errorData';
import LoadedOpportunity from 'static/models/LoadedOpportunity';
import Opportunity from 'static/models/Opportunity';
import PackageUpdate from 'static/models/PackageUpdate';
import { getSubdomain } from 'toolboxes/reuseable-logic/get-subdomain';
import { getOpportunityIdLocalStorage } from 'toolboxes/reuseable-logic/local-storage-getters-setters';
import { mapLoadedOpportunityToOpportunity } from 'toolboxes/reuseable-logic/map-opportunity';

import {
    createFuneralOpportunity,
    fetchOpportunity,
    fetchOpportunityDisclaimers,
    runCreateErrorCase,
    runFailingRequest,
    runSaveCustomerPathAssociation,
    runUpdateErrorCase,
    runUpdatePackageOnOpportunity,
    runUpdateProductQuestionResponses,
    runUpdateProductsForOpportunity,
    savePathToCustomerPathHistory,
    updateOpportunity,
} from '../web-requests/specific-requests/opportunity-requests';

export async function addPathToCustomerPathHistory(opportunityId: string, path: string): Promise<any> {
    if (!opportunityId) {
        return Promise.resolve();
    }

    const currentTime = new Date(Date.now());
    const newPath = `${currentTime.toISOString()}: ${path}`;

    return await savePathToCustomerPathHistory(opportunityId, newPath);
}

export async function addCustomerPathAssociation(opportunityId: string, path: string): Promise<any> {
    if (!opportunityId) {
        return Promise.resolve();
    }

    const currentTime = new Date(Date.now());
    const newPath = `${currentTime.toISOString()} --- ${path}`;

    return await runSaveCustomerPathAssociation(opportunityId, newPath);
}

export async function createOpportunity(accountId: string): Promise<Opportunity> {
    const response = await createFuneralOpportunity(accountId);
    return mapLoadedOpportunityToOpportunity({ ...response, hidePriceListDisclaimer: false });
}

export async function loadOpportunity(opportunityId: string): Promise<LoadedOpportunity> {
    const res = await fetchOpportunity(opportunityId);
    res.hidePriceListDisclaimer = false;
    const disclaimerResp = await fetchOpportunityDisclaimers(opportunityId);
    if (disclaimerResp.some(d => d.accepted === 'Yes' && d.topic === 'Price List')) {
        res.hidePriceListDisclaimer = true;
    }
    return res;
}
export async function updatePackageOnOpportunity(state: RootState): Promise<any> {
    const packageUpdate: PackageUpdate = {
        opportunityId: state.opportunity.opportunityId,
        funeralHomeAccountId: state.account.id,
        packageId: state.selectedPackage.id,
        packageName: state.selectedPackage.name,
        products: state.selectedProducts,
    };

    return await runUpdatePackageOnOpportunity(packageUpdate);
}

export async function updateProductsOnOpportunity(state: RootState): Promise<void> {
    await runUpdateProductsForOpportunity(state.opportunity.opportunityId, state.selectedProducts);
}

export async function updateProductsOnTravelOpportunity(state: RootState): Promise<void> {
    await runUpdateProductsForOpportunity(state.travelOpportunity.id, state.selectedTravelPackage.package.products);
}

export async function updateProductQuestionResponses(state: RootState): Promise<void> {
    return await runUpdateProductQuestionResponses(state.opportunity.opportunityId, state.productQuestionResponses);
}

export async function updatePickupLocationOnOpportunity(state: RootState): Promise<void> {
    const opportunityUpdate: Opportunity = {
        ...state.pickupLocation,
        opportunityId: state.opportunity.opportunityId,
    };
    return await updateOpportunity(state.account.id, opportunityUpdate);
}

export function sendFailingRequest(state: RootState): Promise<void> {
    const data: BadRequestData = {
        message:
            'sending the test failing request. ' +
            (state.opportunity.opportunityId
                ? 'Opportunity Id: ' + state.opportunity.opportunityId
                : 'No opportunity id'),
    };
    return runFailingRequest(data);
}

export async function sendErrorDataToCreateErrorCase(state: RootState): Promise<ErrorCaseCreationResponse> {
    const storedOpportunityId = getOpportunityIdLocalStorage(state);
    const oppId = state.opportunity.opportunityId ? state.opportunity.opportunityId : storedOpportunityId;

    const data: ErrorData = {
        opportunityId: oppId,
        accountId: state.account.id,
        contactId: '',
        subdomain: getSubdomain(),
        request: state.errorLogging.errorData.request,
        response: state.errorLogging.errorData.response,
        url: state.errorLogging.errorData.url,
    };
    return await runCreateErrorCase(data);
}

export async function sendUpdateErrorCase(state: RootState, errorCaseNumber: string): Promise<string> {
    const name = state.errorFormData.name;
    const phone = state.errorFormData.phone;
    const email = state.errorFormData.email;
    const subject = state.errorFormData.subject;
    const details = state.errorFormData.details;

    const data: ErrorCaseUpdate = {
        suppliedName: name,
        suppliedPhone: phone,
        suppliedEmail: email,
        subject: subject,
        description: details,
    };
    return await runUpdateErrorCase(errorCaseNumber, data);
}
