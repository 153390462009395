import React, { useState } from 'react';

import { InputLabel, Select } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ValueDisplay from 'static/models/ValueDisplay';

const SelectComponent: React.FunctionComponent<PropShape> = props => {
    const [currentValue, setCurrentValue] = useState(props.value);

    const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setCurrentValue(e.target.value);
        props.onChange(e);
    };

    const renderOptions = () =>
        props.options?.map((option, i) => (
            <option
                key={`${props.formName}.${props.fieldName}.${i}`}
                id={`option-${props.formName}.${props.fieldName}.${option.display.replace(' ', '')}`}
                value={option.value}
            >
                {option.display}
            </option>
        ));

    const shouldShowError = (): boolean => {
        return props.showError && !!props.errorMessage;
    };

    return (
        <div>
            <InputLabel shrink htmlFor={`select-${props.formName}-${props.fieldName}`}>
                {props.labelString}
            </InputLabel>
            <Select
                native
                fullWidth
                id={`select-${props.formName}-${props.fieldName}`}
                name={props.fieldName}
                value={currentValue || ''}
                error={shouldShowError()}
                onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleOnChange(e)}
                onBlur={(e: React.FocusEvent<HTMLInputElement>) => props.onBlur(e)}
            >
                {!props.hideEmptyOption && (
                    <option
                        disabled={false}
                        hidden={false}
                        key={`${props.formName}-${props.fieldName}`}
                        id={`option-${props.formName}-${props.fieldName}`}
                        value={''}
                    ></option>
                )}
                {renderOptions()}
            </Select>
            {shouldShowError() && <Alert color="error">{props.errorMessage}</Alert>}
        </div>
    );
};

interface PropShape {
    // Shape of passed in props
    formName: string;
    fieldName: string;
    value?: any;
    labelString?: string;
    options: ValueDisplay[];
    hideEmptyOption?: boolean;
    showError?: boolean;
    errorMessage?: string;
    onChange?: React.ChangeEventHandler<HTMLSelectElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
}

SelectComponent.defaultProps = {
    // Set default values for props
    value: '',
    hideEmptyOption: false,
    labelString: '',
    onChange: () => {},
    onBlur: () => {},
};

export default SelectComponent;
