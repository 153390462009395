import { RootState } from 'redux/root';
import urlPaths from 'static/constants/enums/urlPaths';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import { sendFailingRequest } from 'toolboxes/server-application-buffer/opportunity-data';

export default function SendingFailingRequestFunctions(state: RootState): BlockingFunction[] {
    const functionsToRun: BlockingFunction[] = [];

    functionsToRun.push({
        promiseGenerator: async () => {
            await sendFailingRequest(state);
        },
        blocksPageStarts: [urlPaths.sendingFailingRequest],
    });

    return functionsToRun;
}
