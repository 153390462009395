import React from 'react';

import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import ProductGridListComponent from 'components/global-components/product-grid-component/ProductGridListComponent';
import { useFilteredProducts } from 'hooks/useFilteredProducts';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { getPackageText } from 'toolboxes/reuseable-logic/state-specific-language/package-language-change';

const OuterBurialContainerPageComponent: React.FunctionComponent = () => {
    const products = useFilteredProducts(null, 'Outer Burial Container');
    const { selectedAccount } = useSelectedAccount();

    const packageText = getPackageText(selectedAccount);

    return (
        <PageContentWrapperComponent
            title="Select an Outer Burial Container"
            subtitle={
                <>
                    Below you will find all of the outer burial container options we have for you to choose from. Based
                    on the {packageText.toLowerCase()} selected, an outer burial container may have been pre-selected
                    for you, but you can replace it with the option of your choice. Once you’ve made your selection,
                    click "Continue."
                </>
            }
        >
            <ProductGridListComponent products={products} productSubcategory="Outer Burial Container" />
        </PageContentWrapperComponent>
    );
};

export default OuterBurialContainerPageComponent;
