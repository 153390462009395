import React from 'react';

import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import PreferencesSelectionComponent from 'components/global-components/preferences-selection-component/PreferencesSelectionComponent';

const PreferencesPageComponent: React.FunctionComponent<PropShape> = () => {
    /*
    On the reroute functions
        Calls out to the jump to page calculator to determine which page should be the
        first page. If the calculated first page is the preferences page it'll keep them
        on this page, otherwise, sends them to the correct first page.
    */

    return (
        <PageContentWrapperComponent title="How can we help you plan?">
            <PreferencesSelectionComponent />
        </PageContentWrapperComponent>
    );
};
interface PropShape {
    // Shape of passed in props
}
PreferencesPageComponent.defaultProps = {
    // Set default values for props
};
export default PreferencesPageComponent;
