import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LeadContact } from 'static/models/LeadContact';

const initialState: LeadContact = {
    id: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    leadSource: 'Partner',
};

export { initialState as initialLeadContact };

const leadContact = createSlice({
    name: 'leadContact',
    initialState,
    reducers: {
        setLeadContact(state, action: PayloadAction<LeadContact>) {
            return action.payload;
        },
        updateLeadContact(state, action: PayloadAction<LeadContact>) {
            return { ...state, ...action.payload };
        },
    },
});

export const { setLeadContact, updateLeadContact } = leadContact.actions;
export default leadContact.reducer;
