import urlPaths from 'static/constants/enums/urlPaths';

const pagesThatRequireAnAccountSelected: string[] = [
    urlPaths.membershipQuestion,
    urlPaths.membershipVerification,
    urlPaths.membershipVerificationComplete,
    urlPaths.membershipSignUp,
    urlPaths.membershipPurchaseComplete,
];

export default (path: string): boolean => {
    return pagesThatRequireAnAccountSelected.some(page => path.startsWith(page));
};
