import { RoutingNode } from 'hooks/useRouteCalculator/routing-graph';

const outerBurialContainerPageRouting: RoutingNode = {
    forwardEdges: [],
    backwardEdges: [],
    forAllForwardEdges: state =>
        state.selectedProducts.filter(products => products.subCategory === 'Outer Burial Container').length > 0,
};

export default outerBurialContainerPageRouting;
