import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import {
    setSelectedProducts,
    addSelectedProduct,
    removeSelectedProduct,
    updateQuestionProducts,
} from 'redux/selectedProducts/root';
import Product from 'static/models/Product';
import { QuestionProducts } from 'static/models/QuestionProducts';

import { RootState } from '../../redux/root';

interface UseSelectedProductsReturnType {
    selectedProducts: Product[];
    setSelectedProducts: (ps: Product[]) => void;
    addSelectedProduct: (ps: Product) => void;
    removeSelectedProduct: (ps: Product) => void;
    updateQuestionProducts: (ps: QuestionProducts) => void;
}

export const useSelectedProducts = (): UseSelectedProductsReturnType => {
    const dispatch = useDispatch();

    return {
        selectedProducts: useSelector((state: RootState) => state.selectedProducts, shallowEqual),
        setSelectedProducts: (products: Product[]) => dispatch(setSelectedProducts(products)),
        addSelectedProduct: (product: Product) => dispatch(addSelectedProduct(product)),
        removeSelectedProduct: (product: Product) => dispatch(removeSelectedProduct(product)),
        updateQuestionProducts: (questionProducts: QuestionProducts) =>
            dispatch(updateQuestionProducts(questionProducts)),
    };
};
