import React from 'react';

import { Card, CardContent, Grid, makeStyles, Typography } from '@material-ui/core';
import ButtonComponent from 'components/global-components/layout-components/button-components/button-component/ButtonComponent';
import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { resetAction } from 'redux/root';
import urlPaths from 'static/constants/enums/urlPaths';
import { clearOpportunityIdLocalStorage } from 'toolboxes/reuseable-logic/local-storage-getters-setters';

const useStyles = makeStyles(theme => ({
    card: {
        margin: theme.spacing(1),
        position: 'relative',
        overflow: 'visible',
        borderRadius: 0,
    },
    cardBodyBold: {
        fontWeight: 'bold',
    },
    multilineRadioLabel: {
        display: 'inline-block',
        cursor: 'pointer',
        justifyContent: 'flex-start',
        textAlign: 'left',
        marginBottom: '1rem',
        backgroundColor: '#F0F0F0',
        '&:hover': {
            backgroundColor: '#a8a8a8',
            color: '#fff',
        },
    },
}));

const ReturningOpportunityPageComponent: React.FunctionComponent = () => {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();

    const resumePlanningFunction = () => {
        history.push(urlPaths.loadOpportunity);
        return;
    };

    const startOverFunction = () => {
        dispatch(resetAction());
        clearOpportunityIdLocalStorage();
        history.push(urlPaths.start);
        return;
    };

    return (
        <PageContentWrapperComponent title="Welcome Back">
            <Grid container direction="row" justifyContent="center" spacing={2}>
                <Grid item xs={12}>
                    <Card className={classes.card} elevation={0}>
                        <CardContent>
                            <ButtonComponent
                                id={`resume-planning-button`}
                                onClick={() => resumePlanningFunction()}
                                fullWidth
                                disableElevation
                                className={classes.multilineRadioLabel}
                            >
                                <Typography variant="body2" className={classes.cardBodyBold}>
                                    Resume Planning
                                </Typography>
                            </ButtonComponent>
                            <ButtonComponent
                                id={`start-over-button`}
                                onClick={() => startOverFunction()}
                                fullWidth
                                disableElevation
                                className={classes.multilineRadioLabel}
                            >
                                <Typography variant="body2" className={classes.cardBodyBold}>
                                    Start Over
                                </Typography>
                            </ButtonComponent>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </PageContentWrapperComponent>
    );
};

export default ReturningOpportunityPageComponent;
