import { AxiosResponse } from 'axios';

export function sendRequest<T>(axiosPromise: Promise<AxiosResponse<T>>, logErrors = true): Promise<T> {
    return new Promise<T>((resolve, reject) => {
        axiosPromise
            .then(response => {
                resolve(response.data);
            })
            .catch(error => {
                if (logErrors) {
                    console.error(error);
                }
                reject(error);
            });
    });
}
