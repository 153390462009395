import React from 'react';

import { Grid, Typography, makeStyles } from '@material-ui/core';
import SelectComponent from 'components/form-components/SelectComponent.ts/SelectComponent';
import TextBoxComponent from 'components/form-components/text-box-component/TextBoxComponent';
import ButtonComponent from 'components/global-components/layout-components/button-components/button-component/ButtonComponent';
import { useForm } from 'hooks/forms/useForm';
import { demoAppFormDataValidator } from 'hooks/forms/validators';
import { useDemoAppFormData } from 'hooks/useDemoAppFormData';
import DemoFormData from 'static/models/demo/demoFormData';
import ValueDisplay from 'static/models/ValueDisplay';
import { createLeadFromDemo } from 'toolboxes/server-application-buffer/lead-data';

const useStyles = makeStyles(theme => ({
    card: {
        margin: theme.spacing(1),
        position: 'relative',
        overflow: 'visible',
        borderRadius: 0,
    },
    video: {
        width: '100%',
    },
    cardBodyBold: {
        fontWeight: 'bold',
    },
    multilineRadioLabel: {
        display: 'inline-block',
        cursor: 'pointer',
        justifyContent: 'flex-start',
        textAlign: 'left',
        marginBottom: '1rem',
        backgroundColor: '#F0F0F0',
        '&:hover': {
            backgroundColor: '#a8a8a8',
            color: '#fff',
        },
    },
    resetButton: {
        width: 150,
    },
    titles: {
        width: '10%',
    },
    halfWidthBox: {
        clear: 'both',
        width: '48%',
    },
    NameBox: {
        clear: 'both',
        minWidth: '40%',
    },
}));

const DemoAppEndPageFormComponent: React.FunctionComponent<PropShape> = props => {
    const classes = useStyles();

    const { demoFormData, updateDemoFormData } = useDemoAppFormData();

    const titles: ValueDisplay[] = [
        { display: 'Mr.', value: 'Mr.' },
        { display: 'Ms.', value: 'Ms.' },
        { display: 'Mrs.', value: 'Mrs.' },
        { display: 'Dr.', value: 'Dr.' },
        { display: 'Prof.', value: 'Prof.' },
    ];

    const { formData, handleOnChange, handleOnBlur } = useForm<DemoFormData>(
        demoFormData, //inital data
        updateDemoFormData, // hook update
        demoAppFormDataValidator, // validator
    );

    return (
        <Grid style={{ padding: '5%' }} container direction="column" spacing={7}>
            <Grid
                container
                item
                direction="row"
                spacing={5}
                justifyContent="space-between"
                alignItems="baseline"
                wrap="nowrap"
            >
                <SelectComponent
                    formName="demoFormData"
                    fieldName={formData.title?.name}
                    labelString="Title"
                    value={formData.title?.value}
                    showError={formData.title?.touched}
                    errorMessage={formData.title?.error}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                    options={titles}
                />
                <TextBoxComponent
                    formName="demoFormData"
                    className={classes.NameBox}
                    fieldName={formData.firstName?.name}
                    labelString="First Name"
                    value={formData.firstName?.value}
                    showError={formData.firstName?.touched}
                    errorMessage={formData.firstName?.error}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                />
                <TextBoxComponent
                    formName="demoFormData"
                    className={classes.NameBox}
                    fieldName={formData.lastName?.name}
                    labelString="Last Name"
                    value={formData.lastName?.value}
                    showError={formData.lastName?.touched}
                    errorMessage={formData.lastName?.error}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                />
            </Grid>
            <Grid container item direction="row" spacing={5} justifyContent="space-between" alignItems="baseline">
                <TextBoxComponent
                    formName="demoFormData"
                    className={classes.halfWidthBox}
                    fieldName={formData.email?.name}
                    labelString="Email"
                    value={formData.email?.value}
                    showError={formData.email?.touched}
                    errorMessage={formData.email?.error}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                />
                <TextBoxComponent
                    formName="demoFormData"
                    className={classes.halfWidthBox}
                    fieldName={formData.phone?.name}
                    labelString="Phone"
                    value={formData.phone?.value}
                    showError={formData.phone?.touched}
                    errorMessage={formData.phone?.error}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                />
            </Grid>
            <Grid container item direction="row" spacing={5} justifyContent="space-between" alignItems="baseline">
                <TextBoxComponent
                    formName="demoFormData"
                    className={classes.halfWidthBox}
                    fieldName={formData.company?.name}
                    labelString="Funeral Home Name"
                    value={formData.company?.value}
                    showError={formData.company?.touched}
                    errorMessage={formData.company?.error}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                />
                <TextBoxComponent
                    formName="demoFormData"
                    className={classes.halfWidthBox}
                    fieldName={formData.website?.name}
                    labelString="Website"
                    value={formData.website?.value}
                    showError={formData.website?.touched}
                    errorMessage={formData.website?.error}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                />
            </Grid>
            <Grid container item direction="row" spacing={5} justifyContent="space-between" alignItems="baseline">
                <TextBoxComponent
                    formName="demoFormData"
                    className={classes.halfWidthBox}
                    fieldName={formData.callsPerYear?.name}
                    labelString="Number of Calls Per Day"
                    value={formData.callsPerYear?.value}
                    showError={formData.callsPerYear?.touched}
                    errorMessage={formData.callsPerYear?.error}
                    onChange={handleOnChange}
                    onBlur={handleOnBlur}
                />
                <div className={classes.halfWidthBox}></div>
            </Grid>
            <Grid container item direction="row" justifyContent="center" alignItems="center">
                <ButtonComponent
                    id={`send-lead`}
                    style={{ marginBottom: '15px', backgroundColor: '#4B8607' }}
                    onClick={async () => {
                        await createLeadFromDemo(formData);
                        props.reset();
                    }}
                    fullWidth
                    disableElevation
                    className={classes.resetButton}
                >
                    <Typography style={{ color: '#fff' }} variant="body2">
                        Submit
                    </Typography>
                </ButtonComponent>
            </Grid>
        </Grid>
    );
};

export default DemoAppEndPageFormComponent;

interface PropShape {
    reset(): void;
}

DemoAppEndPageFormComponent.defaultProps = {
    reset: () => {},
};
