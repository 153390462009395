import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import ErrorData from 'static/models/errorLogging/errorData';
import ErrorLogging from 'static/models/errorLogging/errorLogging';

const initialState: ErrorLogging = {
    errorData: null,
    errorCaseCreationFailed: false,
    errorCaseNumber: '',
    errorCaseId: '',
};

export { initialState as initialErrorLogging };

const ErrorLoggingSlice = createSlice({
    name: 'errorLogging',
    initialState,
    reducers: {
        setErrorLogging(state, action: PayloadAction<ErrorLogging>) {
            return action.payload;
        },
        setErrorData(state, action: PayloadAction<ErrorData>) {
            return { ...state, errorData: action.payload };
        },
        setErrorCaseCreationFailed(state, action: PayloadAction<boolean>) {
            return { ...state, errorCaseCreationFailed: action.payload };
        },
        setErrorCaseNumber(state, action: PayloadAction<string>) {
            return { ...state, errorCaseNumber: action.payload };
        },
        setErrorCaseId(state, action: PayloadAction<string>) {
            return { ...state, errorCaseId: action.payload };
        },
    },
});

export const { setErrorLogging, setErrorData, setErrorCaseCreationFailed, setErrorCaseNumber, setErrorCaseId } =
    ErrorLoggingSlice.actions;
export default ErrorLoggingSlice.reducer;
