import { AnyAction, combineReducers, createAction } from '@reduxjs/toolkit';

import account, { initialAccount } from './account/root';
import availableAccounts, { initialAvailableAccounts } from './availableAccounts/root';
import isDemoApp, { initialIsDemoApp } from './demo-app/root';
import demoFormData, { initialDemoFormData } from './demoApp/root';
import errorFormData, { initialErrorFormData } from './errorLogging/errorFormData/root';
import errorLogging, { initialErrorLogging } from './errorLogging/root';
import featureFlags, { initialFlags } from './featureFlags/root';
import leadContact, { initialLeadContact } from './leadContact/root';
import membership, { initialMembership } from './membership/root';
import opportunity, { initialOpportunity } from './opportunity/root';
import pickedPreferences, { initialPickedPreferences } from './picked-preferences/root';
import pickupLocation, { initialPickupLocation } from './pickupLocation/root';
import availablePackages, { initialAvailablePackages } from './product-package-questions/availablePackages/root';
import availableProductQuestions, {
    initialAvailableProductQuestions,
} from './product-package-questions/availableProductQuestions/root';
import availableProducts, { initialAvailableProducts } from './product-package-questions/availableProducts/root';
import productQuestionResponses, {
    initialProductQuestionsResponses,
} from './product-package-questions/productQuestionResponses/root';
import selectedPackage, { initialSelectedPackage } from './selectedPackage/root';
import selectedProducts, { initialSelectedProducts } from './selectedProducts/root';
import availableTravelPackages, {
    initialAvailableTravelPackages,
} from './travel-protection/availableTravelPackages/root';
import selectedTravelPackage, {
    initialSelectedTravelPackageState,
} from './travel-protection/selectedTravelPackage/root';
import travelOpportunity, { initialTravelOpportunity } from './travel-protection/travelOpportunity/root';
import travelProvider, { initialTravelProvider } from './travel-protection/travelProvider/root';
import urlParamTracking, { initialParams } from './urlParamTracking/root';

const rootCombinedReducer = combineReducers({
    account,
    featureFlags,
    opportunity,
    pickedPreferences,
    availableAccounts,
    selectedPackage,
    selectedProducts,
    availableProducts,
    availablePackages,
    availableProductQuestions,
    productQuestionResponses,
    pickupLocation,
    availableTravelPackages,
    travelProvider,
    selectedTravelPackage,
    travelOpportunity,
    errorLogging,
    errorFormData,
    demoFormData,
    urlParamTracking,
    membership,
    isDemoApp,
    leadContact,
});

export const initialState: RootState = {
    account: initialAccount,
    featureFlags: initialFlags,
    opportunity: initialOpportunity,
    pickedPreferences: initialPickedPreferences,
    availableAccounts: initialAvailableAccounts,
    selectedPackage: initialSelectedPackage,
    selectedProducts: initialSelectedProducts,
    availableProducts: initialAvailableProducts,
    availablePackages: initialAvailablePackages,
    availableProductQuestions: initialAvailableProductQuestions,
    productQuestionResponses: initialProductQuestionsResponses,
    pickupLocation: initialPickupLocation,
    availableTravelPackages: initialAvailableTravelPackages,
    travelProvider: initialTravelProvider,
    selectedTravelPackage: initialSelectedTravelPackageState,
    travelOpportunity: initialTravelOpportunity,
    errorLogging: initialErrorLogging,
    errorFormData: initialErrorFormData,
    demoFormData: initialDemoFormData,
    urlParamTracking: initialParams,
    membership: initialMembership,
    isDemoApp: initialIsDemoApp,
    leadContact: initialLeadContact,
};

// rootReducer action to reset the store
export const resetAction = createAction('reset');

export const rootReducer = (state: RootState, action: AnyAction): any => {
    // if the resetAction was dispatched, set state to undefined to reset the store
    if (resetAction.match(action)) {
        const { featureFlags } = state;
        return rootCombinedReducer({ ...initialState, featureFlags }, action);
    }

    return rootCombinedReducer(state, action);
};

export type RootState = ReturnType<typeof rootCombinedReducer>;
