import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Package from 'static/models/Package';

const initialState: Package[] = [];

export { initialState as initialAvailableTravelPackages };

const availableTravelPackagesSlice = createSlice({
    name: 'availableTravelPackages',
    initialState,
    reducers: {
        setAvailableTravelPackages(state, action: PayloadAction<Package[]>) {
            return action.payload;
        },
    },
});

export const { setAvailableTravelPackages } = availableTravelPackagesSlice.actions;
export default availableTravelPackagesSlice.reducer;
