enum urlPaths {
    casket = '/casket',
    demographicsInstructions = '/demographicsInstructions',
    disposition = '/disposition',
    earlyPriceListDisclaimer = '/early/priceListDisclaimer',
    limitedEnhancements = '/limitedEnhancements',
    enhancements = '/enhancements',
    error = '/error',
    forceNavigation = '/forceNavigation',
    initialLoadAndCreate = '/initialLoad',
    loadOpportunity = '/loadOpportunity',
    locationSelection = '/locationSelection',
    outerBurialContainer = '/outerBurialContainer',
    packages = '/packages',
    preferences = '/preferences',
    productQuestions = '/productQuestions',
    start = '/start',
    pickupLocation = '/transfer',
    travelProtection = '/travelProtection',
    urn = '/urn',
    orderSummary = '/orderSummary',
    timingNotAvailable = '/timingNotAvailable',
    priceListDisclaimer = '/priceListDisclaimer',
    returningOpportunity = '/returning',
    sendingFailingRequest = '/sendingFailingRequest',
    errorSubmission = '/errorSubmission',
    membershipQuestion = '/membership/question',
    membershipVerification = '/membership/verification',
    demoAppEndPageComponent = '/end',
    membershipSignUp = '/membership/new-member',
    membershipPurchaseComplete = '/membership/confirmation',
    membershipVerificationComplete = '/membership/signin',
    timingGate = '/timingGate',
}

export default urlPaths;
