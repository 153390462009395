import React, { useState } from 'react';

import { EfModal } from '@efuneral/core-ui';
import { Card, CardActions, CardMedia, makeStyles, Theme, Typography } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import clsx from 'clsx';
import CardTagComponent from 'components/global-components/layout-components/ui-components/card-tag-component/CardTagComponent';
import Product from 'static/models/Product';
import { getFormattedPriceForProduct } from 'toolboxes/reuseable-logic/products/productFunctions';

import ActionBar from './ActionBar';
import ProductCardContent from './ProductCardContent';
import { DescriptionOverflowType } from './types';

const useStyles = makeStyles<Theme, PublicStyleProps>(theme => ({
    card: {
        fontSize: '0.875rem',
        position: 'relative',
        margin: '0 auto',
        overflow: 'visible',
        borderRadius: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxWidth: ({ maxWidth, fixedWidth }) => (fixedWidth ? 'none' : maxWidth),
        width: ({ fixedWidth, width }) => (fixedWidth ? width : null),
        [theme.breakpoints.up('md')]: {
            width: ({ fixedWidth, width }) => (fixedWidth ? width : 215),
        },
    },
    cardTag: {
        position: 'absolute',
        top: -theme.spacing(1),
        left: -theme.spacing(1),
    },
    mediaContainer: {
        display: 'flex',
        alignItems: 'flex-start',
        height: 150,
        position: 'relative',
    },
    mediaContainerPadding: {
        padding: theme.spacing(1),
    },
    mediaPlaceholder: {
        height: '100%',
        width: '100%',
        background: theme.palette.background.default,
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
        textTransform: 'uppercase',
        wordWrap: 'break-word',
    },
    media: {
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        maxWidth: '100%',
        objectFit: 'contain',
    },
    hoveredMediaZoomOverlay: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        backgroundColor: 'rgba(0, 0, 0, .75)',
        position: 'absolute',
        top: '0px',
        left: '0px',
    },
    nonHoveredMediaZoomOverlay: {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        cursor: 'pointer',
        position: 'absolute',
        top: '0px',
        left: '0px',
    },
    hoveredMediaZoom: {
        color: '#FFFFFF',
    },
    nonHoveredMediaZoom: {
        color: '#FFFFFF',
        backgroundColor: 'rgba(0, 0, 0, .75)',
        width: '25px',
        height: '25px',
        borderRadius: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inspectedMedia: {
        width: '100%',
        height: '100%',
        maxHeight: '100%',
        maxWidth: '100%',
        objectFit: 'contain',
    },
}));

const ProductCardComponent: React.FunctionComponent<PropShape> = ({
    cardAction,
    descriptionOverflow,
    fixedWidth,
    width,
    maxWidth,
    contentHeight,
    onOpen,
    product,
    selected,
    showGuaranteed,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [hasOverflow, setHasOverflow] = useState(false);
    const [inspectingMedia, setInspectingMedia] = useState(false);
    const [isHoveringMedia, setIsHoveringMedia] = useState(false);
    const classes = useStyles({
        maxWidth,
        contentHeight,
        fixedWidth,
        width,
    });
    const handleExpand = () => {
        if (descriptionOverflow === 'expand' && !isExpanded) {
            setIsExpanded(true);
        }
    };
    const handleCollapse = () => {
        if (descriptionOverflow === 'expand' && isExpanded) {
            setIsExpanded(false);
        }
    };

    return (
        <>
            <EfModal open={inspectingMedia} onClose={() => setInspectingMedia(false)}>
                <div
                    style={{
                        maxHeight: '80vh',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '100%',
                        margin: '1rem',
                    }}
                    onClick={() => setInspectingMedia(false)}
                    aria-label={`enlarged ${product.name} image modal`}
                >
                    <img
                        className={classes.inspectedMedia}
                        src={product.imageURL}
                        alt={`enlarged ${product.name} image`}
                    />
                </div>
            </EfModal>
            <Card className={classes.card} elevation={0}>
                <div className={clsx(classes.mediaContainer, { [classes.mediaContainerPadding]: !product.imageURL })}>
                    {product.imageURL ? (
                        <>
                            <CardMedia
                                className={classes.media}
                                image={product.imageURL}
                                component="img"
                                onClick={() => setInspectingMedia(true)}
                                alt={`${product.name} image`}
                            />
                            {isHoveringMedia ? (
                                <div
                                    className={classes.hoveredMediaZoomOverlay}
                                    onMouseLeave={() => setIsHoveringMedia(false)}
                                    onClick={() => setInspectingMedia(true)}
                                    aria-label={`enlarge ${product.name} image`}
                                >
                                    <div className={classes.hoveredMediaZoom}>
                                        <SearchIcon fontSize={'large'} />
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className={classes.nonHoveredMediaZoomOverlay}
                                    onMouseEnter={() => setIsHoveringMedia(true)}
                                    onClick={() => setInspectingMedia(true)}
                                    aria-label={`enlarge ${product.name} image`}
                                >
                                    <div className={classes.nonHoveredMediaZoom}>
                                        <SearchIcon fontSize={'small'} />
                                    </div>
                                </div>
                            )}
                        </>
                    ) : (
                        <div className={classes.mediaPlaceholder}>
                            <Typography variant="body2">{product.name}</Typography>
                        </div>
                    )}
                </div>
                <ProductCardContent
                    product={product}
                    isExpanded={isExpanded}
                    descriptionOverflow={descriptionOverflow}
                    height={contentHeight}
                    showGuaranteed={showGuaranteed}
                    onContentOverflowChange={setHasOverflow}
                />
                <CardActions disableSpacing>
                    <ActionBar
                        descriptionOverflow={descriptionOverflow}
                        isExpanded={isExpanded}
                        hasOverflow={hasOverflow}
                        formattedPrice={getFormattedPriceForProduct(product)}
                        onExpand={handleExpand}
                        onCollapse={handleCollapse}
                        onOpen={() => onOpen(product)}
                    >
                        {cardAction}
                    </ActionBar>
                </CardActions>
                {selected && <CardTagComponent text="IN CART" className={classes.cardTag} />}
            </Card>
        </>
    );
};

interface PublicStyleProps {
    fixedWidth?: boolean;
    width?: string | number;
    maxWidth?: string | number;
    contentHeight?: string | number;
}

interface PropShape extends PublicStyleProps {
    descriptionOverflow: DescriptionOverflowType;
    cardAction: JSX.Element;
    product: Product;
    selected?: boolean;
    showGuaranteed?: boolean;
    onOpen?: (p: Product) => void;
}

ProductCardComponent.defaultProps = {
    selected: false,
    showGuaranteed: false,
    onOpen: () => {},
    contentHeight: '12.5em',
    fixedWidth: false,
};

export default ProductCardComponent;
