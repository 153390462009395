import React from 'react';

import { Button, makeStyles } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import ShowMoreModalIconComponent from 'components/icon-components/show-more-modal-icon-component/ShowMoreModalIconComponent';

import { DescriptionOverflowType } from './types';

const useStyles = makeStyles(theme => ({
    button: {
        color: theme.palette.primary.main,
        fontSize: '1em',
        padding: 0,
        textDecoration: 'underline',
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'transparent',
        },
    },
    icon: {
        fontSize: 'inherit',
    },
}));

const ExpansionButton: React.FunctionComponent<PropShape> = ({
    descriptionOverflow,
    isExpanded,
    onCollapse,
    onExpand,
    onOpen,
}) => {
    const classes = useStyles();
    const commonProps = {
        disableRipple: true,
        className: classes.button,
    };
    if (descriptionOverflow === 'open') {
        return (
            <Button {...commonProps} onClick={onOpen}>
                Show more details <ShowMoreModalIconComponent className={classes.icon} />
            </Button>
        );
    }
    if (descriptionOverflow === 'expand' && isExpanded) {
        return (
            <Button {...commonProps} onClick={onCollapse}>
                Show fewer details <ExpandLess className={classes.icon} />
            </Button>
        );
    }
    return (
        <Button {...commonProps} onClick={onExpand}>
            Show more details <ExpandMore className={classes.icon} />
        </Button>
    );
};

interface PropShape {
    descriptionOverflow: Exclude<DescriptionOverflowType, 'scroll'>;
    isExpanded: boolean;
    onOpen: () => void;
    onExpand: () => void;
    onCollapse: () => void;
}

export default ExpansionButton;
