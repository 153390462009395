import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import Package from '../../../static/models/Package';

const initialPackage: Package = {
    id: '',
    accountId: '',
    name: '',
    price: 0,
    description: '',
    displayOrder: null,
    imageURL: '',
    disposition: null,
    packageSelectionType: null,
    addonSelectionType: null,
    status: '',
    products: [],
    packageProducts: [],
    limitedProducts: [],
    defaultProducts: [],
    includedProducts: [],
};

interface SelectedTravelPackageState {
    package: Package;
    declinedTravelProtection: boolean;
}

export const initialSelectedTravelPackageState: SelectedTravelPackageState = {
    package: initialPackage,
    declinedTravelProtection: false,
};

export { initialPackage as initialSelectedTravelPackage };

const selectedTravelPackageSlice = createSlice({
    name: 'selectedTravelPackage',
    initialState: initialSelectedTravelPackageState,
    reducers: {
        setSelectedTravelPackage(state, action: PayloadAction<Package>) {
            state.package = action.payload;
        },
        setDeclinedTravelProtection(state, action: PayloadAction<boolean>) {
            state.declinedTravelProtection = action.payload;
        },
    },
});

export const { setSelectedTravelPackage, setDeclinedTravelProtection } = selectedTravelPackageSlice.actions;
export default selectedTravelPackageSlice.reducer;
