import React from 'react';

import PackageSelectionComponent from 'components/global-components/package-selection-component/PackageSelectionComponent';
import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import { useOpportunity } from 'hooks/useOpportunity';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { getPackageText } from 'toolboxes/reuseable-logic/state-specific-language/package-language-change';

import PriceListDisclaimerModal from './PriceListDisclaimerModal';

/*
On page exit
    Save selected package to opportunity
*/
const PackagePageComponent: React.FunctionComponent<PropShape> = () => {
    const { opportunity } = useOpportunity();
    const { selectedAccount } = useSelectedAccount();

    const packageText = getPackageText(selectedAccount);

    return (
        <PageContentWrapperComponent
            title={`Select Your ${opportunity.disposition} ${packageText}`}
            subtitle={
                <>
                    Below are our available {packageText.toLowerCase()} options to choose from, each providing solutions
                    based on the types of goods or services you may be looking for. Once a {packageText.toLowerCase()}{' '}
                    is selected, you will be able to upgrade or add additional items to your arrangement. If you don't
                    see an offering that suits you please contact us and one of our team members will assist you in
                    customizing a solution.
                </>
            }
        >
            <PriceListDisclaimerModal />
            <PackageSelectionComponent />
        </PageContentWrapperComponent>
    );
};
interface PropShape {
    // Shape of passed in props
}
PackagePageComponent.defaultProps = {
    // Set default values for props
};
export default PackagePageComponent;
