import React, { useEffect, useMemo, useState } from 'react';

import { CircularProgress, GridList, makeStyles, Typography } from '@material-ui/core';
import LocationSearchComponent from 'components/global-components/location-search-component/LocationSearchComponent';
import LocationSelectionCardComponent from 'components/global-components/location-selection-card-component/LocationSelectionCardComponent';
import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import { useAvailableAccounts } from 'hooks/useAvailableAccounts';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { useUrlParams } from 'hooks/useUrlParams';
import TagManager from 'react-gtm-module';
import { useHistory } from 'react-router-dom';
import urlPaths from 'static/constants/enums/urlPaths';
import { REFERRAL_ACCOUNT_IDENTIFIER_PARAM_KEY } from 'static/constants/UrlParamKeys';
import { AccountWithDistance } from 'static/models/AccountWithDistance';
import { AutocompletedLocation } from 'static/models/LocationAutocomplete';
import { getSubdomain } from 'toolboxes/reuseable-logic/get-subdomain';
import {
    getMembershipQuestionSkipSessionStorage,
    getReferralAccountIdentifier,
} from 'toolboxes/reuseable-logic/local-storage-getters-setters';
import { createNavigationURL } from 'toolboxes/reuseable-logic/navigation';
import { getDistanceToLocation } from 'toolboxes/server-application-buffer/location-data';

const useStyles = makeStyles({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        margin: '0 auto',
    },
    gridList: {
        height: '70vh',
    },
    loadingSpinner: {
        display: 'flex',
        alignContent: 'center',
    },
});

const useDefaultSortedAccounts = () => {
    const availableAccounts = useAvailableAccounts().map<AccountWithDistance>(a => ({ ...a, distance: 0 }));
    const [cachedAccounts] = useState(availableAccounts); // we cache the accounts here so the useMemo doesn't rerun on a rerender
    return useMemo(() => {
        // first build a set of account IDs
        const idSet = new Map<string, any>();
        cachedAccounts.forEach(a => idSet.set(a.id, {}));
        // then sort the accounts
        let sortedAccounts: AccountWithDistance[] = [];
        for (let i = 0; i < cachedAccounts.length; i++) {
            if (idSet.has(cachedAccounts[i].parentId)) {
                // one of the account IDs matches this account's parentId, so it's a child
                // children go last
                sortedAccounts = [...sortedAccounts, cachedAccounts[i]];
                continue;
            }
            // parents go first
            sortedAccounts = [cachedAccounts[i], ...sortedAccounts];
        }
        return sortedAccounts;
    }, [cachedAccounts]);
};

const useSortAccountsByDistance = (selectedLocation: AutocompletedLocation) => {
    const availableAccounts = useDefaultSortedAccounts();
    const [displayAccounts, setDisplayAccounts] = useState<AccountWithDistance[]>(availableAccounts);
    const [isLoading, setIsLoading] = useState(false);
    // sort the cards by distance to selected location; if no location is selected, put the parent first
    useEffect(() => {
        if (selectedLocation) {
            setIsLoading(true);
            const fetchDistances = async () => {
                const promises: Promise<void>[] = [];
                const accountsWithDistance: AccountWithDistance[] = Array(availableAccounts.length);
                availableAccounts.forEach((account, i) =>
                    promises.push(
                        getDistanceToLocation(selectedLocation, account, 'imperial').then(d => {
                            accountsWithDistance[i] = { ...account, distance: d };
                        }),
                    ),
                );
                await Promise.all(promises);
                setDisplayAccounts(accountsWithDistance.sort((a, b) => a.distance - b.distance));
            };
            fetchDistances().then(() => setIsLoading(false));
        }
    }, [availableAccounts, selectedLocation]);
    return { displayAccounts, isLoading };
};

const LocationSelectionComponent: React.FunctionComponent<PropShape> = () => {
    const classes = useStyles();
    const [selectedLocation, setSelectedLocation] = useState<AutocompletedLocation>(null);
    const { displayAccounts, isLoading } = useSortAccountsByDistance(selectedLocation);
    const { setSelectedAccount } = useSelectedAccount();
    const { urlParams } = useUrlParams();
    const history = useHistory();

    //  Technically, the parent accounts are all first in the displayAccounts array. So, assuming
    //  a one-to-many parent-to-child relationship, we could just grab the first account in the array.
    //  However, I don't know if this is the true for all accounts, so this is just to be safe.
    const uniqueParentIds = Array.from(new Set(displayAccounts.map(account => account.parentId)));
    const parentAccounts = [];

    for (const parentId of uniqueParentIds) {
        const account = displayAccounts?.find(x => x?.id === parentId);
        if (account) {
            parentAccounts.push(displayAccounts?.find(x => x?.id === parentId));
        }
    }

    //  We should only set the tag manager ID if there's only one parent.
    //  If there are multiple, then a discussion needs to be had as to which
    //  one we want to use.
    let gtmId = '';
    if (parentAccounts?.length === 1) {
        gtmId = parentAccounts[0]?.googleTagManagerId;
    }

    const handleAccountSelect = (index: number) => {
        if (getSubdomain() === displayAccounts[index].subdomain) {
            setSelectedAccount(displayAccounts[index]);
            history.push(urlPaths.start);
            return;
        }
        let path = '';
        if (getMembershipQuestionSkipSessionStorage()) {
            path = urlPaths.start;
        }
        if (getReferralAccountIdentifier()) {
            path += `?${REFERRAL_ACCOUNT_IDENTIFIER_PARAM_KEY}=${getReferralAccountIdentifier()}`;
        }

        // we don't need to set the selected account because that will be picked up from the subdomain
        // when we go through /start
        window.location.href = createNavigationURL(
            window.location.protocol,
            window.location.host,
            displayAccounts[index].subdomain,
            // don't need to navigate to preferences because this redirection will go to /start with the new subdomain
            // which will get us into the correct spot of the flow automatically
            path,
            urlParams,
        );
    };

    const renderDistance = (account: AccountWithDistance) => {
        if (isLoading) {
            return (
                <div className={classes.loadingSpinner}>
                    <CircularProgress />
                </div>
            );
        }
        return (
            <Typography variant="body2" align="center" color="primary">
                {account.distance > 0 && `${account.distance.toFixed(1)} mi`}
            </Typography>
        );
    };

    //  Throw initialization into a useEffect similar to how
    //  we do it in App.tsx.
    useEffect(() => {
        if (gtmId) {
            const tagManagerArgs = {
                gtmId,
                dataLayerName: 'accountDataLayer',
            };
            TagManager.initialize(tagManagerArgs);
        }
    });

    return (
        <PageContentWrapperComponent
            title="Select a location and get started"
            subtitle="Click on one of the locations below to get started with your online planning OR search for the nearest location by providing an address or landmark of your choice."
        >
            <LocationSearchComponent onLocationChange={loc => setSelectedLocation(loc)} />
            <div className={classes.root}>
                <GridList cellHeight={160} className={classes.gridList}>
                    {displayAccounts.map((a, i) => (
                        <LocationSelectionCardComponent
                            key={a.id}
                            logoUrl={`https://media.efuneral.com/account-logos/${a.subdomain}.png?version=1.1`}
                            streetAddress={a.address}
                            city={a.city}
                            state={a.state}
                            zip={a.zip}
                            distanceComponent={renderDistance(a)}
                            onSelect={() => handleAccountSelect(i)}
                        />
                    ))}
                </GridList>
            </div>
        </PageContentWrapperComponent>
    );
};
interface PropShape {
    // Shape of passed in props
}
LocationSelectionComponent.defaultProps = {
    // Set default values for props
};
export default LocationSelectionComponent;
