import { setOpportunity } from 'redux/opportunity/root';
import {
    initialProductQuestionsResponses,
    setProductQuestionResponses,
} from 'redux/product-package-questions/productQuestionResponses/root';
import { RootState } from 'redux/root';
import { initialSelectedPackage, setSelectedPackage } from 'redux/selectedPackage/root';
import { initialSelectedProducts, setSelectedProducts } from 'redux/selectedProducts/root';
import urlPaths from 'static/constants/enums/urlPaths';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import { updateOpportunity } from 'toolboxes/web-requests/specific-requests/opportunity-requests';

import { getAndDispatchAccountProductQuestions } from '../../../toolboxes/server-application-buffer/products-packages-questions-data';
import { getAndDispatchTravelData } from '../../../toolboxes/server-application-buffer/travel-data';

export function preferences(state: RootState, dispatch: Function): BlockingFunction[] {
    const functionsToRun: BlockingFunction[] = [];

    functionsToRun.push({
        promiseGenerator: async () => {
            await getAndDispatchAccountProductQuestions(state, dispatch);
        },
        blocksPageStarts: [urlPaths.packages],
    });

    functionsToRun.push({
        promiseGenerator: async () => {
            await getAndDispatchTravelData(state, dispatch);
        },
        blocksPageStarts: [urlPaths.packages],
    });

    functionsToRun.push({
        promiseGenerator: async () => {
            // if preferences have changed
            // we need to reset packages/products/product questions
            if (
                state.pickedPreferences.timing !== state.opportunity.timing ||
                state.pickedPreferences.disposition !== state.opportunity.disposition
            ) {
                // reset the store
                dispatch(setSelectedPackage(initialSelectedPackage));
                dispatch(setSelectedProducts(initialSelectedProducts));
                dispatch(setProductQuestionResponses(initialProductQuestionsResponses));
            }

            if (state.pickedPreferences.disposition) {
                dispatch(setOpportunity({ ...state.opportunity, ...state.pickedPreferences }));
                await updateOpportunity(state.account.id, {
                    opportunityId: state.opportunity.opportunityId,
                    ...state.pickedPreferences,
                });
            }
        },
        blocksPageStarts: [urlPaths.packages],
    });
    return functionsToRun;
}
