import React from 'react';

import { Card, CardContent, Typography } from '@material-ui/core';

const CardComponent: React.FunctionComponent<PropShape> = props => {
    return (
        <Card>
            {props.title && props.subtitle && (
                <CardContent>
                    {props.title && <Typography variant="h3">{props.title}</Typography>}
                    {props.subtitle && <Typography variant="body2">{props.subtitle}</Typography>}
                </CardContent>
            )}
            <CardContent>{props.children}</CardContent>
        </Card>
    );
};

interface PropShape {
    // Shape of passed in props
    title?: string;
    subtitle?: React.ReactNode;
}

CardComponent.defaultProps = {
    // Set default values for props
    title: '',
    subtitle: '',
};

export default CardComponent;
