import React from 'react';

import { useDispatch } from 'react-redux';
import { resetAction } from 'redux/root';
import { clearOpportunityIdLocalStorage } from 'toolboxes/reuseable-logic/local-storage-getters-setters';

import MockLinkComponent from '../layout-components/mock-link-component/MockLinkComponent';

const ResetSessionButtonComponent: React.FunctionComponent<PropShape> = props => {
    const dispatch = useDispatch();

    const resetSession = () => {
        dispatch(resetAction());
        clearOpportunityIdLocalStorage();
        window.location.pathname = '/';
    };

    return (
        <MockLinkComponent id={props.id} className={props.className} onClick={resetSession}>
            Reset Session
        </MockLinkComponent>
    );
};
interface PropShape {
    // Shape of passed in props
    id: string;
    className?: string;
}

ResetSessionButtonComponent.defaultProps = {
    // Set default values for props
    className: undefined,
};

export default ResetSessionButtonComponent;
