import { ignoredRoutes } from 'hooks/useRouteCalculator/ignored-routes';

export const shouldShowDemoCustomComponents = (isDemoApp: boolean): boolean => {
    if (isDemoApp === false) return false; //ambigious usage allow, assume is demo if calling it

    const currentPageURLSplit = window.location.href.split('/');
    const currentPath = currentPageURLSplit[currentPageURLSplit.length - 1];

    return (
        !(ignoredRoutes.indexOf('/' + currentPath) > -1) && //Ignored pages should not
        currentPath !== 'preferences' // starting page should not show
    );
};
