import React from 'react';

import { useTheme } from '@material-ui/core';

const CardTagComponent: React.FunctionComponent<PropShape> = props => {
    const theme = useTheme();

    return (
        <div className={props.className}>
            <svg xmlns="http://www.w3.org/2000/svg" width="125" height="33" viewBox="0 0 125 33">
                <path d="M0,0H125L95.75,33H0Z" fill={theme.palette.primary.main} />
                <text transform="translate(24 22)" fill="#fff" fontSize="14" fontFamily="SegoeUI, Segoe UI">
                    <tspan x="0" y="0">
                        {props.text}
                    </tspan>
                </text>
            </svg>
        </div>
    );
};

interface PropShape {
    // Shape of passed in props
    className: string;
    text?: string;
}

CardTagComponent.defaultProps = {
    // Set default values for props
    className: '',
    text: '',
};

export default CardTagComponent;
