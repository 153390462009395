import React, { useEffect, useState } from 'react';

import { makeStyles, Grid, Hidden, Divider } from '@material-ui/core';
import ExternalLinkComponent from 'components/global-components/external-link-component/ExternalLinkComponent';
import { useAvailableAccounts } from 'hooks/useAvailableAccounts';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { useLocation } from 'react-router';
import { EfuneralServiceTier } from 'static/constants/enums/efuneralServiceTier';
import urlPaths from 'static/constants/enums/urlPaths';
import { getSubdomain } from 'toolboxes/reuseable-logic/get-subdomain';

import EfuneralLogoSrc from '../../../../static/media/efuneral.png';
import HeaderCartComponent from './HeaderCartComponent';

// This is the Styling/Css make the classes and define the style in there
const useStyles = makeStyles(theme => ({
    headerLeft: {
        alignItems: 'flex-start',
        marginTop: '10px',
        paddingLeft: '20px',
        marginBottom: '10px',
    },
    headerRight: {
        textAlign: 'end',
        fontSize: '20px',
        fontWeight: 'bold',
    },
    logoImageHeader: {
        maxHeight: theme.custom.headerLogoMaxHeight ?? '4rem', // TODO Load this from somewhere. Needs set
        [theme.breakpoints.down('xs')]: {
            maxWidth: '90%',
        },
        display: 'inline',
    },
    headerGridWrapper: {
        alignItems: 'center',
        backgroundColor: theme.custom.headerBackgroundColor,
        padding: '5px',
        paddingLeft: '20px',
        paddingRight: '20px',
    },
    companyTitleStyling: {
        fontFamily: 'Lora, serif',
        fontSize: '1.75rem',
        color: theme.custom.headerFontColor,
        padding: '.5rem 1rem',
        textAlign: 'center',
    },
    logoDivider: {
        color: '#989b9c',
        height: '95%',
        width: '2px',
    },
}));

const HeaderComponent: React.FunctionComponent = () => {
    const classes = useStyles();
    const { selectedAccount } = useSelectedAccount();
    const availableAccounts = useAvailableAccounts();
    const [displayAccount, setDisplayAccount] = useState(selectedAccount);
    const location = useLocation();
    const { cobrandingStorefront } = useFeatureFlags();

    const isReturningOpportunityPage = location.pathname === urlPaths.returningOpportunity;

    useEffect(() => {
        if (selectedAccount.id.length > 0) {
            setDisplayAccount(selectedAccount);
            return undefined;
        }
        if (!availableAccounts || availableAccounts.length === 0) {
            return undefined;
        }
        if (selectedAccount?.id.length === 0) {
            const subdomain = getSubdomain();
            setDisplayAccount(availableAccounts.filter(a => a.subdomain === subdomain)[0]);
        }
    }, [selectedAccount, availableAccounts]);

    let showNameInHeader = true;

    try {
        showNameInHeader = JSON.parse(displayAccount.customColors)?.showNameInHeader;
    } catch (_e) {
        // Maintain true value
    }

    const gridSize = showNameInHeader ? 3 : 6;
    const cobrandStorefront = cobrandingStorefront && selectedAccount.efuneralServiceTier === EfuneralServiceTier.BASIC;

    return (
        <Grid container component="header" direction="row" className={classes.headerGridWrapper}>
            <Grid item container xs={6} sm={gridSize} className={`${classes.headerLeft}`}>
                <div>
                    <ExternalLinkComponent id="funeral-home-website-link" href={displayAccount.website}>
                        <Grid item container spacing={1} xs={12}>
                            {cobrandStorefront && (
                                <>
                                    <Grid item xs={5}>
                                        <img
                                            id="efuneral-logo"
                                            className={classes.logoImageHeader}
                                            src={EfuneralLogoSrc}
                                            alt={`${displayAccount.name} logo`}
                                            style={{ maxWidth: '100%' }}
                                        />
                                    </Grid>
                                    <Grid item xs={1}>
                                        <Divider orientation="vertical" className={classes.logoDivider} />
                                    </Grid>
                                    <Grid item xs={5}>
                                        <img
                                            id="funeral-home-logo"
                                            className={classes.logoImageHeader}
                                            src={`https://media.efuneral.com/account-logos/${displayAccount.subdomain}.png?version=1.1`}
                                            alt={`${displayAccount.name} logo`}
                                        />
                                    </Grid>
                                </>
                            )}
                            {!cobrandStorefront && (
                                <Grid item xs={6}>
                                    <img
                                        id="funeral-home-logo"
                                        className={classes.logoImageHeader}
                                        src={`https://media.efuneral.com/account-logos/${displayAccount.subdomain}.png?version=1.1`}
                                        alt={`${displayAccount.name} logo`}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </ExternalLinkComponent>
                </div>
            </Grid>
            {showNameInHeader && (
                <Hidden only="xs">
                    <Grid item xs={6} className={classes.companyTitleStyling}>
                        {displayAccount.name}
                    </Grid>
                </Hidden>
            )}
            <Grid item xs={6} sm={gridSize} className={classes.headerRight}>
                {!isReturningOpportunityPage && <HeaderCartComponent />}
            </Grid>
        </Grid>
    );
};

export default HeaderComponent;
