import React from 'react';

import { DialogActions, DialogContent, makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import ButtonComponent from 'components/global-components/layout-components/button-components/button-component/ButtonComponent';
import { useOpportunity } from 'hooks/useOpportunity';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { defaultDialogOnCloseEventHandler } from 'toolboxes/helpers/DialogHelpers';
import { getPricelistUrl, shouldShowPricelist } from 'toolboxes/reuseable-logic/pricelist';
import { acceptCPRADisclosure, acceptPriceListDisclaimer } from 'toolboxes/server-application-buffer/disclaimer-data';

import PriceListDisclaimerText from './PriceListDisclaimerText';

const useStyles = makeStyles({
    priceList: {
        width: '100%',
        minHeight: 500,
    },
});

const PriceListDisclaimerModal: React.FunctionComponent = () => {
    const { opportunity, setHidePriceListDisclaimer } = useOpportunity();
    const { selectedAccount } = useSelectedAccount();
    const classes = useStyles();

    const handleAccept = async () => {
        setHidePriceListDisclaimer(true);
        await acceptPriceListDisclaimer(opportunity.opportunityId);
        await acceptCPRADisclosure(opportunity.opportunityId);
    };

    return (
        <Dialog open={!opportunity.hidePriceListDisclaimer} onClose={defaultDialogOnCloseEventHandler}>
            <DialogContent>
                <PriceListDisclaimerText />
            </DialogContent>
            <DialogActions>
                <ButtonComponent id="accept-price-list-disclaimer" onClick={handleAccept}>
                    I understand
                </ButtonComponent>
            </DialogActions>
            <DialogContent>
                {shouldShowPricelist(selectedAccount) && (
                    <iframe
                        title="general-price-list"
                        src={getPricelistUrl(selectedAccount)}
                        className={classes.priceList}
                    />
                )}
            </DialogContent>
        </Dialog>
    );
};

export default PriceListDisclaimerModal;
