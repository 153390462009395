import urlPaths from 'static/constants/enums/urlPaths';

const pagesThatDontRequireAnOpportunity: string[] = [
    urlPaths.start,
    urlPaths.locationSelection,
    urlPaths.initialLoadAndCreate,
    urlPaths.loadOpportunity,
    urlPaths.returningOpportunity,
    urlPaths.membershipQuestion,
    urlPaths.membershipVerification,
    urlPaths.demoAppEndPageComponent,
    urlPaths.membershipVerificationComplete,
    urlPaths.membershipSignUp,
    urlPaths.membershipPurchaseComplete,
    // urlPaths.error,
    // urlPaths.forceNavigation
];

export default (path: string): boolean => {
    return !pagesThatDontRequireAnOpportunity.some(page => path.startsWith(page));
};
