import React from 'react';

import { Grid, Hidden, makeStyles, Typography } from '@material-ui/core';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { formatAccountAddress, formatPhoneNumber } from 'toolboxes/reuseable-logic/string-formatters';

import FooterLinks from './FooterLinks';

const footerFontSize = 14;
const useStyles = makeStyles(theme => ({
    footerBaseFontSize: {
        fontSize: footerFontSize,
    },
    container: {
        backgroundColor: theme.custom.footerBackgroundColor,
        justifyContent: 'space-around',
        padding: '4px 48px',
        textAlign: 'center',
        height: '7rem', // 5 lines
        [theme.breakpoints.up('md')]: {
            justifyContent: 'flex-start',
            textAlign: 'left',
            height: '4.5rem', // 3 lines
        },
    },
    footerRight: {
        textAlign: 'end',
    },
    footerText: {
        textDecoration: 'none',
        fontSize: footerFontSize,
        color: theme.custom.footerFontColor,
    },
}));

const FooterComponent: React.FunctionComponent<PropShape> = ({ showFuneralHomeNumber }) => {
    const classes = useStyles();
    const { selectedAccount } = useSelectedAccount();

    return (
        <div className={classes.footerBaseFontSize}>
            <Grid container direction="row" className={classes.container}>
                <Grid item sm={12} md={6}>
                    <Grid container direction="column">
                        <Grid item>
                            <Typography variant="body2" className={classes.footerText}>
                                {selectedAccount.name}
                                {selectedAccount.funeralHomeNumber &&
                                    showFuneralHomeNumber &&
                                    ` (FH#: ${selectedAccount.funeralHomeNumber})`}
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="body2" className={classes.footerText}>
                                {formatAccountAddress(selectedAccount)}
                            </Typography>
                            <Typography variant="body2" className={classes.footerText}>
                                Questions? {formatPhoneNumber(selectedAccount.phoneNumber)}
                            </Typography>
                        </Grid>
                        <Hidden mdUp>
                            <FooterLinks account={selectedAccount} textStyle={classes.footerText} />
                        </Hidden>
                    </Grid>
                </Grid>
                <Hidden smDown>
                    <Grid item md={6} className={classes.footerRight}>
                        <FooterLinks account={selectedAccount} textStyle={classes.footerText} />
                    </Grid>
                </Hidden>
            </Grid>
        </div>
    );
};

interface PropShape {
    showFuneralHomeNumber?: boolean;
}

FooterComponent.defaultProps = {
    showFuneralHomeNumber: false,
};

export default FooterComponent;
