import { useSelector, useDispatch } from 'react-redux';
import { setSelectedPackage } from 'redux/selectedPackage/root';
import Package from 'static/models/Package';

import { RootState } from '../../redux/root';

interface ReturnType {
    selectedPackage: Package;
    setSelectedPackage: (p: Package) => void;
}

export const useSelectedPackage = (): ReturnType => {
    const dispatch = useDispatch();

    return {
        selectedPackage: useSelector((state: RootState) => state.selectedPackage),
        setSelectedPackage: (p: Package) => dispatch(setSelectedPackage(p)),
    };
};
