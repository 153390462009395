import React from 'react';

import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import ProductGridListComponent from 'components/global-components/product-grid-component/ProductGridListComponent';
import { useFilteredProducts } from 'hooks/useFilteredProducts';
import { useOpportunity } from 'hooks/useOpportunity';
import { useSelectedAccount } from 'hooks/useSelectedAccount';
import { getPackageText } from 'toolboxes/reuseable-logic/state-specific-language/package-language-change';

const CasketPageComponent: React.FunctionComponent = () => {
    const products = useFilteredProducts(null, 'Urn');
    const { selectedAccount } = useSelectedAccount();
    const { opportunity } = useOpportunity();

    const packageText = getPackageText(selectedAccount);

    return (
        <PageContentWrapperComponent
            title="Select an Urn"
            subtitle={
                <>
                    Below you will find all of the urn options we have for you to choose from. Based on the{' '}
                    {opportunity.disposition.toLowerCase()} {packageText.toLowerCase()} you selected, an urn may have
                    been pre-selected for you, but you can replace it with the option of your choice. Once you’ve made
                    your selection, click "Continue."
                </>
            }
        >
            <ProductGridListComponent products={products} productSubcategory="Urn" />
        </PageContentWrapperComponent>
    );
};

export default CasketPageComponent;
