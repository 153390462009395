import { useSelector, useDispatch } from 'react-redux';
import { setHidePriceListDisclaimer, setOpportunity } from 'redux/opportunity/root';
import Opportunity from 'static/models/Opportunity';

import { RootState } from '../redux/root';

interface ReturnType {
    opportunity: Opportunity;
    setOpportunity: (opportunity: Opportunity) => void;
    setHidePriceListDisclaimer: (value: boolean) => void;
}

export const useOpportunity = (): ReturnType => {
    const dispatch = useDispatch();

    const dispatchOpportunity = (opportunity: Opportunity) => {
        dispatch(setOpportunity(opportunity));
    };

    return {
        opportunity: useSelector((state: RootState) => state.opportunity),
        setOpportunity: dispatchOpportunity,
        setHidePriceListDisclaimer: (value: boolean) => dispatch(setHidePriceListDisclaimer(value)),
    };
};
