import React from 'react';

import { Grid } from '@material-ui/core';
import TextAreaComponent from 'components/form-components/text-area-component/TextAreaComponent';
import TextBoxComponent from 'components/form-components/text-box-component/TextBoxComponent';
import { useForm } from 'hooks/forms/useForm';
import { errorFormDataValidator } from 'hooks/forms/validators';
import { useErrorFormData } from 'hooks/useErrorFormData';
import ErrorFormData from 'static/models/errorLogging/errorFormData';

const ErrorFormComponent: React.FunctionComponent = () => {
    const { errorFormData, updateErrorFormData } = useErrorFormData();
    const { formData, handleOnChange, handleOnBlur } = useForm<ErrorFormData>(
        errorFormData, //inital data
        updateErrorFormData, // hook update
        errorFormDataValidator, // vlidator
    );

    return (
        <Grid container direction="column" spacing={2}>
            <Grid container item direction="column" spacing={2}>
                <Grid item>
                    <TextBoxComponent
                        formName="errorFormData"
                        fieldName={formData.name?.name}
                        labelString="Name"
                        value={formData.name?.value}
                        showError={formData.name?.touched}
                        errorMessage={formData.name?.error}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                    />
                </Grid>
                <Grid item>
                    <TextBoxComponent
                        formName="errorFormData"
                        fieldName={formData.phone?.name}
                        labelString="Phone"
                        value={formData.phone?.value}
                        showError={formData.phone?.touched}
                        errorMessage={formData.phone?.error}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                    />
                </Grid>
                <Grid item>
                    <TextBoxComponent
                        formName="errorFormData"
                        fieldName={formData.email?.name}
                        labelString="Email"
                        value={formData.email?.value}
                        showError={formData.email?.touched}
                        errorMessage={formData.email?.error}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                    />
                </Grid>
                <Grid item>
                    <TextBoxComponent
                        formName="errorFormData"
                        fieldName={formData.subject?.name}
                        labelString="Subject"
                        value={formData.subject?.value}
                        showError={formData.subject?.touched}
                        errorMessage={formData.subject?.error}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                    />
                </Grid>
                <Grid item>
                    <TextAreaComponent
                        formName="errorFormData"
                        fieldName={formData.details?.name}
                        labelString="Details"
                        rows={5}
                        value={formData.details?.value}
                        showError={formData.details?.touched}
                        errorMessage={formData.details?.error}
                        onChange={handleOnChange}
                        onBlur={handleOnBlur}
                    />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ErrorFormComponent;
