import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { useSelectedProducts } from 'hooks/product-package-questions/useSelectedProducts';
import { getTaxableProducts } from 'toolboxes/reuseable-logic/products/productFunctions';

const useStyles = makeStyles(theme => ({
    cartInnerLine: {
        borderBottomWidth: 1,
        borderBottomColor: theme.palette.primary.main,
        borderBottomStyle: 'solid',
    },
    taxedItemsFontSize: {
        fontSize: '0.75rem',
    },
    taxedItemsInfoBoxSize: {
        width: 270,
        maxHeight: 200,
    },
}));

const TaxedItemsPopupContentComponent: React.FunctionComponent = () => {
    const classes = useStyles();
    const { selectedProducts } = useSelectedProducts();

    const taxableProducts = getTaxableProducts(selectedProducts);

    return (
        <div className={classes.taxedItemsInfoBoxSize}>
            <div id="taxed-items-popup-header" className={classes.cartInnerLine}>
                <Typography variant="body2" color="primary">
                    Taxed Items
                </Typography>
            </div>
            {taxableProducts.map((product, i) => {
                return (
                    <Typography
                        id={`taxed-items-popup-line-item-id-${product.id}`}
                        noWrap
                        key={i}
                        variant="body2"
                        className={classes.taxedItemsFontSize}
                    >
                        {product.name}
                    </Typography>
                );
            })}
        </div>
    );
};

export default TaxedItemsPopupContentComponent;
