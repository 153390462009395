import { RootState } from 'redux/root';
import urlPaths from 'static/constants/enums/urlPaths';
import BlockingFunction from 'static/models/blockingFunctions/BlockingFunction';
import { updateProductsOnOpportunity } from 'toolboxes/server-application-buffer/opportunity-data';

export function limitedEnhancement(state: RootState): BlockingFunction[] {
    return [
        {
            promiseGenerator: () => updateProductsOnOpportunity(state),
            blocksPageStarts: [urlPaths.enhancements],
        },
    ];
}
