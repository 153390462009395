import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { FeatureFlagResult } from '../../static/models/network/FeatureFlagResult';

// Just a couple example flags for getting redux set up. We can edit as we figure out which flags we need
export interface FeatureFlagState {
    useEndOfExperienceOption: boolean;
    useReactForAtNeeds: boolean;
    useCustomerPathAssociationObject: boolean;
    enableTimingGatePage: boolean;
    cobrandingStorefront: boolean;

    [key: string]: boolean;
}

export const initialState: FeatureFlagState = {
    useEndOfExperienceOption: false,
    useReactForAtNeeds: false,
    useCustomerPathAssociationObject: false,
    enableTimingGatePage: false,
    cobrandingStorefront: false,
};

export { initialState as initialFlags };

const featureFlagSlice = createSlice({
    name: 'featureFlags',
    initialState,
    reducers: {
        setFeatureFlags: {
            reducer: (state, { payload }: PayloadAction<Array<FeatureFlagResult>>) => {
                const flags = payload.reduce((result, flag) => ({ ...result, [flag.description]: flag.enabled }), {});
                return {
                    ...state,
                    ...flags,
                };
            },
            prepare: (flags: Array<FeatureFlagResult>) => ({ payload: flags }),
        },
    },
});

export const { setFeatureFlags } = featureFlagSlice.actions;
export default featureFlagSlice.reducer;
