import React from 'react';

import { Grid, makeStyles } from '@material-ui/core';
import ErrorFormComponent from 'components/global-components/error-form-component/ErrorFormComponent';
import ButtonLinkComponent from 'components/global-components/layout-components/button-components/button-link-component/ButtonLinkComponent';
import PageContentWrapperComponent from 'components/global-components/page-content-wrapper-component/PageContentWrapperComponent';
import urlPaths from 'static/constants/enums/urlPaths';

const useStyles = makeStyles(theme => ({
    button: {
        marginTop: theme.spacing(1),
    },
    form: {
        alignContent: 'center',
    },
}));

const ErrorPageComponent: React.FunctionComponent = () => {
    const classes = useStyles();

    return (
        <PageContentWrapperComponent
            title={'Sorry'}
            subtitle={
                <>
                    Something went wrong. If you would like to request support, please fill out the form below and one
                    of our team members will work to help resolve the issue.
                </>
            }
        >
            <Grid container justify="center">
                <Grid item md={6} sm={8} xs={12}>
                    <ErrorFormComponent />
                    <ButtonLinkComponent className={classes.button} fullWidth id="Submit" to={urlPaths.errorSubmission}>
                        Submit
                    </ButtonLinkComponent>
                </Grid>
            </Grid>
        </PageContentWrapperComponent>
    );
};

export default ErrorPageComponent;
