import { MembershipSignUpFormModel, MembershipVerificationFormModel } from '@efuneral/memberships';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Membership from 'static/models/Membership';

const initialState: Membership = {
    membershipSignUpValid: false,
    membershipVerificationValid: false,
    membershipSignUpContact: null,
    membershipVerificationContact: null,
};

export { initialState as initialMembership };

const membershipSlice = createSlice({
    name: 'membership',
    initialState,
    reducers: {
        setMembershipVerificationValid(state, action: PayloadAction<boolean>) {
            return { ...state, membershipVerificationValid: action.payload };
        },
        setMembershipSignUpValid(state, action: PayloadAction<boolean>) {
            return { ...state, membershipSignUpValid: action.payload };
        },
        setMembershipSignUpContact(state, action: PayloadAction<MembershipSignUpFormModel>) {
            return { ...state, membershipSignUpContact: action.payload };
        },
        setMembershipVerificationContact(state, action: PayloadAction<MembershipVerificationFormModel>) {
            return { ...state, membershipVerificationContact: action.payload };
        },
    },
});

export const {
    setMembershipVerificationValid,
    setMembershipSignUpValid,
    setMembershipSignUpContact,
    setMembershipVerificationContact,
} = membershipSlice.actions;
export default membershipSlice.reducer;
