import { useSelector, useDispatch } from 'react-redux';

import { setAccount } from '../redux/account/root';
import { RootState } from '../redux/root';
import Account from '../static/models/Account';

interface UseSelectedAccountReturnType {
    selectedAccount: Account;
    setSelectedAccount: (account: Account) => void;
}

export const useSelectedAccount = (): UseSelectedAccountReturnType => {
    const dispatch = useDispatch();

    const setSelectedAccount = (account: Account) => {
        dispatch(setAccount(account));
    };

    return {
        selectedAccount: useSelector((state: RootState) => state.account),
        setSelectedAccount,
    };
};
